<template>
  <div class="table-info wow fadeIn" data-wow-delay="0.5s">
    <t-title-info
        :label="navInfo.english_title"
        :title="navInfo.title"
        labelColor="#000"
        title-border=".01rem dashed var(--titleColor)">
      </t-title-info>
    <div class="title-wrap">
      <div class="left">
        <img src="@/assets/common/icon_title_label.png" alt="" class="img">
        职位投递
      </div>
      <div class="right">
        共创生物健康事业，实现卓越成长
      </div>
    </div>
    <el-table
      :data="cTableList"
      stripe
      style="width: 100%">
      <el-table-column
        prop="name"
        label="职位名称"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="category"
        label="职位类型"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="site"
        label="工作地点"
        align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            icon="el-icon-s-promotion"
            @click="scope.row.routerDetails($route.query.p_index || 0)">申请职位</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String | Number,
      default: () => 0
    }
  },
  data() {
    return {
    };
  },
  computed: {
    cTableList() {
      return this.$store.state.we.weInfo[this.type === 0 ? 'employment' : 'hireIntern'] || [];
    }
  },
  mounted() {

  },
  methods: {
  },
};
</script>

<style lang="less" scoped>
.table-info {
  padding: .5rem 1.8rem .7rem;
  /deep/ table {
    @media screen and(max-width: 980px) {
      width: auto !important;
    }
  }
  >.title-wrap {
    margin: .3rem 0 .2rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    >.left {
      color: #666;
      font-size: .23rem;
      display: flex;
      align-items: center;
      >.img {
        margin-right: .1rem;
        width: .5rem;
      }
    }
    >.right {
      margin-bottom: -.1rem;
      color: #666;
    }
  }
}
/deep/ .el-table {
  border-radius: .1rem .1rem 0 0;
}
/deep/ .el-table thead {
  color: #fff;
}
/deep/ .has-gutter {
  background: linear-gradient(90deg, #0189FF, #00C4BA);
  tr {
    background-color: transparent;
  }
}
/deep/ tr.el-table__row--striped td.el-table__cell {
  background: rgba(220, 235, 246, 1) !important;
}
/deep/ th.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table__cell {
  padding: .25rem 0;
}
/deep/ .cell {
  font-size: .18rem;
}
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-button {
  border-radius: .18rem;
}
</style>
