<template>
  <div class="recall-info">
    <div class="item wow fadeInUp" :data-wow-delay="`${0.3 * (index * 1)}s`" v-for="(item, index) in cRecall" :key="index" @click="item.routerDetails($route.query.p_index || 0)">
      <div class="left">
        {{cNum(index)}}
      </div>
      <div class="center">
        <h3 class="title more-ellipsis clamp-2">
          {{ item.title }}
        </h3>
      </div>
      <div class="right">
        {{ $utils.setTime(item.create_time, "Y-M-D") }}
        <img src="https://img.jozobio.com/img/message/icon_07.png" alt="" class="img">
      </div>
    </div>
    <div class="pagination-wrap wow fadeIn" data-wow-delay="0.6s">
      <el-pagination
        background
        :page-size="pageInfo.num"
        layout="prev, pager, next"
        @prev-click="pageCurrent"
        @next-click="pageCurrent"
        @current-change="pageCurrent"
        :total="cRecall.length">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Paging from "@/views/message/mixins/paging";
export default {
  props: {
    navIndex: {
      type: Number,
      default: () => 0
    }
  },
  mixins: [Paging],
  data() {
    return {
      pageInfo: {
        num: 6
      }
    };
  },
  computed: {
    cNum() {
      return function(index) {
        return String(index + 1).padStart(2, 0);
      }
    },
    cRecall() {
      return this.$store.state.message.messageInfo.recall || [];
    }
  }
};
</script>

<style lang="less" scoped>
  .recall-info {
    margin-top: .1rem;
    >.item {
      padding: .2rem .1rem;
      border-bottom: dotted 1px rgba(0, 0, 0, .1);
      cursor: pointer;
      display: flex;
      align-items: center;
      >.left {
        width: .4rem;
      }
      >.center {
        flex: 1;
        >.title {
          width: fit-content;
          font-weight: 400;
        }
      }
      >.right {
        display: flex;
        align-items: center;
        >.img {
          width: .2rem;
          margin-left: .5rem;
        }
      }
      &:hover {
        color: #0078EC;
        background-color: #f8f8f8;
        >.center {
          >.title {
            background-image: linear-gradient(90deg, #0078EC 40%, #00C4BA 60%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            color: #fff;
          }
        }
      }
    }
  }
</style>
