<template>
  <div class="ball-list">
    <img src="https://img.jozobio.com/uploads/20231207/Fv2DF720Ny2Ht_6LzeUwce46pN06.png" class="ball-img ball-01"/>
    <img src="https://img.jozobio.com/uploads/20231207/Fqn9Uf9Zrt84MFUYh-R_pAF6azoo.png" class="ball-img ball-02"/>
    <img src="https://img.jozobio.com/uploads/20231207/FtyC9YKXi_2XPTto4wV56HQsb9UO.png" class="ball-img ball-03"/>
    <img src="https://img.jozobio.com/uploads/20231207/Ftr5IQNuTKAiyYyFbUQx20NTgo6I.png" class="ball-img ball-04"/>
    <img src="https://img.jozobio.com/uploads/20231207/FrIRKWvBN2HkEJ-F9dvrQw881sV6.png" class="ball-img ball-05"/>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="less">
.ball-img {
  position: absolute;
  top: 0;
  &.ball-01 {
    right: -1.5rem;
    top: .3rem;
    width: 2.88rem;
  }
  &.ball-02 {
    top: 2rem;
    right: 3.04rem;
    width: 4.11rem;
  }
  &.ball-03 {
    top: 3rem;
    left: 3rem;
    width: 3.84rem;
  }
  &.ball-04 {
    top: 7rem;
    left: -.9rem;
    width: 2.8rem;
  }
  &.ball-05 {
    top: 6.73rem;
    right: -1.1rem;
    width: 2.5rem;
  }
}
</style>
