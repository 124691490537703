<template>
  <div class="t-top-nav">
    <div class="divide"></div>
    <div class="content-info" id="contentInfo">
      <div class="left">
        <img :src="configInfo.top_logo" @click="routerHome" alt="" class="img">
      </div>
      <div class="right">
        <div class="menu-wrap" @click="openNav">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
        <div class="list-wrap" :class="{open: open}">
          <div
            class="item"
            :class="{active: item.url === $route.path}"
            v-for="item in (configInfo.nav ? configInfo.nav : defaultNav)" :key="item.id"
            @click="handleItem(item.url)">
            <img src="https://img.jozobio.com/img/common/img_nav_bg.png" alt="" class="img">
            {{item.title}}
          </div>
        </div>
        <el-popover
          placement="bottom-start"
          trigger="click"
        >
          <div class="input-wrap" style="display: flex;align-items: center">
            <el-input v-model="search" placeholder="请输入关键字" clearable/>
            <div style="width: 0.1rem;"></div>
            <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
          </div>
          <template slot="reference">
            <div class="search-wrap">
              <img src="https://img.jozobio.com/img/common/icon_search_white.png" alt="" class="img">
              <img src="@/assets/common/icon_search_blue.png" alt="" class="img">
            </div>
          </template>
        </el-Popover>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import base from '@/router/base';
export default {
  props: {
  },
  data() {
    return {
      search: '',
      open: false
    };
  },
  watch: {
  },
  computed: {
    ...mapGetters([
     'configInfo'
    ]),
    defaultNav() {
      return base.map(v => {
        let obj = {};
        if (v.path) {
          obj = {
            title: v.meta.title,
            url: v.path
          }
        }
        return obj;
      });
    }
  },
  methods: {
    handleItem(path) {
      this.$router.push(path);
      if (this.$store.state.isMedia) {
        this.open = false;
      }
      window.scrollTo(0, 0);
      if (!this.$store.state.isMedia) {
        this.$nextTick(() => {
          this.$store.commit('initWoW');
        });
      }
    },
    routerHome() {
      this.$router.push({
        path: '/'
      });
    },
    handleSearch() {
      if (!this.search) return this.$message.warning('请填写搜索关键字');
      this.$router.push({
        path: '/search',
        query: {
          s: this.search
        }
      });
      window.scroll(0, 0);
    },
    openNav() {
      //移动端
      if (!this.$store.state.isMedia) return;
      this.open = !this.open;
    }
  }
};
</script>
<style lang="less" scoped>
  .t-top-nav {
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 999;
    >.divide {
      height: 1.33rem;
    }
    >.content-info {
      position: absolute;
      top: 0;
      z-index: 999;
      width: 100%;
      height: 1.33rem;
      background-color: #fff;
      box-shadow: 0 2px 2px rgba(0, 0, 0, .05);
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: space-between;
      >.left {
        margin-left: var(--wrapper);
        >.img {
          height: .86rem;
        }
      }
      >.right {
        height: 100%;
        display: flex;
        >.menu-wrap {
          display: none;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          >.line {
            width: .6rem;
            height: .08rem;
            background: #1c76fe;
            margin-bottom: .15rem;
            @media only screen and (max-width: 980px) {

            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        >.list-wrap {
          display: flex;
          align-items: center;
          @media only screen and (max-width: 980px) {
            padding: 0 !important;
          }
          >.item {
            margin-top: -.05rem;
            position: relative;
            padding: 0 .42rem;
            height: 100%;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .18rem;
            @media only screen and (max-width: 980px) {
              width: 100%;
              color: #fff;
              font-weight: initial;
              &:nth-child(1) {
                background-color: #1b77fe;
              }   
              &:nth-child(2) {
                background-color: #3985ff;
              }
              &:nth-child(3) {
                background-color: #5696ff;
              }
              &:nth-child(4) {
                background-color: #71a7ff;
              }
              &:nth-child(5) {
                background-color: #8cb8ff;
              }
              &:nth-child(6) {
                background-color: #a8c9ff;
              }
              &:nth-child(7) {
                background-color: #c3d9fe;
              }       
            }
            >.img {
              position: absolute;
              z-index: -1;
              margin-bottom: -.16rem;
              width: .81rem;
              opacity: 0;
              transition: .1s;
            }
            &:hover {
              color: #fff;
              >.img {
                opacity: 1;
              }
            }
          }
          >.active {
            color: #fff;
            @media only screen and (max-width: 980px) {
              color: #fff !important;
              background-color: #1c76fe !important;
            }
            >.img {
              opacity: 1 !important;
            }
          }
        }
        .search-wrap {
          width: 1.34rem;
          height: 100%;
          background-color: #0189ff;
          cursor: pointer;
          transition: .2s;
          display: flex;
          align-items: center;
          justify-content: center;
          >.img {
            width: .2rem;
            &:nth-child(2) {
              display: none;
            }
          }
          @media only screen and (max-width: 980px) {
            background-color: #fff;
            .img {
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                width: .6rem !important;
                display: block;
              }
            }
          }
          &:hover {
            opacity: .9;
          }
        }
      }
    }
  }
</style>
