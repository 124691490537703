<template>
  <div class="recruit-info">
    <div class="content-info">
      <img :src="cTalents['2image']" alt="" class="img">
      <div class="info-wrap">
        <div class="title wow fadeInUp">
          <div class="name">
            招聘通道
          </div>
        </div>
        <div class="text wow fadeInUp" data-wow-delay="0.5s">
          自身与公司价值的结合，实现自身与公司的共同成长
        </div>
        <div class="border wow fadeInUp" data-wow-delay="0.7s"></div>
        <div class="btn wow fadeInUp" data-wow-delay="0.9s" @click="handleBtn">点击查看</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    cTalents() {
      return this.$store.state.we.weInfo.talents || {};
    }
  },
  methods: {
    handleBtn() {
      const html = document.getElementsByTagName('html')[0];
      const fontSize = + html.style.fontSize.replace('px', '');
      const scroll = document.getElementsByClassName('t-nav-list-top')[0].offsetTop - 1.94 * fontSize;
      window.scrollTo({
        top: scroll,
        behavior: "smooth"
      });
      this.$router.push({
        path: this.$route.path,
        query: {
          p_index: 1
        }
      })
    }
  }
};
</script>

<style lang="less" scoped>
  .recruit-info {
    >.content-info {
      position: relative;
      >.img {
        width: 100%;
      }
      >.info-wrap {
        position: absolute;
        top: 1rem;
        right: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media screen and(max-width: 980px) {
          top: .2rem;
        }
        >.title {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          >.name {
            display: inline-flex;
            font-size: .5rem;
            background-image: linear-gradient(to right, #006ff7 70%, #00b6c0);
            -webkit-background-clip: text;
            color: transparent;
          }
        }
        >.text {
          margin-top: .2rem;
          @media screen and(max-width: 980px) {
            font-size: .3rem;
          }
        }
        >.border {
          margin-top: .08rem;
          width: 2.7rem;
          height: 3px;
          background-image: linear-gradient(to right, #006ff7 70%, #00b6c0);
        }
        >.btn {
          margin-top: .3rem;
          padding: .1rem .6rem;
          color: #fff;
          border-radius: .08rem;
          background-image: linear-gradient(to right, #006ff7, #00b6c0);
          &:hover {
            box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
          }
          @media screen and(max-width: 980px) {
            font-size: .3rem;
          }
        }
      }
    }
  }
</style>
