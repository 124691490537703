<template>
  <div class="union-info">
    <div class="top">
      <div class="left">
        <border-title :titles="cTitles" type="2"></border-title>
      </div>
      <div class="right">
        <div
          class="item wow fadeInRight"
          :data-wow-delay="`${0.3 * (index * 1)}s`"
          v-for="(image, index) in cPartner[3]?.images"
          :key="`image-${index}`"
        >
          <img :src="image" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="bottom">
      <label-info></label-info>
    </div>
  </div>
</template>

<script>
import BorderTitle from './BorderTitle.vue';
import LabelInfo from './labelInfo.vue';
export default {
  components: { BorderTitle, LabelInfo },
  data() {
    return {

    };
  },
  computed: {
    cPartner() {
      return this.$store.state.exploit.exploitInfo.partner || [];
    },
    cTitles() {
      return [this.cPartner[3]?.title, this.cPartner[3]?.e_title];
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .union-info {
    padding: .2rem 1.8rem .5rem;
    >.top {
      display: flex;
      align-items: center;
      >.left {
        width: 4rem;
      }
      >.right {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        >.item {
          margin-right: 1.2rem;
          &:nth-child(4n) {
            margin-right: 0;
          }
          >.img {
            height: 1.5rem;
          }
        }
      }
    }
  }
</style>
