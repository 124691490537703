<template>
  <div class="form-resume">
    <el-dialog
      :visible.sync="resumeVisible"
      :show-close="false"
    >
      <template slot="title">
        <header class="header-wrap">
          <div class="title-wrap">
            <h2 class="title">在线填写简历</h2>
            <div class="title-icon-wrap">
              <img class="title-icon" src="https://img.jozobio.com/img/we/recruit/recruit_resume_icon.png" alt="在线填写简历"/>
            </div>
          </div>
          <div class="close-icon-wrap" @click="close">
            <img class="close-icon" src="https://img.jozobio.com/img/we/recruit/recruit_resume_close_icon.png" alt="关闭"/>
          </div>
        </header>
      </template>
      <section class="resume-content">
        <div class="form-wrap">
          <!-- 表单 -->
          <el-form
              :ref="formTarget.ref"
              :label-position="formTarget.labelPosition"
              :inline="formTarget.inline"
              :model="formTarget.resumeForm"
              :label-width="formTarget.labelWidth">
            <div
              class="resume-info"
              v-for="(labelItem, labelIndex) in formTarget.value"
              :key="`label-${labelIndex}`"
            >
              <div class="label-wrap">
                <div class="add-left">
                  <div class="label-icon-wrap">
                    <img class="label-icon" src="https://img.jozobio.com/img/common/icon_label.png" alt="基础信息"/>
                  </div>
                  <h3 class="label">{{ labelItem.label }}</h3>
                </div>
                <template v-if="labelItem.add">
                  <div class="add-right">
                    <div class="add-btn btn" @click="formTarget[labelItem.addMethod]()">
                      <div class="add-icon-wrap">
                        <img class="add-icon" src="https://img.jozobio.com/img/we/recruit/recruit_resume_add_icon.png" alt="添加"/>
                      </div>
                      <div class="add-text">添加</div>
                    </div>
                    <div class="del-btn btn" @click="formTarget[labelItem.delMethod]()">
                      <div class="del-icon-wrap">
                        <img class="del-icon" src="https://img.jozobio.com/img/we/recruit/recruit_resume_delete_icon.png" alt="删除"/>
                      </div>
                      <div class="del-text">删除</div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="input-list">
                <template
                  v-for="(inputItem, inputIndex) in labelItem.inputList"
                >
                  <!-- 普通表单 -->
                  <template v-if="!Array.isArray(inputItem)">
                    <el-form-item
                        :label="inputItem.label"
                        :prop="inputItem.prop"
                        :key="`input-${inputIndex}`"
                        :rules="inputItem.rules"
                    >
                      <template v-if="inputItem.type === 'input'">
                        <!-- 如果type为input 这里就是输入框 -->
                        <el-input :placeholder="inputItem.placeholder" v-model="formTarget.resumeForm[inputItem.prop]"></el-input>
                      </template>
                      <template v-if="inputItem.type === 'select'">
                        <!-- 如果type为select 这里就是下拉框 -->
                        <el-select v-model="formTarget.resumeForm[inputItem.prop]" :placeholder="inputItem.placeholder">
                          <el-option
                              :label="selectItem.label"
                              :value="selectItem.value"
                              v-for="(selectItem, selectIndex) in inputItem.selectList"
                              :key="`${inputItem.prop}-${selectIndex}`"
                          ></el-option>
                        </el-select>
                      </template>
                      <template v-if="inputItem.type === 'date'">
                        <!-- 如果type为date 这里就是选择日期 -->
                        <el-date-picker type="datetime" format="yyyy-MM-dd HH:mm:ss" :placeholder="inputItem.placeholder" v-model="formTarget.resumeForm[inputItem.prop]"></el-date-picker>
                      </template>
                      <template v-if="inputItem.type === 'textarea'">
                        <el-input type="textarea" :placeholder="inputItem.placeholder" v-model="formTarget.resumeForm[inputItem.prop]"/>
                      </template>
                    </el-form-item>
                  </template>
                  <!-- 能添加的表单 -->
                  <template v-else>
                    <template v-if="labelItem.label === '工作经历'">
                      <div
                          class="el-form-item is-required add-form-item"
                          v-for="(addInput, addIndex) in inputItem"
                          :key="`add-input-${inputIndex}-${addIndex}`"
                      >
                        <label :for="addInput.prop" class="el-form-item__label">{{ addInput.label }}</label>
                        <div class="el-form-item__content">
                          <template v-if="addInput.type === 'input'">
                            <!-- 如果type为input 这里就是输入框 -->
                            <el-input :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.json[inputIndex][addInput.prop]"></el-input>
                          </template>
                          <template v-if="addInput.type === 'select'">
                            <!-- 如果type为select 这里就是下拉框 -->
                            <el-select v-model="formTarget.resumeForm.json[inputIndex][addInput.prop]" :placeholder="addInput.placeholder">
                              <el-option
                                  :label="selectItem.label"
                                  :value="selectItem.value"
                                  v-for="(selectItem, selectIndex) in addInput.selectList"
                                  :key="`${addInput.prop}-${selectIndex}`"
                              ></el-option>
                            </el-select>
                          </template>
                          <template v-if="addInput.type === 'date'">
                            <!-- 如果type为date 这里就是选择日期 -->
                            <el-date-picker type="datetime" :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.json[inputIndex][addInput.prop]"></el-date-picker>
                          </template>
                          <template v-if="addInput.type === 'textarea'">
                            <el-input type="textarea" :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.json[inputIndex][addInput.prop]"/>
                          </template>
                        </div>
                      </div>
                      <div class="add-item-line" v-if="labelItem.inputList.length > 1"></div>
                    </template>
                    <template v-if="labelItem.label === '教育经历'">
                      <div
                          class="el-form-item is-required add-form-item"
                          v-for="(addInput, addIndex) in inputItem"
                          :key="`add-input-${inputIndex}-${addIndex}`"
                          style="margin-bottom: .05rem"
                      >
                        <template v-if="inputIndex === 0">
                          <label :for="addInput.prop" class="el-form-item__label">{{ addInput.label }}</label>
                        </template>
                        <div class="el-form-item__content">
                          <template v-if="addInput.type === 'input'">
                            <!-- 如果type为input 这里就是输入框 -->
                            <el-input :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.education_json[inputIndex][addInput.prop]"></el-input>
                          </template>
                          <template v-if="addInput.type === 'select'">
                            <!-- 如果type为select 这里就是下拉框 -->
                            <el-select v-model="formTarget.resumeForm.education_json[inputIndex][addInput.prop]" :placeholder="addInput.placeholder">
                              <el-option
                                  :label="selectItem.label"
                                  :value="selectItem.value"
                                  v-for="(selectItem, selectIndex) in addInput.selectList"
                                  :key="`${addInput.prop}-${selectIndex}`"
                              ></el-option>
                            </el-select>
                          </template>
                          <template v-if="addInput.type === 'date'">
                            <!-- 如果type为date 这里就是选择日期 -->
                            <el-date-picker type="datetime" :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.education_json[inputIndex][addInput.prop]"></el-date-picker>
                          </template>
                          <template v-if="addInput.type === 'textarea'">
                            <el-input type="textarea" :placeholder="addInput.placeholder" v-model="formTarget.resumeForm.education_json[inputIndex][addInput.prop]"/>
                          </template>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </div>
            </div>
          </el-form>
          <div class="split-line"></div>
        </div>
        <div class="right">
          <div class="btn-wrap">
            <div class="submit-resume btn" @click="formTarget.submit($vnode.componentInstance)">投递简历</div>
          </div>
          <div class="code-wrap">
            <div class="code-img-wrap">
              <img class="code-img" src="https://img.jozobio.com/img/lzy_test/recruit_resume_code.png" alt="二维码"/>
            </div>
            <div class="explain-wrap">
              <div class="explain">扫码识别二维码，关注公众号</div>
              <div class="sub-explain">随时了解招聘信息及进展</div>
            </div>
          </div>
        </div>
      </section>
    </el-dialog>
  </div>
</template>

<script>
import {
  ResumeForm
} from '../../target/we';
export default {
  data() {
    return {
      formTarget: null, //简历表单对象
      resumeVisible: false
    }
  },
  created() {
    this.initTarget();
  },
  methods: {
    initTarget() {
      this.formTarget = new ResumeForm({
        ref: 'form',
        labelPosition: 'top',
        inline: true,
        labelWidth: '80px'
      });
    },
    open() {
      this.resumeVisible = true;
    },
    close() {
      this.resumeVisible = false;
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog {
  width: 13.8rem;
}
/deep/ .el-input,
/deep/ .el-form-item{
  width: 2.3rem;
  box-sizing: content-box;
  &:has(.el-textarea) {
    width: 100%;
    margin-right: 0;
  }
}
/deep/ .el-form-item__label {
  padding-bottom: .1rem;
  line-height: .3rem;
}
.header-wrap {
  display: flex;
  align-items: center;
  padding-bottom: .15rem;
  border-bottom: .01rem solid #E5E5E5;
}
.title-wrap {
  display: flex;
  align-items: flex-end;
  flex: 1;
}
.title {
  margin-right: .1rem;
  font-size: .28rem;
}
.title-icon {
  width: .23rem;
  margin-bottom: .03rem;
}
.close-icon-wrap {
  cursor: pointer;
}
.close-icon {
  width: .25rem;
}
.resume-content {
  display: flex;
}
.resume-info {
  margin-bottom: .3rem;
  &:last-child {
    margin-bottom: 0;
  }
}
.right {
  width: 4.68rem;
}
.form-wrap {
  position: relative;
  flex: 1;
  padding-right: .41rem;
}
.input-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .25rem;
}
.add-left {
  display: flex;
  align-items: center;
}
.add-right {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add-btn,
.del-btn {
  display: flex;
  align-items: center;
  &.add-btn {
    margin-right: .15rem;
  }
}
.add-icon-wrap,
.del-icon-wrap {
  margin-right: .05rem;
}
.add-icon,
.del-icon {
  width: .16rem;
  margin-bottom: .02rem;
  &.del-icon {
    width: .18rem;
  }
}
.add-text {
  color: #0473D4;
}
.del-text {
  color: #d81e06;
}
.label {
  font-size: .2rem;
  font-weight: 500;
}
.label-icon-wrap {
  margin-right: .1rem;
}
.label-icon {
  width: .07rem;
  margin-bottom: .03rem;
}
.add-item-line {
  height: .01rem;
  width: 100%;
  background-color: #F2F1F1;
  margin-bottom: .22rem;
  &:last-child {
    display: none;
  }
}
.split-line {
  position: absolute;
  top: 0;
  right: 0;
  width: .06rem;
  height: 7.81rem;
  background-color: #F2F1F1;
  border-radius: .03rem;
}

.btn-wrap {
  display: flex;
  justify-content: center;
  margin-top: .3rem;
}
.submit-resume {
  width: 2.6rem;
  height: .5rem;
  background: linear-gradient(90deg, #0089FF, #00C2BC);
  border-radius: .28rem;
  font-size: .2rem;
  text-align: center;
  line-height: .5rem;
  color: #fff;
  cursor: pointer;
}
.code-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}
.code-img {
  width: 2.3rem;
}
.explain {
  margin: .2rem 0 .1rem;
  text-align: center;
  color: #4F4F4F;
}
.sub-explain {
  font-size: .14rem;
  text-align: center;
  color: #4F4F4F;
}

</style>
