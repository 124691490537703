<template>
  <div class="partners">
    <title-info :nav-info="navInfo"></title-info>
    <text-info></text-info>
    <exploit-info ref="part_1"></exploit-info>
    <people-info ref="part_2"></people-info>
    <register-info ref="part_3"></register-info>
    <union-info ref="part_4"></union-info>
  </div>
</template>

<script>
import TitleInfo from './childComps/TitleInfo';
import TextInfo from "@/views/exploit/achievement/childComps/TextInfo.vue";
import ExploitInfo from './childComps/ExploitInfo.vue';
import PeopleInfo from './childComps/PeopleInfo.vue';
import RegisterInfo from './childComps/RegisterInfo.vue';
import UnionInfo from './childComps/UnionInfo.vue';
export default  {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    },
    loadImg: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  watch: {
    loadImg: {
      handler() {
        this.navTop[this.num];
      },
      deep: true
    }
  },
  components: { TitleInfo, ExploitInfo, PeopleInfo, RegisterInfo, UnionInfo, TextInfo },
  data() {
    return {
      num: 0,
    };
  },
  mounted() {
    if (this.$route.query.pa_index) {
      setTimeout(() => {
        this.navTop((+this.$route.query.pa_index || 0) + 1);
      }, 150);
    }
  },

  methods: {
    navTop(num) {
      if(this.loadImg) {
        const html = document.getElementsByTagName('html')[0];
        const fontSize = + html.style.fontSize.replace('px', '');
        const scroll = this.$refs[`part_${num}`].$el.offsetTop - 2 * fontSize;
        window.scrollTo({
          top: scroll,
          behavior: "smooth"
        });
        // window.scrollTo({
        //   top: this.$refs[`part_${num}`].$el.offsetTop - 200,
        //   behavior: "smooth"
        // });
      }
    }
  },
};
</script>

<style lang="less" scoped>

</style>
