<template>
<div class="t-nav-list" :style="{margin}">
  <div class="content-info">
    <div class="left">
      <img src="https://img.jozobio.com/img/common/icon_home_white.png" alt="" class="img">
      <div class="list-wrap">
        <div class="item" v-for="(item, index) in titles" :key="index">> {{item}}</div>
      </div>
    </div>
    <div class="right">
      <div class="sub-menu" :class="{'w-r': isW}" @click="openNav">
        <div class="menu-text">{{ listInfo[index] ? listInfo[index][listKey] : ''}}</div>
        <div class="arrow"></div>
      </div>
      <div class="list" :class="{open: open}">
        <div
            class="item"
            :style="{padding: iPadding}"
            :class="{active: itemIndex == index, 'w-r': isW}"
            v-for="(item, itemIndex) in listInfo"
            :key="itemIndex"
            @click="handleItem(itemIndex)">
          {{item[listKey]}}
          <template v-if="itemIndex + 1 != index && itemIndex != index">
            <div class="border"></div>
          </template>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    margin: {
      type: String,
      default() {
        return '-.6rem 0 0 0'
      }
    },
    iPadding: {
      type: String,
      default() {
        return '0 .5rem';
      }
    },
    titles: {
      type: Array,
      default() {
        return []
      }
    },
    listInfo: {
      type: Array,
      default() {
        return []
      }
    },
    listKey: {
      type: String,
      default: () => 'name'
    },
    isWow: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      index: 0,
      open: false,
      isW: false
    }
  },
  watch: {
    $route: {
      handler(value) {
        this.index = value.query.p_index || 0;
        if(value.path == '/message') {
          this.isW = true;
        }else {
          this.isW = false;
        }
      },
      immediate: true
    }
  },
  mounted() {
  },
  methods: {
    handleItem(index) {
      if(document.getElementsByClassName('t-nav-list-top')[0]) {
        let top = document.getElementsByClassName('t-nav-list-top')[0].offsetTop - 160;
        if (this.$store.state.isMedia) {
          top += 90;
          this.open = false;
        }
        window.scrollTo({
          top: top,
          behavior: "smooth"
        });
      }

      this.$router.replace({
        path: this.$route.path,
        query: {
          p_index: index
        }
      })
      this.$emit('returnVal', index)
      if (this.isWow && !this.$store.state.isMedia) {
        this.$nextTick(() => {
          this.$store.commit('initWoW');
        });
      }
    },
    openNav() {
      this.open = !this.open;
    }
  }
};
</script>

<style lang="less" scoped>
  .t-nav-list {
    position: -webkit-sticky;
    position: sticky;
    top: 1.33rem;
    z-index: 99;
    >.content-info {
      position: relative;
      z-index: 1;
      padding: 0 var(--wrapper);
      height: .6rem;
      color: rgba(255, 255, 255, .8);
      //background-color: rgba(0, 0, 0, .4);
      background-color: rgba(63, 104, 245, 1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      >.left {
        display: flex;
        align-items: center;
        >.img {
          width: .18rem;
        }
        >.list-wrap {
          display: flex;
          align-items: center;
          >.item {
            margin-left: .1rem;
          }
        }
      }
      >.right {
        height: 100%;
        display: flex;
        align-items: center;
        >.sub-menu {
          display: none;
          align-items: center;
          >.menu-text {
            font-size: .45rem;
          }
          >.arrow {
            margin-left: .2rem;
            margin-bottom: .15rem;
            width: .23rem;
            height: .23rem;
            border-right: .06rem solid #fff;
            border-bottom: .06rem solid #fff;
            transform: rotate(45deg);
          }
        }
        >.list {
          display: flex;
          height: 100%;
          >.item {
            position: relative;
            height: 100%;
            color: rgba(255, 255, 255, .8);
            cursor: pointer;
            user-select: none;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: .15rem;
            &:hover {
              color: rgba(255, 255, 255, 1);
            }
            >.border {
              position: absolute;
              right: 0;
              height: .15rem;
              border-right: 1px solid rgba(255, 255, 255, .8);
            }
          }
          >.active {
            background-image: linear-gradient(to right, #0089FF, #00C2BC);
          }
        }
      }
    }
  }
</style>
