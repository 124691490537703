import ProductDetails from "@/views/product/details/Details.vue";
import NewsDetails from "@/views/news/details/Details.vue";
import RecruitDetail from "@/views/we/recruitDetail/RecruitDetail.vue";
import MessageDetails from "@/views/message/details/Details.vue";
import ExploitDetails from '@/views/exploit/details/Details.vue';
import BaseDetails from '@/views/profile/details/Details.vue';
import Search from "@/views/search/Search.vue";

export default [
  {
    path: '/product_details',
    name: 'product_details',
    component: ProductDetails
  },
	{
		path: '/news_details/:id',
		component: NewsDetails,
    meta: {
      footer: 2
    },
	},
  {
    path: '/we_info',
    component: RecruitDetail,
    meta: {
      footer: 2
    },
  },
  {
    path: '/message_details',
    component: MessageDetails,
    meta: {
      footer: 2
    },
  },
  {
    path: '/exploit_details',
    meta: {
      footer: 2
    },
    component: ExploitDetails
  },
  {
    path: '/profile_details',
    component: BaseDetails
  },
  {
    path: '/search',
    component: Search
  }
]
