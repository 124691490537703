<template>
  <div class="recruit-request">
    <div class="request-wrap">
      <h3 class="label wow fadeInUp" data-wow-delay="0.4s">
        岗位要求：
      </h3>
      <div class="content wow fadeInUp" data-wow-delay="0.5s">
        <p style="color: #707070;line-height: 36px;white-space: pre-wrap">{{ recruitInfo.ask }}</p>
      </div>
    </div>
    <div class="desc-wrap">
      <h3 class="label wow fadeInUp" data-wow-delay="0.4s">
        岗位说明：
      </h3>
      <div class="content wow fadeInUp" data-wow-delay="0.5s">
        <p style="color: #707070;line-height: 36px;white-space: pre-wrap">{{ recruitInfo.illustrate }}</p>
      </div>
    </div>
    <div class="btn-wrap">
      <el-upload
          class="upload"
          action="https://www.jozobio.com/api/common/upload"
          accept=".pdf,.docx,.doc,.xlsx,.xls"
          :before-upload="beforeUpload"
          :on-success="uploadSuccess"
          :limit="1"
      >
        <div class="upload-btn btn wow fadeIn" data-wow-delay="0.5s">
            上传简历
        </div>
      </el-upload>
      <div class="form-btn btn wow fadeIn" data-wow-delay="0.6s" @click="openResume">
        在线填写
      </div>
    </div>
    <form-resume ref="resume"></form-resume>
  </div>
</template>

<script>
import FormResume from "@/views/we/recruitDetail/childComps/form-resume.vue";
export default {
  components: {
    FormResume
  },
  data() {
    return {
      recruitInfo: {},
      fullPath: ''
    }
  },
  created() {
    this.recruitInfo = JSON.parse(this.$route.query.item || '{}') || {};
  },
  methods: {
    openResume() {
      this.$refs.resume.open();
    },
    beforeUpload(file) {
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if(!['.pdf','.docx','.doc','.xls','.xlsx'].includes(fileType)){
        this.$message({
          type: 'error',
          message: '请上传pdf、docx、xlsx的文件'
        });
        return false;
      }
    },
    async uploadSuccess(response, file, fileList) {
      if (response.code !== 1) {
        this.$message({
          type: 'error',
          message: '上传失败'
        });
        return false;
      }
      this.fullPath = response.data.fullurl;
      //提交简历
      const res = await this.$api.we.subPDF({
        file: this.fullPath
      });
      if (res.code !== 1) {
        this.$message({
          type: 'error',
          message: '上传失败'
        });
        return false;
      }
      this.$message({
        type: 'success',
        message: response.msg
      });
    }
  }
}
</script>

<style scoped lang="less">
.recruit-request {
  position: relative;
  padding: 0 2rem;
  margin-bottom: 1rem;
}
.request-wrap,
.desc-wrap {
  padding: 0 .1rem;
  margin: .3rem 0 .4rem;
  &.desc-wrap {
    margin-bottom: 0;
  }
}
.label {
  display: flex;
  align-items: center;
  margin-bottom: .15rem;
  font-size: .18rem;
  &:before {
    content: '';
    width: .03rem;
    height: .2rem;
    margin: .01rem .08rem 0 0;
    background-color: #050505;
  }
}
.btn-wrap {
  display: flex;
  margin-top: .6rem;
}
.upload-btn,
.form-btn {
  width: 1.8rem;
  height: .45rem;
  border: .01rem solid #050505;
  border-radius: .27rem;
  text-align: center;
  line-height: .45rem;
  transition: .5s ease-out;
  &.form-btn {
    margin-left: .5rem;
    border: none;
    background: linear-gradient(90deg, #0078EC, #00C4BA);
    color: #fff;
  }
  &.upload-btn {
    &:hover {
      background: linear-gradient(90deg, #0078EC, #00C4BA);
      border: none;
      color: #fff;
    }
  }
}
</style>
