<template>
  <div class="we">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
      :index="navIndex"
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      @returnVal="retNav">
    </t-nav-list>
    <template v-if="navIndex == 0 || navIndex == 2">
      <talents-info :nav-info="cSubNavTitle"></talents-info>
      <recruit-info></recruit-info>
      <map-info ref="mapInfo"></map-info>
      <banner-info></banner-info>
    </template>
    <template v-else-if="navIndex == 1">
      <!-- <top-info :nav-info="cSubNavTitle"></top-info> -->
      <table-info :nav-info="cSubNavTitle"></table-info>
    </template>
    <!-- <template v-else-if="navIndex === 2">
      <top-info :nav-info="cSubNavTitle"></top-info>
      <table-info :type="1"></table-info>
    </template> -->
  </div>
</template>

<script>
import TopInfo from './childComps/TopInfo.vue';
import RecruitInfo from './childComps/RecruitInfo.vue';
import TalentsInfo from './childComps/TalentsInfo.vue';
import TableInfo from './childComps/TableInfo.vue';
import TopBanner from "@/views/we/mixins/top-banner";
import MapInfo from './childComps/MapInfo.vue';
import BannerInfo from './childComps/BannerInfo.vue';
export default {
  components: { TalentsInfo, RecruitInfo, TopInfo, TableInfo, MapInfo, BannerInfo },
  name: 'We',
  mixins: [TopBanner],
  data() {
    return {
      navIndex: 0,
    };
  },
  created() {
  },
  methods: {
  },
};
</script>

<style lang="less" scoped>

</style>
