<template>
  <div class="test">
     测试页
  </div>
</template>

<script>
export default {
  name: 'DemoTest',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="less" scoped>

</style>