<template>
  <div class="label-info wow fadeInUp" data-wow-delay="0.5s">
    <div class="left">
      >>>>>>>>
    </div>
    <div class="right">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default() {
        return '合作单位/PARTNER'
      }
    }
  },
  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .label-info {
    margin: .6rem 0;
    font-size: .14rem;
    color: #696969;
    display: flex;
    align-items: center;
    >.left {
      padding-left: .18rem;
      width: 4rem;
    }
    >.right {
      flex: 1;
      padding: 0 .15rem;
      height: .4rem;
      background-color: rgba(220, 235, 246, 1);
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
</style>
