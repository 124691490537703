<template>
  <div class="banner">
    <div class="home-swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(bannerItem, bannerIndex) in cBanner"
          :key="bannerIndex"
        >
          <div class="banner-img-wrap">
            <img class="banner-img" :src="bannerItem.image"/>
          </div>
          <div class="info-wrap">
            <div :class="['en', {easy: swiper.activeIndex === bannerIndex}]" :style="{'--time': (0.4 + 0.3) + 's'}">{{ bannerItem.e_title }}</div>
            <div :class="['title', {easy: swiper.activeIndex === bannerIndex}]" :style="{'--time': (0.4 + 0.5) + 's'}">{{ bannerItem.title }}</div>
            <div :class="['sub-title', {easy: swiper.activeIndex === bannerIndex}]" :style="{'--time': (0.4 + 0.7) + 's'}">{{ bannerItem.sub_title }}</div>
            <div :class="['btn-more-wrap','btn', {easy: swiper.activeIndex === bannerIndex}]" :style="{'--time': (0.4 + 0.9) + 's'}" @click="bannerItem.routerUrl()">
              <img class="more-icon" src="https://img.jozobio.com/img/home/home_more_icon.png" alt="more"/>
              <div class="btn-text">探索更多内容</div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination wow fadeInUp"></div>
      <div class="progress-wrap wow fadeInUp">
        <div class="number">{{ $utils.formatNumber(activeIndex) }}.</div>
        <div class="progress">
          <div class="bar" :style="{animationName: animation}" ref="bar"></div>
        </div>
        <div class="number">{{ $utils.formatNumber(nextIndex) }}.</div>
      </div>
    </div>
    <div class="bottom-banner">
      <img class="bottom-banner-img" v-if="$store.state.indexInfo.data?.image" :src="$store.state.indexInfo.data?.image"/>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper';
export default {
  props: {
    bannerList: {
      type: Array,
      default: () => []
    },
    autoplay: {
      type: Number,
      default: () => 3000
    },
    slidesPerView: { //一页显示个数
      type: Number,
      default: () => 1
    },
    spaceBetween: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      swiper: {},
      progressW: 0,
      animation: 'bar'
    }
  },
  computed: {
    activeIndex() {
      return this.swiper ? this.swiper.activeIndex + 1 : '';
    },
    nextIndex() {
      let index = '';
      if (this.swiper) {
        index = this.swiper.activeIndex + 1 === this.swiper.slides?.length ? 1 : this.swiper.activeIndex + 2;
      }
      return index;
    },
    cBanner() {
      return this.$store.state.indexInfo.data?.banner || [];
    }
  },
  created() {
  },
  mounted() {
    this.initSwiper();
    this.eventListener();
  },
  destroyed() {
  },
  methods: {
    eventListener() {
      this.$refs.bar.addEventListener('animationend', () => {
        if (!this.swiper) return ;
        this.$set(this, 'animation', 'none');
        const nextIndex = this.swiper.activeIndex + 1 === this.swiper.slides.length ? 0 : this.swiper.activeIndex + 1;
        this.swiper.slideTo(nextIndex);
        const timer = setTimeout(() => {
          this.$set(this, 'animation', 'bar');
          clearTimeout(timer);
        }, 500)
      }, false);
    },
    initSwiper() {
      this.swiper = new Swiper('.home-swiper-container', {
        slidesPerView: this.slidesPerView,
        spaceBetween: this.spaceBetween,
        autoplay: false,
        observer: true,
        observeParents: true,
        pagination: { //分页器
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'home-paging-bullet'
        },
        speed: 1000,
      });
      this.swiper.pagination.$el.addClass('home-paging');
    }
  }
}
</script>

<style lang="less">
.banner {
  width: 100%;
  overflow: hidden;
  .home-swiper-container {
    position: relative;
    height: 9.18rem;
    .swiper-slide {
      position: relative;
    }
  }

  .banner-img-wrap {
    .banner-img {
      display: block;
      width: 100%;
      height: 9.18rem;
    }
  }
  .info-wrap {
    position: absolute;
    top: 50%;
    left: var(--wrapper);
    transform: translateY(-50%);
    z-index: 1;
    color: #FFFFFF;
    .en {
      font-size: .28rem;
      font-family: Archive;
    }
    .title {
      margin: .2rem 0 .1rem;
      font-size: .75rem;
      font-weight: bold;
    }
    .sub-title {
      font-size: .17rem;
      opacity: .8;
    }
    .btn-more-wrap {
      display: flex;
      align-items: center;
      width: fit-content;
      height: .41rem;
      margin-top: .4rem;
      padding: 0 .2rem;
      border: .01rem solid #fff;
      border-radius: .19rem;
      .more-icon {
        width: .16rem;
        height: auto;
      }
      .btn-text {
        margin-left: .05rem;
        font-size: .15rem;
      }
    }
  }

  .progress-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: .5rem;
    left: 0;
    width: 100%;
    padding: 0 var(--wrapper);
    z-index: 1;

    .number {
      font-size: .24rem;
      font-family: HIVEBOX-EN REGULAR;
      color: #FFFFFF;
    }

    .progress {
      position: relative;
      margin: 0 .1rem;
      height: .01rem;
      width: 1.15rem;
      background-color: #fff;
      .bar {
        position: absolute;
        left: 0;
        transform: translateY(-50%);
        width: 0;
        height: .05rem;
        background-color: #fff;
        animation-duration: 4s;
        animation-timing-function: linear;
      }
    }
  }

  .bottom-banner {
    .bottom-banner-img {
      width: 100%;
    }
  }
}
.easy {
  animation: easy var(--time);
  animation-timing-function: ease-out;
}
@keyframes easy {
  0% {
    transform: translateY(2rem);
    opacity: 0;
  }
  50% {
    opacity: .2;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bar {
  0% {
    width: 0;
  }
  100% {
    width: 1.15rem;
  }
}
.home-paging {
  bottom: .5rem !important;
  padding: 0 var(--wrapper);
  text-align: right;
}

.home-paging-bullet {
  display: inline-block;
  width: .07rem;
  height: .07rem;
  margin-right: .15rem;
  background: #FFFFFF;
  border-radius: 50%;
  cursor: pointer;
}
</style>
