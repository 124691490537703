import {
  UIData
} from "@/common/targer";
import { singleTon } from "@/utils/singleTon"
import utils from '@/utils/utils'


//引导页对象
class Circle { //圆对象
  isLoad = false;
  constructor(ctx, cvs, imgId = 0, imgMaxWidth = 3.6, imgMinWidth = 1.1) {
    const fontSize = document.getElementsByTagName('html')[0].style.fontSize.replace('px', '');
    const maxWidth = Math.floor(imgMaxWidth * +fontSize);
    const minWidth = Math.floor(imgMinWidth * +fontSize);
    this.imgWidth = utils.getRandom(minWidth, maxWidth); //图片宽度
    this.cvs = cvs; //画布对象
    this.y = utils.getRandom(0, cvs.height - imgMaxWidth / 2); //y坐标
    this.x = utils.getRandom(0, cvs.width - imgMaxWidth / 2); //x坐标
    this.ctx = ctx; //canvas上下文
    this.imgId = imgId; //图片序号
    if (!this.imgId || this.imgId > 10) {
      this.imgId = utils.getRandom(1, 10);
    }
    this.img = new Image(this.imgWidth,this.imgWidth); //生成图片
    this.img.onload = () => {
      this.isLoad = true; //图片是否加载完成
    }
    this.img.src = `https://img.jozobio.com/img/guide_ball${this.imgId}@2x.png`;
    //每秒钟移动速度
    this.xSpeed = utils.getRandom(-50, 50);
    this.ySpeed = utils.getRandom(10, 100);
    //上一次作画时间
    this.lastDrawTime = null;
  }
  draw() {
    //更新坐标
    if (this.lastDrawTime) {
      //上一次绘画过 不是第一次
      const duration = (Date.now() - this.lastDrawTime) / 1000; //获取时间差 秒单位
      //距离
      const xDis = this.xSpeed * duration,
        yDis = this.ySpeed * duration;
      //新的坐标
      let x = this.x + xDis;
      let y = this.y - yDis;
      //判断是否图片x是否到达尽头
      if (x > this.cvs.width - this.imgWidth) {
        x = this.cvs.width - this.imgWidth;
        this.xSpeed = -this.xSpeed;
      } else if (x < 0) {
        x = 0;
        this.xSpeed = -this.xSpeed;
      }
      //判断图片是否在视图不可见
      if (y <= -this.imgWidth) {
        y = this.cvs.height;
        x = utils.getRandom(0, this.cvs.width - this.imgWidth / 2);
      }
      this.x = x;
      this.y = y;
    }
    if (!this.isLoad) {
      return ;
    }
    this.ctx.drawImage(this.img, this.x, this.y, this.imgWidth * devicePixelRatio, this.imgWidth * devicePixelRatio);
    this.lastDrawTime = Date.now();
  }
}
export class Graph {
  constructor(cvs, circleNumber = 10) {
    this.cvs = cvs;
    this.ctx = cvs.getContext('2d');
    this.circles = new Array(circleNumber).fill(0).map((v, index) => new Circle(this.ctx, cvs,index + 1));
  }

  //画
  draw() {
    requestAnimationFrame(() => {
      this.draw();
    });
    //每次重绘清空画布
    this.ctx.clearRect(0, 0, this.cvs.width, this.cvs.height);
    for (let i = 0; i < this.circles.length; i++) {
      const circle = this.circles[i];
      circle.draw();
    }
  }
}
//引导页对象

//banner元素对象
export class Banner extends UIData {
  constructor(target) {
    super(target);
  }
}
//企业导航元素对象
export class Anchor extends UIData {
  constructor(target) {
    super(target)
  }

  toggleIndex(target, index) {
    target.navIndex = index;
  }
}
//研究元素对象
export class Research extends UIData {
  constructor(target) {
    super(target);
  }

  //点击切换导航
  toggleNav(target, index) {
    target.isAnimate = false
    setTimeout(() => {
      target.toggleIndex = index;
      target.isAnimate = true;
    }, 150);
  }

}
//宣传元素对象
export class Overview extends UIData {
  constructor(target) {
    super(target);
  }
}

//index所有数据
class IndexData {
  data = {}
  //首页数据实例
  constructor(target) {
    this.data = target;
  }
}
//单例 只调用一次接口
const newIndexData = singleTon(IndexData);

export { newIndexData as IndexData };
