import http from './http'

/**
 * 基础信息接口
 */

export default {
  initConfig() {
    return http.get('/index/init');
  },
  index() {//首页接口
    return http.get('/index/index');
  },
  search(params) {
    return http.get('/index/seach', {
      params
    });
  }
}
