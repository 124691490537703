<template>
  <div class="coupe-video">
    <c-title :en="'/' + navInfo.english_title" :cn="navInfo.title"></c-title>
    <section class="video-main">
      <div class="video-wrap wow fadeInDown">
        <video
          class="video"
          :src="cVideo.file"
          ref="video"
          controls
          preload="metadata"
          :poster="cVideo.image"
        >
          <source :src="cVideo.file">
        </video>
        <div class="video-play" @click="playVideo" :style="{display: isPaused && !isLoadError ? 'block' : 'none'}">
          <img class="video-play-icon" src="https://img.jozobio.com/img/product/coupe/product_video_icon.png" alt="开始播放">
        </div>
      </div>
      <div class="video-change-wrap">
        <div
          class="change-item wow fadeInUp"
          :class="{active: videoIndex === index}"
          :data-wow-delay="`${0.3 * (index * 1)}s`"
          v-for="(item, index) in cProducts"
          @click="item.toggleVideo($vnode.componentInstance, index)"
          @mouseenter="item.hoverToggleVideo($vnode.componentInstance, index)()"
        >
          <div class="wrapper">
            <div class="change-icon-wrap">
              <img class="change-icon" :src="item.icon_image" />
              <img class="change-active-icon" :src="item.icon_act_image" />
            </div>
            <div class="title-wrap">
              <h3 class="video-title">{{ item.title }}</h3>
            </div>
            <div class="play-icon-wrap">
              <img class="play-icon" src="https://img.jozobio.com/img/product/coupe/product_video_icon.png" alt="play"/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="ball-wrap">
      <img src="https://img.jozobio.com/uploads/20231207/FjgyEiidRDyKROIFFHhUNh8nKDfC.png" class="ball-img ball-1"/>
      <img src="https://img.jozobio.com/uploads/20231207/Fr04GP13El3kO-IcyiOXBW4n1E6O.png" class="ball-img ball-2"/>
      <img src="https://img.jozobio.com/uploads/20231207/FvUS0tiPGn9KR0SPOyOVb9BnKjYm.png" class="ball-img ball-3"/>
      <img src="https://img.jozobio.com/uploads/20231207/Fo4eyvVNcbTjFuilyGg3NKZGYp0-.png" class="ball-img ball-4"/>
    </div>
  </div>
</template>

<script>
import Title from "@/views/product/components/title.vue";
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'c-title': Title
  },
  data() {
    return {
      video: null,
      isPaused: true,
      isLoadError: false,
      videoIndex: 0
    }
  },
  mounted() {
    this.onVideoEvent();
  },
  destroyed() {
  },
  computed: {
    cProducts() {
      return this.$store.state.product.productInfo.coupeVideo || [];
    },
    cVideo() {
      return this.cProducts[this.videoIndex] || {};
    }
  },
  methods: {
    onVideoEvent() {
      this.$set(this, 'video', this.$refs.video)
      this.video.addEventListener('pause', () => {
        this.isPaused = true;
      });
      this.video.addEventListener('play', () => {
        this.isPaused = false;
      });
      this.video.addEventListener('error', () => {
        this.isLoadError = true;
      });
    },
    playVideo() {
      if (!this.video) return;
      this.video.play();
    }
  }
}
</script>

<style scoped lang="less">
.coupe-video {
  position: relative;
  overflow-x: hidden;
}
.video-main {
  padding: 0 2rem;
  margin-bottom: 1.5rem;
}
.video-wrap {
  position: relative;
}
.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
}
.video-play-icon {
  width: .54rem;
}
.video {
  width: 100%;
}
.video-change-wrap {
  display: flex;
  margin-top: -.05rem;
  box-shadow: 0 .03rem .08rem 0 rgba(11,74,128,0.16);
}
.change-item {
  position: relative;
  flex: 1;
  height: 1.71rem;
  border-right: .01rem solid rgba(22, 22, 22, 0.16);
  cursor: pointer;
  &.active {
    >.wrapper {
      top: -.4rem;
      bottom: -.2rem;
      background: linear-gradient(156deg, #0089FF 20%, #00C2BC 80%);
      >.change-icon-wrap {
        transform: translateY(-.2rem);
        >.change-icon {
          opacity: 0;
        }
        >.change-active-icon {
          opacity: 1;
        }
      }
      >.title-wrap {
        transform: translateY(-.2rem);
        >.video-title {
          color: #fff;
        }
      }
      >.play-icon-wrap {
        bottom: .38rem;
        >.play-icon {
          opacity: 1;
        }
      }
    }
  }
  &:hover {
    >.wrapper {
      top: -.4rem;
      bottom: -.2rem;
      background: linear-gradient(156deg, #0089FF 20%, #00C2BC 80%);
      >.change-icon-wrap {
        transform: translateY(-.2rem);
        >.change-icon {
          opacity: 0;
        }
        >.change-active-icon {
          opacity: 1;
        }
      }
      >.title-wrap {
        transform: translateY(-.2rem);
        >.video-title {
          color: #fff;
        }
      }
      >.play-icon-wrap {
        bottom: .38rem;
        >.play-icon {
          opacity: 1;
        }
      }
    }
  }
}
.wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: .5s ease;
}
.change-icon-wrap {
  position: relative;
  margin-bottom: .25rem;
  transition: .3s ease;
}
.change-icon,
.change-active-icon {
  height: .35rem;
  opacity: 1;
  transition: opacity .4s ease-in-out;
  &.change-active-icon {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
.title-wrap {
  transition: .3s ease;
}
.video-title {
  transition: .3s ease-in;
  color: rgba(70, 70, 70, 0.7);
}
.play-icon-wrap {
  position: absolute;
  bottom: 0;
  transition: .3s ease-in;
}
.play-icon {
  width: .35rem;
  opacity: 0;
  transition: .8s ease-in;
}

.ball-img {
  position: absolute;
  top: 0;
  z-index: -1;
  &.ball-1 {
    top: 1.54rem;
    left: .27rem;
    width: 1.69rem;
  }
  &.ball-2 {
    top: 1.78rem;
    left: 1.39rem;
    width: 3.35rem;
  }
  &.ball-3 {
    top: 1.86rem;
    right: .73rem;
    width: 1.69rem;
  }
  &.ball-4 {
    top: 2.1rem;
    right: calc(1.3rem - 3.35rem);
    width: 3.35rem;
  }
}
</style>
