<template>
  <div class="product">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
      :index="navIndex"
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      @returnVal="retNav">
    </t-nav-list>
    <template v-if="navIndex === 0">
      <product-list :nav-info="cSubNavTitle"></product-list>
    </template>
    <template v-if="navIndex === 1">
      <being-product :nav-info="cSubNavTitle"></being-product>
    </template>
    <template v-if="navIndex === 2">
      <coupe-video :nav-info="cSubNavTitle"></coupe-video>
    </template>
  </div>
</template>

<script>
import ProductList from "@/views/product/components/product-list.vue";
import BeingProduct from "@/views/product/components/being-product.vue";
import CoupeVideo from "@/views/product/components/coupe-video.vue";
import TopBanner from "@/views/product/mixins/top-banner";
export default {
  name: 'Product',
  components: {
    ProductList,
    BeingProduct,
    CoupeVideo
  },
  mixins: [TopBanner]
};
</script>

<style lang="less" scoped>

</style>
