<template>
  <div class="recruit-list">
    <el-table
        :data="recruitList"
        stripe
        style="width: 100%">
      <el-table-column
          prop="name"
          label="职位名称"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="category"
          label="职位类型"
          align="center"
      >
      </el-table-column>
      <el-table-column
          prop="site"
          label="工作地点"
          align="center"
      >
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
              size="mini"
              icon="el-icon-s-promotion"
              @click="scope.row.routerDetails($route.query.p_index || 0)">申请职位</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    recruitList: {
      type: Array,
      default: () => []
    }
  }
}
</script>


<style scoped lang="less">
.recruit-list {
  margin-top: .5rem;
}
/deep/ .el-table {
  border-radius: .1rem .1rem 0 0;
}
/deep/ .el-table thead {
  color: #fff;
}
/deep/ .has-gutter {
  background: linear-gradient(90deg, #0189FF, #00C4BA);
  tr {
    background-color: transparent;
  }
}
/deep/ tr.el-table__row--striped td.el-table__cell {
  background: #EDEDED !important;
}
/deep/ th.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table__cell {
  padding: .25rem 0;
}
/deep/ .cell {
  font-size: .18rem;
}
/deep/ .el-table td.el-table__cell,
/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ .el-button {
  border-radius: .18rem;
}
</style>
