<template>
  <div class="territory">
    <list-info @returnVal="retList"></list-info>
    <template
      v-for="(item, index) in cList"
    >
      <content-info
          :key="`territory-${index}`"
          :ref="`info_${index + 1}`"
          :type="index % 2 === 0 ? 2 : 3"
          :bgColor="index % 2 === 0 ? '#ebf3fa' : ''"
          :item="item"
        @router="item.routerDetails($route.query.p_index || 0)"
      >
      </content-info>
    </template>
  </div>
</template>

<script>
import ListInfo from './childComps/ListInfo.vue';
import ContentInfo from './childComps/ContentInfo.vue';
export default {
  components: { ListInfo, ContentInfo },
  data() {
    return {

    };
  },

  mounted() {
    if (this.$route.query.r_index) {
      this.retList((+this.$route.query.r_index || 0) + 1);
    }
  },
  computed: {
    cList() {
      return this.$store.state.exploit.exploitInfo.research || []
    }
  },
  methods: {
    retList(num) {
      window.scrollTo({
        top: this.$refs['info_' + num][0].$el.offsetTop - 85,
        behavior: "smooth"
      });
    }
  },
};
</script>

<style lang="less" scoped>

</style>
