import Vue from "vue";
import store from "@/store";

export default {
	data() {
	  return {
	    navIndex: 0,
			timer: null
	  };
	},
	computed: {
	  cTitles() {
	    return ['新闻中心', this.navList[this.navIndex]?.title];
	  },
		navList() {
			return this.$store.state.news.nav.nav || [];
		},
		cNavInfo() {
			return this.$store.state.news.nav || {};
		},
		cSubNavTitle() {
			return this.navList[this.navIndex] || {};
		}
	},
	watch: {
		$route: {
			handler(newVal) {
				this.$set(this, 'navIndex', +newVal.query.p_index || 0);
			},
			deep: true
		}
	},
	created() {
		this.$set(this, 'navIndex', +this.$route.query.p_index || 0);
	},
	methods: {
	  retNav(index) {
			if (this.timer) return ;
			this.timer = setTimeout(() => {
	  	  this.navIndex = index;
				if (this.$route.path === `/news_details/${this.$route.params.id}`) {
					this.$router.push({
						path: '/news',
						query: {
							p_index: this.$route.query.p_index || 0
						}
					});
					this.$nextTick(() => {
						this.$store.commit('initWoW');
					});
				}
			}, 300);
	  }
	}
}
