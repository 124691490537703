<template>
	<div class="news-content">
		<div class="news-title-wrap">
			<h2 class="news-title wow fadeInUp">{{ item.title }}</h2>
		</div>
		<section class="content content-text wow fadeInUp" data-wow-delay="0.5s"  v-html="cContent(item.content)">
		</section>
	</div>
</template>

<script>
export default {
  data() {
    return {
      item: {}
    }
  },
  created() {
    this.item = JSON.parse(this.$route.query.item || '{}') || {};
  },
  computed: {
	cContent() {
		return function(value) {
			let str = '';
			if(value) {
				str = value.replace(/<img/g, '<img style="width:100%"')
			}
			return str;
		}
	}
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.news-content {
	padding: 0 1.8rem;
	@media screen and(max-width: 980px) {
		/deep/ .content-text * {
			font-size: .5rem !important;
		}
		/deep/ p {
			margin-left: 0 !important;
		}
	}
}
.news-title-wrap {
	margin-top: .7rem;
	border-bottom: .01rem solid #E6E6E6;
}
.news-title {
	text-align: center;
	font-size: .28rem;
	font-weight: 500;
	color: #090909;
}
.news-source-date {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: .25rem 0 .15rem;
	color: #838282;
}
.date-wrap {
	display: flex;
	align-items: center;
}
.date-icon-wrap {
	margin-right: .05rem;
}
.date-icon {
	width: .17rem;
	margin-bottom: .03rem;
}
.date,
.datetime,
.source-wrap,
.source,
.view {
	font-size: .14rem;
	font-weight: 400;
}
.split-line {
	width: .02rem;
	height: .14rem;
	margin: 0 .07rem;
	background-color: #CCCCCC;
}
.view-wrap {
	display: flex;
	align-items: center;
	margin-left: .4rem;
}
.view-icon-wrap {
	margin-right: .08rem;
}
.view-icon {
	width: .2rem;
	margin-bottom: .03rem;
}

.content {
	padding: .3rem 0 .7rem;
	border-bottom: .01rem solid #E6E6E6;
}
</style>
