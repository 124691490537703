<template>
  <div class="details message">
    <t-top-info
        :banner="cSubNavTitle.bg_image"
        :label=cNavInfo.english_title
        :title=cNavInfo.title
    ></t-top-info>
    <t-nav-list
        :titles="cTitles"
        :listInfo="navList"
        list-key="title"
        iPadding="0 .3rem"
      @returnVal="retNav"
    >
    </t-nav-list>
    <details-content></details-content>
  </div>
</template>

<script>
import TopBanner from "@/views/message/mixins/top-banner";
import DetailsContent from "@/views/message/details/components/DetailsContent.vue";
export default {
  components: {
    DetailsContent
  },
  mixins: [TopBanner]
}
</script>

<style scoped lang="less">

</style>
