import Vue from "vue";
export default {
  setImg(value) { // 格式化图片
    let str = value;
    if (str && str.indexOf('http') == -1) {
      str = 'https://jxyy.kmjfjdc.cn' + str;
    }
    return str;
  },
  setHtml(value) { // 格式化html
    let str = value;
    if (str) {
      str = str.replace(/emsp;/g, '&emsp;');
      str = str.replace(/nbsp;/g, '&nbsp;');
      str = str.replace(/\n/g, '<br/>')
      str = str.replace(/\t/g, '&nbsp')
      str = str.replace(/ /g, '&nbsp')
    }
    return str;
  },
  setFixed(value, num = 2) { //设置保留小数位数
    let number = 0;
    if (value) {
      number = value * 1;
    }
    return number.toFixed(num);
  },
  // 参数number为毫秒时间戳，format为需要转换成的日期格式
  setTime(number, format = 'Y-M-D h:m') {
		if(!number) return '';
    let time = null;
    if (String(number).length > 10) {
      time = new Date(number)
    } else {
      time = new Date(number * 1000)
    }
    let newArr = []
    let formatArr = ['Y', 'M', 'D', 'h', 'm', 's']
    newArr.push(time.getFullYear())
    newArr.push(formatNumber(time.getMonth() + 1))
    newArr.push(formatNumber(time.getDate()))

    newArr.push(formatNumber(time.getHours()))
    newArr.push(formatNumber(time.getMinutes()))
    newArr.push(formatNumber(time.getSeconds()))

    for (let i in newArr) {
      format = format.replace(formatArr[i], newArr[i])
    }
    return format;
  },
  timeLag(date1, date2) {
    let date3 = new Date(date1).getTime() - new Date(date2).getTime();   //时间差的毫秒数
    //------------------------------

    //计算出相差天数
    let days = Math.floor(date3 / (24 * 3600 * 1000))

    //计算出小时数

    let leave1 = date3 % (24 * 3600 * 1000)    //计算天数后剩余的毫秒数
    let hours = Math.floor(leave1 / (3600 * 1000))
    //计算相差分钟数
    let leave2 = leave1 % (3600 * 1000)        //计算小时数后剩余的毫秒数
    let minutes = Math.floor(leave2 / (60 * 1000))
    //计算相差秒数
    let leave3 = leave2 % (60 * 1000)      //计算分钟数后剩余的毫秒数
    let seconds = Math.round(leave3 / 1000)
    return {
      days,
      hours,
      minutes,
      seconds
    }
  },
  setWeek(value, name = '天') {
    let str = "";
    switch (Number(value)) {
      case 1:
        str = "一";
        break;
      case 2:
        str = "二";
        break;
      case 3:
        str = "三";
        break;
      case 4:
        str = "四";
        break;
      case 5:
        str = "五";
        break;
      case 6:
        str = "六";
        break;
      case 0:
        str = name;
        break;
    }
    return str;
  },
  space(lat1, lng1, lat2, lng2) {//根据经纬计算距离
    var radLat1 = lat1 * Math.PI / 180.0;
    var radLat2 = lat2 * Math.PI / 180.0;
    var a = radLat1 - radLat2;
    var b = lng1 * Math.PI / 180.0 - lng2 * Math.PI / 180.0;
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137;//地球半径
    s = Math.round(s * 10000) / 10000;
    return s  // 单位千米
  },
  formatNumber,
  deepClone(target, map = new WeakMap()) {
    if (isObject(target)) {
      const isArray = Array.isArray(target);
      const cloneTarget = isArray ? [] : {};
      if (map.has(target)) {
        return map.get(target);
      }
      map.set(target, cloneTarget);
      if (isArray) {
        this.forEach(target, (item, index) => {
          cloneTarget[index] = this.deepClone(item, map);
        });
      } else {
        const keys = Object.keys(target);
        this.forEach(keys, key => {
          cloneTarget[key] = this.deepClone(target[key], map);
        });
      }
      return cloneTarget;
    } else {
      return target;
    }
  },
  increase(time, target, key) {
    let totalTime = time || 1000;
    let num = target[key];
    let rate = 16.6; //间隔
    let step = +num / (+totalTime / rate);
    let count = 0;
    const timer = setInterval(() => {
      count = count + step;
      Vue.set(target, key, +count.toFixed(0));
      //强制让浏览器渲染
      document.body.clientHeight;
      if (count > num) {
        count = +num;
        Vue.set(target, key, +count.toFixed(0));
        document.body.clientHeight;
        clearInterval(timer);
      }
    }, rate);
  },
  forEach(array, fn) {
    for (let i = 0, len = array.length; i < len; i++) {
      fn(array[i], i)
    }
  },
  //获取范围内的随机数
  getRandom(min, max) {
    return Math.floor(Math.random() * (max + 1 - min) + min);
  }
}

// 格式化日期，如月、日、时、分、秒保证为2位数
function formatNumber(n) {
  if (!n) return ;
  n = n.toString()
  return n[1] ? n : '0' + n;
}
function isObject(target) {
  return typeof target === 'object' && target !== null;
}


