export default {
  data() {
    return {
      navIndex: 0,
      timer: null
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.navIndex = +value.query.p_index || 0;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    cTitles() {
      return [this.cNavInfo.title, this.cNavList[this.navIndex]?.title];
    },
    cNavList() {
      return this.$store.state.profile.nav.nav || [];
    },
    cNavInfo() {
      return this.$store.state.profile.nav || {};
    },
    cSubNavTitle() {
      return this.cNavList[this.navIndex] || {};
    }
  },
  methods: {
    retNav(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        if (this.$route.path === '/profile_details') {
          this.$router.push({
            path: '/profile',
            query: {
              p_index: this.$route.query.p_index
            }
          });
        }
      }, 300);
    }
  }
}
