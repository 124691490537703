<template>
  <div class="about-info">
    <div class="bg-img-wrap wow fadeInRight">
      <img class="bg-img" :src="aboutInfo.image"/>
    </div>
    <div class="info-wrap">
      <!-- <div class="title-wrap wow fadeInUp" id="titleAnimate" data-wow-delay="0s">
        <h2 class="title-cn">关于九洲生物</h2>
        <h2 class="title-en">/About us</h2>
        <img class="title-icon" src="https://img.jozobio.com/img/common/common_title_icon.png"/>
      </div> -->
      <div id="titleAnimate" class="wow fadeInUp">
        <t-title-info
            label="About us"
            title="关于九洲生物"
            title-border=".01rem dashed var(--titleColor)"
          >
        </t-title-info>
      </div>
      <div class="sub-title-wrap wow fadeInUp" data-wow-delay="0.3s">
        <h3 class="sub-title">{{cAbout ? cAbout.index_title_1 : ''}}</h3>
      </div>
      <div class="desc-wrap wow fadeInUp" data-wow-delay="0.5s">
        <p class="desc">{{ aboutInfo.about_con }}</p>
      </div>
      <div class="tips">
        {{cAbout ? cAbout.index_title_2 : ''}}
      </div>
      <div class="data-show-wrap" id="data">
        <img class="icon" src="https://img.jozobio.com/img/home/home_about_icon.png" />
        <div
          class="data-item"
          v-for="(dataItem, index) in aboutInfo.abput_json"
          :key="`about-${index}`"
        >
          <div class="data">
            {{ dataItem.value }}{{ dataItem.unit }}
          </div>
          <div class="label">{{ dataItem.label }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dom: null,
      setNum: null,
      aboutInfo: {}
    }
  },
  watch: {
    'cAbout': {
      handler(value) {
        if(value) {
          sessionStorage.setItem('indexAboutInfo', JSON.stringify(value.about))
          this.$nextTick(() => {
            this.init();
          })
        }
      },
      deep: true
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.listenerAnimate();
  },
  beforeDestroy() {
    this.dom.removeEventListener('animationstart', this.setNum);
  },
  computed: {
    cAbout() {
      return this.$store.state.indexInfo.data;
    },
  },
  methods: {
    init() {
      const indexAboutInfo = sessionStorage.getItem('indexAboutInfo');
      if(indexAboutInfo) {
        this.aboutInfo = JSON.parse(indexAboutInfo);
      }
    },
    listenerAnimate() {
      this.dom = document.getElementById('titleAnimate');
      if (!this.dom) return ;
      this.setNum = () => {
        if (!this.aboutInfo.abput_json) return ;
        this.aboutInfo.abput_json.forEach(v => {
          this.$utils.increase(1000, v, 'value');
        });
      }
      this.dom.addEventListener('animationstart', this.setNum);
    }
  }
}
</script>

<style scoped lang="less">
.about-info {
  position: relative;
  padding: 1.5rem var(--wrapper) 1.1rem;
  overflow: hidden;
  &:before {
    position: absolute;
    top: 0;
    right: 5.5rem;
    content: '';
    width: 4rem;
    height: 4rem;
    transform: translateY(-70%);
    background-color: rgba(224, 242, 255, 1);
    border-radius: 50%;
  }
  .bg-img-wrap {
    position: absolute;
    right: 0;
    bottom: 0;
    .bg-img {
      width: 7.8rem;
    }
  }
  .info-wrap {
    width: 8.5rem;
    .title-wrap {
      display: flex;
      align-items: center;
      padding-bottom: .15rem;
      border-bottom: .01rem dashed rgba(104, 109, 128, 1);
      .title-cn {
        font-size: .4rem;
        font-weight: bold;
        color: var(--titleColor);
      }
      .title-en {
        margin-left: .1rem;
        font-size: .4rem;
        font-weight: 500;
        color: var(--titleColor);
      }
      .title-icon {
        margin: .1rem 0 0 .1rem;
        width: .3rem;
      }
    }
    .sub-title-wrap {
      margin: .2rem 0 .2rem;
      .sub-title {
        font-size: .32rem;
        font-weight: 500;
        color: var(--titleColor);
      }
    }
    .desc-wrap {
      padding-bottom: .4rem;
      border-bottom: .01rem solid rgba(236, 236, 236, 1);
      .desc {
        line-height: .3rem;
        color: #6C6C6C;
      }
    }
    .tips {
      margin: .25rem 0 .13rem;
      font-size: .15rem;
      color: var(--titleColor);
    }
    .data-show-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .34rem 0;
      border-top: .01rem solid var(--titleColor);
      border-bottom: .01rem solid var(--titleColor);
      .icon {
        position: absolute;
        width: .13rem;
        top: 0;
        left: 0;
        transform: translateY(-50%);
        padding-right: .03rem;
        background-color: #fff;
        box-sizing: content-box;
      }
      .data-item {
        .data {
          display: flex;
          font-family: Archive;
          font-size: .26rem;
          line-height: 1;
          color: var(--titleColor);
        }
        .label {
          font-size: .26rem;
          color: var(--titleColor);
        }
      }
    }
  }
}
</style>
