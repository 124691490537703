<template>
  <div class="workstation">
    <!-- <t-title-info
        margin=".5rem 1.8rem"
        :label="cWorkbench.e_title"
        :title="cWorkbench.title"
        labelColor="#000"
        :icon-show="true"
        :listInfo="navList"
        :index="navIndex"
        @returnVal="retTitle"
    ></t-title-info> -->
    <t-title-info
        margin=".5rem 1.8rem"
        label="R&D Platform"
        title="研发平台"
        labelColor="#000"
        :icon-show="true"
        :listInfo="navList"
        :index="navIndex"
        @returnVal="retTitle"
    ></t-title-info>
    <text-info></text-info>
    <template v-if="navIndex === 0">
      <html-text></html-text>
    </template>
    <template v-if="navIndex === 1">
      <platform></platform>
    </template>
  </div>
</template>

<script>
import TextInfo from "@/views/exploit/achievement/childComps/TextInfo.vue";
import HtmlText from "@/views/exploit/workstation/childComps/html-text.vue";
import Platform from "@/views/exploit/achievement/childComps/platform.vue";
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    HtmlText,
    TextInfo,
    Platform
  },

  data() {
    return {
      navList: [
        {
          id: 1,
          name: '专家工作台'
        },
        {
          id: 2,
          name: '技术中心'
        }
      ],
      navIndex: 0,
      timer: null
    }
  },
  computed: {
    cWorkbench() {
      return this.$store.state.exploit.exploitInfo.workbench[this.navIndex] || {};
    }
  },
  mounted() {
    if (this.$route.query.w_index) {
      this.navIndex = +this.$route.query.w_index;
      this.retTitle(+this.$route.query.w_index);
    }
  },
  methods: {
    retTitle(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        let type = 3;
        if (index === 1) {
          type = 2;
        }
        this.$store.dispatch('exploit/exploit', {type: type, l: 3});
        this.$nextTick(() => {
          this.$store.commit('initWoW');
        });
      }, 300);
    }
  }
}
</script>

<style scoped lang="less">

</style>
