<template>
  <div class="product-info">
    <div class="ball">
      <img class="ball-img-1 ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_1.png">
    </div>
    <div class="ball">
      <img class="ball-img-2 ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_2.png">
    </div>
    <div class="ball">
      <img class="ball-img-3 ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_3.png">
    </div>
    <div class="ball">
      <img class="ball-img-4 ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_4.png">
    </div>
    <div class="ball">
      <img class="ball-img-5 ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_5.png">
    </div>
    <!-- 轮播 -->
    <div class="product-swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="(productItem, index) in cProduct"
          :key="index"
        >
          <div class="product-img-wrap wow fadeIn">
            <img class="product-img" :src="productItem.indeximg"/>
          </div>
          <div class="info-wrap wow fadeInUp" data-wow-delay="0s">
            <div class="title-warp">
              <h2 class="title">推荐产品</h2>
            </div>
            <div class="sub-title-wrap wow fadeInUp" data-wow-delay="0.3s">
              <h3 class="sub-title">{{ productItem.title }}</h3>
            </div>
            <div class="desc-wrap wow fadeInUp" data-wow-delay="0.6s">
              <p class="desc more-ellipsis clamp-3">{{ productItem.des }}</p>
            </div>
            <div class="more-btn btn  wow fadeInUp" data-wow-delay="0.6s" @click="productItem.routerDetails($route.query.p_index || 0)">
              <div class="btn-text">了解更多</div>
              <div class="icon-wrap">
                <img class="icon" src="https://img.jozobio.com/img/home/home_product_more.png" alt="了解更多"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-button-next wow fadeInRight">
        <img class="icon-button" src="https://img.jozobio.com/img/home/home_product_next.png" >
      </div>
      <div class="swiper-button-prev wow fadeInLeft">
        <img class="icon-button" src="https://img.jozobio.com/img/home/home_product_prev.png" >
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.initSwiper();
  },
  computed: {
    cProduct() {
      return this.$store.state.indexInfo.data?.product || [];
    }
  },
  methods: {
    initSwiper() {
      this.swiper = new Swiper('.product-swiper-container', {
        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        speed: 800,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.product-info {
  position: relative;
  padding: 1.05rem var(--wrapper) .75rem;
  overflow: hidden;
  .ball {
    .ball-img {
      position: absolute;
      z-index: -1;
    }
    .ball-img-1 {
      width: 4rem;
      top: -1.83rem;
      left: -1.93rem;
    }
    .ball-img-2 {
      width: 5rem;
      top: 1.66rem;
      left: 2.5rem;
    }
    .ball-img-3 {
      width: 4.5rem;
      top: 1.1rem;
      right: 3.7rem;
    }
    .ball-img-4 {
      width: 3rem;
      top: -.61rem;
      right: -.62rem;
    }
    .ball-img-5 {
      width: 2.2rem;
      bottom: 0;
      right: -.45rem;
    }
  }
  .product-swiper-container {
    .swiper-wrapper {
      height: 6.2rem;
    }
    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon-button {
      width: .56rem;
    }
    .swiper-button-prev {
      left: var(--wrapper);
      &:after {
        content: '';
      }
    }
    .swiper-button-next {
      right: var(--wrapper);
      &:after {
        content: '';
      }
    }
    .product-img-wrap {
      .product-img {
        width: 2.9rem;
      }
    }
    .info-wrap {
      margin-left: 2.4rem;
      .title-warp {
        .title {
          font-size: .4rem;
          font-weight: normal;
          color: #1B1B1B;
        }
      }
      .sub-title-wrap {
        margin: .35rem 0 .3rem;
        .sub-title {
          font-size: .32rem;
          font-weight: bold;
          color: #0079EB;
        }
      }
      .desc-wrap {
        width: 6.5rem;
        .desc {
          color: #707070;
          line-height: .4rem;
        }
      }
      .more-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.4rem;
        height: .52rem;
        margin-top: 1rem;
        background: linear-gradient(90deg, #0078EC, #00C4BA);
        border-radius: .25rem;
        .btn-text {
          font-size: .18rem;
          color: #FFFFFF;
        }
        .icon-wrap {
          margin-left: .1rem;
          .icon {
            width: .18rem;
          }
        }
      }
    }
  }
}
</style>
