<template>
  <div class="profile">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
      :titles="cTitles"
      :listInfo="cNavList"
    ></t-top-info>
    <t-nav-list
      :titles="cTitles"
      :listInfo="cNavList"
      listKey="title"
      :is-wow="false"
      @returnVal="retVal"
    >
    </t-nav-list>
    <about-info ref="aboutInfo"></about-info>
    <course-info ref="courseInfo"></course-info>
    <writing-info ref="writingInfo"></writing-info>
    <honor-info ref="honorInfo"></honor-info>
    <base-info ref="baseInfo"></base-info>
    <media-info ref="mediaInfo"></media-info>
  </div>
</template>

<script>
import AboutInfo from './childComps/AboutInfo.vue';
import CourseInfo from './childComps/CourseInfo.vue';
import WritingInfo from "@/views/profile/childComps/writingInfo.vue";
import HonorInfo from './childComps/HonorInfo.vue';
import BaseInfo from './childComps/BaseInfo.vue';
import MediaInfo from './childComps/MediaInfo.vue';

import TopBanner from "@/views/profile/mixins/top-banner";
export default {
  name: 'Profile',
  components: { AboutInfo, CourseInfo, HonorInfo, BaseInfo, MediaInfo, WritingInfo },
  mixins: [TopBanner],
  data() {
    return {
      navIndex: 0,
      num: 1.94
    };
  },
  watch: {
    $route() {
      this.init();
    }
  },
  created() {
    if(document.body.clientWidth <= 980) {
      this.num = 3.5;
    }
  },
  mounted() {
    setTimeout(() => {
      this.init();
    }, 150)
  },
  methods: {
    init() {
      const html = document.getElementsByTagName('html')[0];
      const fontSize = +html.style.fontSize.replace('px', '');
      window.scrollTo({
        top: this.$refs[this.cNavList[this.$route.query.p_index || 0]?.key]?.$el.offsetTop - this.num * fontSize,
        behavior: "smooth"
      });
    },
    retVal(index) {
      const html = document.getElementsByTagName('html')[0];
      const fontSize = + html.style.fontSize.replace('px', '');
      let num = 1.94
      const scroll = this.$refs[this.cNavList[index]?.key]?.$el.offsetTop - this.num * fontSize;
      window.scrollTo({
        top: scroll,
        behavior: "smooth"
      });
    }
  }
};
</script>

<style lang="less" scoped>

</style>
