export default {
  methods: {
    pageCurrent(current) {
      this.apiSend(current);
    },
    apiSend(current) {
      this.$store.dispatch('message/message', {
        type: this.navIndex + 1,
        page: current,
        num: this.pageInfo.num || 6
      });
    }
  }
}
