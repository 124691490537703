import { render, staticRenderFns } from "./TTopNav.vue?vue&type=template&id=64a12fb7&scoped=true"
import script from "./TTopNav.vue?vue&type=script&lang=js"
export * from "./TTopNav.vue?vue&type=script&lang=js"
import style0 from "./TTopNav.vue?vue&type=style&index=0&id=64a12fb7&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a12fb7",
  null
  
)

export default component.exports