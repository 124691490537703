<template>
  <div class="research-info">
    <div class="bg-img-wrap wow fadeInDown">
      <img class="bg-img" :src="cNav[toggleIndex]?.indeximg" />
    </div>
    <div class="info-wrap">
      <!-- <div class="title-wrap wow fadeInUp"  data-wow-delay="0s">
        <h2 class="title-cn">{{ cNav[toggleIndex]?.title }}</h2>
        <h2 class="title-en">/{{ cNav[toggleIndex]?.english_title }}</h2>
        <img src="https://img.jozobio.com/img/common/common_title_icon_w.png" alt="" class="img">
      </div> -->
      <t-title-info
        :label="cNav[toggleIndex]?.english_title"
        :title="cNav[toggleIndex]?.title"
        labelColor="#fff"
        titleColor="#fff"
        title-border=".01rem dashed var(--titleColor)"
        ></t-title-info>
      <div class="desc-wrap wow fadeInUp" data-wow-delay="0.4s">
        <p class="desc">{{ cNav[toggleIndex]?.des }}</p>
      </div>
      <nav class="nav-list">
        <div
          class="nav-item wow fadeInLeft"
          :class="{animateTime: isAnimate}"
          :style="{'--leftTime': `${0.6 + (index * 0.2)}s`}"
          :ref="`research_nav_${index}`"
          :data-wow-delay="(0.2 * (index * 1)) + 's'"
          v-for="(navItem, index) in cNav[toggleIndex]?.data"
          :key="`nav-${index}`"
          @click="cNav[toggleIndex].routerUrl(`/exploit?p_index=${toggleIndex}${cParams(index)}`)"
        >
          <div class="label">{{ navItem.title }}</div>
          <div class="icon-wrap">
            <img class="icon" src="https://img.jozobio.com/img/home/home_research_arrow_icon.png" />
          </div>
        </div>
      </nav>
    </div>
    <div class="toggle-list-wrap wow fadeInUp">
      <nav class="toggle-list">
        <div
          :class="['toggle-item', {active: toggleIndex === index}]"
          v-for="(toggleItem, index) in cNav"
          :key="`toggle-${index}`"
          @click="toggleItem.toggleNav($vnode.componentInstance, index)"
        >
          <div class="toggle-icon-wrap">
            <img class="toggle-icon" :style="{opacity: toggleIndex === index ? 0 : 1}" :src="toggleItem.image1" :alt="toggleItem.title"/>
            <img class="toggle-icon active" :style="{opacity: toggleIndex === index ? 1 : 0}" :src="toggleItem.image2" :alt="toggleItem.title"/>
          </div>
          <div class="toggle-text">{{ toggleItem.title }}</div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      toggleIndex: 0,
      isAnimate: false
    }
  },
  computed: {
    cNav() {
      return this.$store.state.indexInfo.data?.nav1 || [];
    },
    cParams() {
      return (index) => {
        let paramStr = '';
        if (this.toggleIndex === 0) {
          paramStr += `&r_index=${index}`;
        }
        if (this.toggleIndex === 1) {
          paramStr += `&a_index=${index}`;
        }
        if (this.toggleIndex === 2) {
          paramStr += `&w_index=${index}`;
        }
        if (this.toggleIndex === this.cNav.length - 1) {
          paramStr += `&pa_index=${index}`;
        }
        return paramStr
      }
    }
  },
  mounted() {
  },
  methods: {
    handleToggle(index) {
      this.toggleIndex = index;
    }
  }
}
</script>


<style scoped lang="less">
.research-info {
  position: relative;
  .bg-img-wrap {
    .bg-img {
      width: 100%;
      height: 8.24rem;
    }
  }
  .info-wrap {
    position: absolute;
    top: 1.18rem;
    left: 1.29rem;
    .title-wrap {
      display: flex;
      align-items: flex-end;
      font-weight: bold;
      color: #FFFFFF;
      .title-cn {
        font-size: .44rem;
      }
      .title-en {
        margin-left: .1rem;
        margin-bottom: .03rem;
        font-size: .24rem;
        font-weight: initial;
      }
    }
    .desc-wrap {
      margin: .25rem 0 .65rem;
      width: 6.85rem;
      .desc {
        font-size: .14rem;
        color: #FFFFFF;
        opacity: 0.9;
        line-height: .3rem;
      }
    }
    .nav-list {
      .nav-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .15rem 0;
        width: 2.85rem;
        border-top: .01rem solid rgba(255,255,255, .45);
        cursor: pointer;
        &:last-child {
          border-bottom: .01rem solid rgba(255,255,255, .45);
        }
        &:hover {
          .icon-wrap {
            .icon {
              animation-name: arrow;
            }
          }
        }
        .label {
          font-size: .2rem;
          color: #FFFFFF;
        }
        .icon-wrap {
          margin-bottom: .03rem;
          .icon {
            width: .23rem;
            animation-duration: 1s;
          }
        }
        &.animateTime {
          animation-name: toggleFade;
          animation-duration: var(--leftTime);
          animation-delay: 0s !important;
        }
      }
    }
  }
  .toggle-list-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 1.29rem;
    background-color: rgba(8, 90, 148, 0.35);
    .toggle-list {
      display: flex;
      align-items: center;
      .toggle-item {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: .6rem;
        background-color: #CAEAF7;
        transition: all .5s ease-in-out;
        box-shadow: 0 .03rem .07rem 0 rgba(0,137,255,0.15);
        cursor: pointer;
        &.active {
          background-color: #7EB22C;
          .toggle-text {
            color: #fff;
          }
        }
        .toggle-icon-wrap {
          position: relative;
          margin-right: .15rem;
          .toggle-icon {
            width: .2rem;
            transition: .3s ease-in;
            &.active {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }
          }
        }
        .toggle-text {
          font-size: .2rem;
          color: #1B1B1B;
          opacity: .85;
        }
      }
    }
  }
}
@keyframes toggleFade {
  0% {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes arrow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-.05rem);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
