<template>
  <div class="overview-info">
    <div class="ball">
      <img class="ball-img" src="https://img.jozobio.com/img/lzy_test/home_ball_6.png" />
    </div>
    <div class="bg-img-wrap">
      <img :class="['bg-img', {next: !isNext}]" :src="cPromotion[nextIndex]?.image" />
      <template v-if="cPromotion.length > 1">
        <img :class="['bg-img', {next: isNext}]" :src="cPromotion[onceNextIndex].image" />
      </template>
    </div>
    <div class="overview-wiper-container">
      <div class="swiper-wrapper">
        <div
            class="swiper-slide"
            v-for="(promotionItem, index) in cPromotion"
            :key="`promotion-${index}`"
        >
          <div class="title-wrap wow lightSpeedIn">
            <h2 class="title">{{ promotionItem.title }}</h2>
          </div>
          <div class="explain wow lightSpeedIn" data-wow-delay="0.3s">
            <strong class="text">{{ promotionItem.sub_title }}</strong>
          </div>
        </div>
      </div>
      <div class="swiper-pagination wow fadeIn"></div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      swiper: null,
      nextIndex:  0,
      onceNextIndex: 1,
      isNext: false
    }
  },
  mounted() {
    this.initSwiper();
  },
  computed: {
    cPromotion() {
      return this.$store.state.indexInfo.data?.promotion || [];
    }
  },
  methods: {
    initSwiper() {
      const _this = this;
      this.swiper = new Swiper('.overview-wiper-container', {
        slidesPerView : 1,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        pagination: { //分页器
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'home-overview-paging-bullet'
        },
        on: {
          slideChangeTransitionEnd: function () {
            this.nextIndex = this.activeIndex;
            this.onceNextIndex = this.activeIndex + 1 > _this.data.promotion.length - 1 ? 0 : this.activeIndex + 1;
            this.isNext = !this.isNext;
          }
        }
      });

    }
  }
}
</script>

<style scoped lang="less">
.overview-info {
  position: relative;
  height: 4.18rem;
  .ball {
    .ball-img {
      position: absolute;
      width: 3.1rem;
      top: -.87rem;
      left: 0;
    }
  }
  >.bg-img-wrap {
    >.bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: .3s ease-in;
      opacity: 0;
      &.next {
        opacity: .6;
      }
    }
  }
  .overview-wiper-container {
    padding: 1.25rem 1rem;
    overflow: hidden;
    .title-wrap {
      .title {
        text-align: center;
        font-size: .52rem;
        font-weight: bold;
        background-image: linear-gradient(to right, rgb(2, 112, 219), rgb(0, 196, 186));
        color: #ffffff;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    .swiper-pagination {
      bottom: 1.8rem;
    }
  }
  .explain {
    margin-top: .8rem;
    font-size: .18rem;
    color: #01A4CA;
    text-align: center;
    .text {
      font-weight: initial;
    }
  }
}
/deep/ .home-overview-paging-bullet {
  display: inline-block;
  width: .07rem;
  height: .07rem;
  margin-right: .1rem;
  background-color: #019FCD;
  border-radius: 50%;
  cursor: pointer;
}
</style>
