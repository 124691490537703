<template>
  <div class="anchor-list">
    <nav class="list">
      <div
        class="anchor-item wow fadeInLeft"
        :class="{active: navIndex === index}"
        :data-wow-delay="(0.4 * (index * 1)) + 's'"
        v-for="(anchorItem, index) in cNav"
        :key="`anchor-${index}`"
        @click="anchorItem.toggleIndex($vnode.componentInstance, index) || anchorItem.routerUrl('/profile?p_index=0', false)"
      >
        <div class="anchor-icon-wrap">
          <img class="anchor-icon" :src="anchorItem.image1" :alt="anchorItem.title" />
          <img class="anchor-icon active" :src="anchorItem.image2" :alt="anchorItem.title"/>
        </div>
        <div class="nav-text nowrap">{{ anchorItem.title }}</div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navIndex: -1
    }
  },
  computed: {
    cNav() {
      return this.$store.state.indexInfo.data?.nav
    }
  },
  created() {
  },
  mounted() {

  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.anchor-list {
  background-color: #E5F1FD;
  .list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .anchor-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 3.86rem;
      height: 2.02rem;
      background: #EEEEEE;
      transition: all .5s ease-in-out;
      cursor: pointer;
      &:first-child {
        background-color: #E5F1FD;
      }
      &:last-child {
        background-color: #fff;
      }
      &.active {
        background: linear-gradient(90deg, #0078EC, #00C4BA);
        .anchor-icon-wrap {
          .anchor-icon {
            opacity: 0;
            &.active {
              opacity: 1;
            }
          }
        }
        .nav-text {
          color: #fff;
        }
      }
      &:hover {
        background: linear-gradient(90deg, #0078EC, #00C4BA);
        .anchor-icon-wrap {
          .anchor-icon {
            opacity: 0;
            &.active {
              opacity: 1;
            }
          }
        }
        .nav-text {
          color: #fff;
        }
      }
      .anchor-icon-wrap {
        position: relative;
        margin-bottom: .4rem;
        .anchor-icon {
          width: .4rem;
          transition: .3s ease-in;
          &.active {
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
      }
      .nav-text {
        font-size: .20rem;
        color: #040404;
      }
    }
  }
}
</style>
