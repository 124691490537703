import { render, staticRenderFns } from "./TopInfo.vue?vue&type=template&id=66fa5062&scoped=true"
import script from "./TopInfo.vue?vue&type=script&lang=js"
export * from "./TopInfo.vue?vue&type=script&lang=js"
import style0 from "./TopInfo.vue?vue&type=style&index=0&id=66fa5062&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66fa5062",
  null
  
)

export default component.exports