import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/utils'
import api from './api/api'

//网站seo
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo);

import animated from "animate.css";
Vue.use(animated)

Vue.prototype.$utils = utils
Vue.prototype.$api = api
Vue.prototype._router = router

Vue.config.productionTip = false

import {
  Select,
  Option,
  Table,
  TableColumn,
  Input,
  Radio,
  DatePicker,
  Message,
  Pagination,
  Button,
  Dialog,
  Form,
  FormItem,
  Upload,
  Popover,
  Checkbox,
  CheckboxGroup
} from 'element-ui';

Vue.use(Select)
Vue.use(Option)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Radio)
Vue.use(DatePicker)
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Upload)
Vue.use(Popover)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.prototype.$message = Message

import TNavList from './components/content/tNavList/TNavList.vue'
import TTopInfo from './components/content/tTopInfo/TTopInfo.vue'
import TTitleInfo from './components/content/tTitleInfo/TTitleInfo.vue'
Vue.component('TNavList', TNavList)
Vue.component('TTopInfo', TTopInfo)
Vue.component('TTitleInfo', TTitleInfo)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
