import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

//modules
import news from "@/store/news"; //新闻模块
import profile from "@/store/profile"; //企业概况模块
import product from "@/store/product"; //公司产品模块
import exploit from "@/store/exploit"; //技术开发模块
import message from "@/store/message"; //信息公示模块
import we from "@/store/we"; //加入我们模块

//target
import {
  Banner,
  Anchor,
  Research,
  Overview,
  IndexData
} from "@/views/index/target";
import {
  NewsNav,
  News
} from "@/views/news/target/news"; //新闻中心
import {
  About,
  profileTypes
} from "@/views/profile/target/profile"; //公司概况
import {
  Product
} from "@/views/product/target/product"; //公司产品
//wow入口
import {
  WowEntry
} from "@/common/targer";


export default new Vuex.Store({
  state: {
    isMobile: false,
    configInfo: {},
    indexInfo: {},
    isPromise: false,
    isGuide: false, //是否已经过了
    isNavShow: false,
    isMedia: false, //是否触发媒体查询
    apiUse: {
      index: [
        {
          method: 'index'
        }
      ],
      profile: [
        {
          method: 'profile/profile',
        }
      ],
      product: [
        {
          method: 'product/product',
          data: 1
        },
        {
          method: 'product/product',
          data: 2
        },
        {
          method: 'product/product',
          data: 3
        }
      ],
      exploit: [
        {
          method: 'exploit/exploit',
          data: {
            type: 1
          }
        },
        {
          method: 'exploit/exploit',
          data: {
            type: 2
          }
        },
        {
          method: 'exploit/exploit',
          data: {
            type: 3
          }
        },
        {
          method: 'exploit/exploit',
          data: {
            type: 4
          }
        },
      ],
      news: [
        {
          method: 'news/news',
          data: {
						type: 0,
						page: 1,
						num: 6
					}
        },
				{
					method: 'news/news',
					data: {
						type: 1,
						page: 1,
						num: 6
					}
				}
      ],
			message: [
				{
					method: 'message/message',
					data: {
						type: 1
					}
				},
				{
					method: 'message/message',
					data: {
						type: 2,
						page: 1,
						num: 6
					}
				},
				{
					method: 'message/message',
					data: {
						type: 3
					}
				},
				{
					method: 'message/message',
					data: {
						type: 4
					}
				},
				{
					method: 'message/message',
					data: {
						type: 5,
						page: 1,
						num: 6
					}
				}
			],
			we: [
				{
					method: 'we/we',
					data: {
						type: 1
					}

				},
				{
					method: 'we/we',
					data: {
						type: 2,
						page: 1,
						num: 14
					}
				},
				{
					method: 'we/we',
					data: {
						type: 3,
						page: 1,
						num: 14
					}
				}
			]
    }
  },
  getters: {
    configInfo: state => state.configInfo,
  },
  mutations: {
    isMobile(state) {
      this.flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (this.flag === null) {
        state.isMobile = false;
      } else {
        state.isMobile = true;
      }
    },
    isMedia(state) {
      if (!window.matchMedia) {
        state.isMedia = window.innerWidth < 980;
      } else {
        const media = window.matchMedia('(max-width: 980px)');
        state.isMedia = media.matches;
      }
    },
    setConfigInfo(state, payload) {
      state.configInfo = payload || {};
    },
    setIndexInfo(state, payload) {
      state.indexInfo = payload || {}
    },
    setIsPromise(state, payload) {
      state.isPromise = payload;
    },
    setIsGuide(state, payload) {
      state.isGuide = payload;
    },
    setIsNavShow(state, payload) {
      state.isNavShow = payload;
    },
    initWoW() {
      WowEntry.animationStart();
    }
  },
  actions: {
   	config({dispatch, commit, state}, route) {
			return new Promise((resolve, reject) => {
				Vue.prototype.$api.base.initConfig()
					.then((res) => {
						if (res.code !== 1) return;
						const {data} = res;
						commit('setConfigInfo', data);
						if (data.nav) {
							commit('news/setNav', data.nav.find(v => v.title === '新闻中心'));
							commit('profile/setNav', data.nav.find(v => v.title === '企业概况'));
							commit('product/setNav', data.nav.find(v => v.title === '公司产品'));
							commit('exploit/setNav', data.nav.find(v => v.title === '技术开发'));
							commit('message/setNav', data.nav.find(v => v.title === '信息公示'));
							commit('we/setNav', data.nav.find(v => v.title === '加入我们'));
						}
						const tempApiUse = Vue.prototype.$utils.deepClone(state.apiUse);
						//查询是哪个页面需要优先调接口
						for (let key in tempApiUse) {
							const path = route.path.replace('/', '');
              if (path === 'search') {
                //调用剩下的接口
                dispatch('useAllApi', tempApiUse).then(() => {
                  commit('setIsPromise', true);
                  if (!state.isMobile) {
                    commit('initWoW');
                  }
                  resolve();
                });
                break;
              }
							if (path.indexOf(key) !== -1) {
								const tasks = tempApiUse[key].map(v => {
									return dispatch(v.method, v.data);
								});
								delete tempApiUse[key];
								//调用接口
								Promise.all(tasks).then(() => {
                  commit('setIsPromise', true);
                  if (!state.isMobile) {
                    commit('initWoW');
                  }
									//调用剩下的接口
									dispatch('useAllApi', tempApiUse).then(() => {
										resolve();
									});
								});
								//找到之后直接跳出循环
								break;
							}
						}
					});
			});
    },
		useAllApi({dispatch}, useApi) {
			return new Promise((resolve, reject) => {
				const tasks = [];
				const keys = Object.keys(useApi);
				for (let i = 0; i < keys.length; i++) {
					const key = keys[i];
					for (let a = 0; a < useApi[key].length; a++) {
						tasks.push(dispatch(useApi[key][a].method, useApi[key][a].data));
					}
				}
				Promise.all(tasks).then(() => {
					resolve();
				});
			});
		},
    index({commit, state}) {
      //首页接口
      return new Promise(async (resolve, reject) => {
        const res = await Vue.prototype.$api.base.index();
        if (res.code !== 1) return reject();
        for (let key in res.data) {
          if (key === 'image') continue;
          switch (key) {
            case 'banner':
              //封装banner
              res.data[key] = res.data[key].map(banner => new Banner(banner));
              break;
            case 'nav':
              //封装nav
              res.data[key] = res.data[key].map(nav => new Anchor(nav));
              break;
            case 'about':
              //封装nav
              res.data[key] = new About(res.data[key], profileTypes.INDEX);
              break;
            case 'nav1':
              //封装nav1
              res.data[key] = res.data[key].map(nav1 => new Research(nav1));
              break;
            case 'product':
              //封装product
              res.data[key] = res.data[key].map(product => new Product(product));
              break;
            case 'promotion':
              //封装promotion
              res.data[key] = res.data[key].map(overview => new Overview(overview));
              break;
            case 'new_enterprise':
              //封装新闻
              const newsEnterprise = res.data[key].map(news => new News(news));
              const newsIndustry = res.data['new_industry'].map(news => new News(news));
              res.data['news'] = [
                new NewsNav(state.news.nav.nav[0], newsEnterprise),
                new NewsNav(state.news.nav.nav[1], newsIndustry)
              ];
              delete res.data[key]
              delete res.data['new_industry']
              break;
          }
        }
        commit('setIndexInfo', new IndexData(res.data));
        resolve();
      });
    }
  },
  modules: {
    news,
    profile,
    product,
    exploit,
    message,
    we
  }
});
