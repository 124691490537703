export default {
  data() {
    return {
      navIndex: 0,
      timer: null
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.navIndex = +value.query.p_index || 0;
        if(this.navIndex == 2) {
          this.$nextTick(() => {
            const html = document.getElementsByTagName('html')[0];
            const fontSize = + html.style.fontSize.replace('px', '');
            const scroll = this.$refs.mapInfo.$el.offsetTop - 1.94 * fontSize;
            window.scrollTo({
              top: scroll,
              behavior: "smooth"
            });
          });
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    cTitles() {
      return ['加入我们', this.navList[this.navIndex]?.title];
    },
    navList() {
      return this.$store.state.we.nav.nav || [];
    },
    cNavInfo() {
      return this.$store.state.we.nav || {};
    },
    cSubNavTitle() {
      return this.navList[0] || {};
      // return this.navList[this.navIndex] || {};
    }
  },
  methods: {
    retNav(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        if (this.$route.path === '/we_info') {
          this.$router.push({
            path: '/we',
            query: {
              p_index: this.$route.query.p_index
            }
          });
          this.$nextTick(() => {
            this.$store.commit('initWoW');
          });
        }
      }, 300);
    }
  }
}
