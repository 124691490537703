<template>
  <div class="content-info" :style="{backgroundColor: bgColor}">
    <template v-if="cType([1, 3])">
      <div class="info-wrap one">
        <div class="text-wrap">
          <t-title-info
            :label="item.e_title"
            :title="item.title"
            :signRight="false"
            type="2">
          </t-title-info>
          <div class="text wow fadeInUp" data-wow-delay="0.3s">
            {{ item.des }}
          </div>
          <div class="btn-wrap wow fadeInUp" data-wow-delay="0.5s">
            <template v-if="cType([3])">
                <div class="btn btn-border" @click="router">
                  产品详情
                  <div class="more-icon-wrap">
                    <img src="https://img.jozobio.com/img/exploit/icon_08.png" alt="" class="more-icon">
                    <img class="more-icon active" src="https://img.jozobio.com/img/product/product_more_icon.png" />
                  </div>
                </div>
            </template>
            <template v-else>
              <div class="btn bg-color" @click="router">
                产品详情
                <div class="more-icon-wrap">
                  <img src="https://img.jozobio.com/img/exploit/icon_07.png" alt="" class="more-icon">
                  <img class="more-icon active" src="https://img.jozobio.com/img/product/product_more_icon.png" />
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="img-wrap wow fadeIn" data-wow-delay="0.3s">
          <img :src="item.image" alt="" class="img">
        </div>
      </div>
    </template>
    <template v-else-if="cType([2])">
      <div class="info-wrap two">
        <div class="img-wrap wow fadeIn" data-wow-delay="0.3s">
          <img :src="item.image" alt="" class="img">
        </div>
        <div class="text-wrap">
          <t-title-info
            :label="item.e_title"
            :title="item.title"
            :signRight="false"
            type="2"
            alignItems="flex-end">
          </t-title-info>
          <div class="text wow fadeInUp" data-wow-delay="0.3s">
            {{ item.des }}
          </div>
          <div class="btn-wrap wow fadeInUp" data-wow-delay="0.5s">
            <div class="btn btn-border" @click="router">
              产品详情
              <div class="more-icon-wrap">
                <img src="https://img.jozobio.com/img/exploit/icon_08.png" alt="" class="more-icon">
                <img class="more-icon active" src="https://img.jozobio.com/img/product/product_more_icon.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      default() {
        return ''
      }
    },
    type: {
      type: [Number, String],
      default() {
        return 1
      }
    },
    item: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    };
  },
  computed: {
    cType() {
      return function(arr) {
        return arr.includes(Number(this.type));
      }
    }
  },
  methods: {
    router() {
      this.$emit('router');
    }
  }
};
</script>

<style lang="less" scoped>
  .content-info {
    padding: .8rem 2.2rem;
    &:hover {
      .img-wrap {
        transform: scale(1.1);
      }
    }
    >.info-wrap {
      display: flex;
      align-items: center;
      >.text-wrap {
        flex: 1;
        >.text {
          margin-top: .4rem;
          font-size: .14rem;
          line-height: .3rem;
          color: #666;
        }
        >.btn-wrap {
          margin-top: .3rem;
          >.btn {
            padding: .1rem .3rem;
            color: #fff;
            border-radius: .2rem .2rem 0 .2rem;
            display: inline-flex;
            align-items: center;
            transition: .3s ease-out;
            >.more-icon-wrap {
              position: relative;
              >.more-icon {
                width: .25rem;
                transition: .4s ease-out;
                &.active {
                  position: absolute;
                  top: 50%;
                  left: 0;
                  transform: translateY(-50%);
                  opacity: 0;
                }
              }
            }
            &:hover {
              background: linear-gradient(to right, #0079eb, #00c2bb);
              color: #fff;
              >.more-icon-wrap {
                >.more-icon {
                  opacity: 0;
                  &.active {
                    opacity: 1;
                  }
                }
              }
            }
          }
          >.bg-color {
            background-image: linear-gradient(to right, #0079eb, #00c2bb);
          }
          >.btn-border {
            color: #bfbfbf;
            border: 1px solid #bfbfbf;
          }
        }
      }
      >.img-wrap {
        transition: .3s ease-out;
        >.img {
          width: 4rem;
        }
      }
    }
    >.one {
      >.img-wrap {
        margin-left: .8rem;
      }
    }
    >.two {
      >.text-wrap {
        margin-left: .8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
</style>
