export default {
  data() {
    return {
      navIndex: 0,
      timer: null
    };
  },
  created() {

  },
  methods: {
    retNav(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        if (this.$route.path === '/message_details') {
          this.$router.push({
            path: '/message',
            query: {
              p_index: this.$route.query.p_index || 0
            }
          });
        }
      }, 300);
    }
  },
  watch: {
    $route: {
      handler(value) {
        this.navIndex = +value.query.p_index || 0
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    cTitles() {
      return ['信息公示', this.navList[this.navIndex]?.title];
    },
    navList() {
      return this.$store.state.message.nav.nav || [];
    },
    cNavInfo() {
      return this.$store.state.message.nav || {};
    },
    cSubNavTitle() {
      return this.navList[this.navIndex] || {};
    }
  },
}
