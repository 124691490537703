<template>
  <div class="news-list">
    <template v-if="newsList.length < 1">
      <div class="empty">
        <div class="img-wrap">
          <img class="img" src="https://img.jozobio.com/img/common/common_empty_img.png" alt="暂无数据"/>
        </div>
        <div class="text">无搜索结果！</div>
      </div>
    </template>
    <template v-else>
      <div
          class="news-item wow fadeInUp"
          :data-wow-delay="`${0.3 * (index * 1)}s`"
          v-for="(item, index) in newsList"
          :key="item.id"
          @click="item.routerDetails($route.query.p_index || 0)">
        <div class="news-img-wrap">
          <img class="news-img" :src="item.image" :alt="item.title"/>
        </div>
        <div class="info-wrap">
          <h2 class="news-title">{{ item.title }}</h2>
          <div class="desc-wrap">
            <p class="desc">{{ item.des }}</p>
          </div>
          <div class="date-wrap">
            <time class="date">{{ $utils.setTime(item.create_time, 'Y-M-D') }}</time>
            <div class="details-btn">
              <div class="text nowrap">查看详情</div>
              <div class="more-icon-wrap">
                <img class="more-icon" src="https://img.jozobio.com/img/news/news_details_more_icon.png" alt="查看详情"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    newsList: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style scoped lang="less">
.news-list {
  margin-top: .5rem;
}
.news-item {
  display: flex;
  align-items: center;
  padding: .24rem 0;
  margin-bottom: .2rem;
  transition: .2s ease-in;
  cursor: pointer;
  &:hover {
    padding: .24rem .19rem;
    box-shadow: .01rem -.01rem .08rem .03rem #E4E9EF;
    .news-title {
      background-image: linear-gradient(90deg, #0266C6 40%, #00AAA1 60%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .news-img {
      transform: scale(1.2);
    }
  }
}
.news-img-wrap {
  overflow: hidden;
}
.news-img {
  width: 4.4rem;
  height: 2.26rem;
  transition: .3s ease-out;
}
.info-wrap {
  flex: 1;
  padding-left: .4rem;
}
.news-title {
  width: fit-content;
  font-size: .24rem;
  color: #3E3E3E;
  transition: .2s ease-in-out;
}
.desc-wrap {
  margin: .3rem 0 .4rem;
}
.desc {
  color: #969696;
  line-height: .3rem;
}
.date-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #969696;
}
.details-btn {
  display: flex;
  align-items: center;
  transition: all .5s ease-in;
  cursor: pointer;
  &.details-btn:hover {
    color: #00A7CD;
  }
}
.text {
  font-size: .17rem;
}
.more-icon-wrap {
  margin-left: .07rem;
}
.more-icon {
  width: .26rem;
  margin-bottom: .08rem;
}
.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 3rem;
  .text {
    font-size: .16rem;
  }
}
</style>
