import Vue from "vue";
import {
	News, NewsNav
} from "@/views/news/target/news";
//新闻模块
export default {
	namespaced: true,
  state: {
		nav: {},
		newsInfo: {
			hot_news: [],
			enterprise: [],
			dynamic: [],
			total: 0
		},
		newsDetails: {}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setNewsInfo(state, payload) {
			state.newsInfo[payload.key] = payload.data || payload.default;
		}
	},
	actions: {
		news({commit, state}, params) {
			return new Promise(async (resolve, reject) => {
				const res = await Vue.prototype.$api.news.news(params);
				if (res.code !== 1) return reject();
				let key = '';
				if (+params.type === 0) {
					key = 'enterprise'
				}
				if (+params.type === 1) {
					key = 'dynamic'
				}
				res.data.list = res.data.list.map(v => new News(v));
				res.data.hot_news = res.data.hot_news.map(v => new News(v));
				commit('setNewsInfo', {key, data: res.data.list, default: {}});
				commit('setNewsInfo', {key: 'hot_news', data: res.data.hot_news, default: []});
				commit('setNewsInfo', {key: 'total', data: res.data.total, default: 0});
				resolve();
			});
		}
	}
}
