<template>
  <div class="join-info wow fadeInUp">
    <div class="join-img-wrap wow fadeIn" data-wow-delay="0.7s">
      <img class="join-img" :src="cJoin.bg_image"/>
    </div>
    <section class="join-main-info">
      <div class="logo-wrap wow fadeInUp" data-wow-delay="0.3s">
        <img class="logo" :src="cJoin.image" alt="logo"/>
      </div>
      <h2 class="title wow fadeInUp" data-wow-delay="0.4s">{{ cJoin.title }}</h2>
      <div class="desc-wrap wow fadeInUp" data-wow-delay="0.5s">
        <p class="desc">{{ cJoin.des }}</p>
      </div>
      <div class="more-btn btn wow fadeInUp" data-wow-delay="0.6s" @click="routerWe">
        <div class="text">探索更多</div>
        <div class="icon-wrap">
          <img class="icon" src="https://img.jozobio.com/img/home/home_product_more.png" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    cJoin() {
      return this.$store.state.indexInfo.data?.data5 || {};
    }
  },
  methods: {
    routerWe() {
      this.$router.push({
        path: '/we',
        query: {
          p_index: 1
        }
      });
      window.scroll(0, 0);
    }
  }
}
</script>

<style scoped lang="less">
.join-info {
  position: relative;
  padding: 1rem var(--wrapper);
  background: radial-gradient(circle, #AACEDB, #F3F3F3);
  .join-img-wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    .join-img {
      width: 100%
    }
  }
  .join-main-info {
    position: relative;
    z-index: 1;
    .logo-wrap {
      .logo {
        width: 1.98rem;
      }
    }
    .title {
      margin: .7rem 0 .3rem;
      font-size: .32rem;
      color: #0089FF;
    }
    .desc-wrap {
      width: 7.35rem;
      margin-bottom: .3rem;
      .desc {
        color: #000000;
        line-height: .3rem;
        opacity: 0.8;
      }
    }
    .more-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.6rem;
      height: .47rem;
      background: linear-gradient(90deg, #0078EC, #00C4BA);
      border-radius: .23rem;
      .text {
        font-size: .18rem;
        color: #FFFFFF;
        line-height: .47rem;
      }
      .icon-wrap {
        margin-left: .1rem;
        margin-bottom: .03rem;
        .icon {
          width: .18rem;
        }
      }
    }
  }
}
</style>
