import http from './http'

/**
 * 技术开发接口
 */

export default {
  exploit(params) {
    return http.get('/index/technological', {
      params
    });
  }
}
