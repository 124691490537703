<template>
  <div class="map-info">
    <t-title-info
      label="Contact Us"
      title="联系我们"
      title-border=".01rem dashed var(--titleColor)"
    ></t-title-info>
    <div class="content-info">
      <div class="left wow fadeInLeft">
        <div class="item">
          <div class="left">
            <img src="@/assets/we/icon_02.png" alt="" class="img">
          </div>
          <div class="right">
            地址：<br/>
            {{configInfo.site}}
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="@/assets/we/icon_03.png" alt="" class="img">
          </div>
          <div class="right">
            电话：<br/>
            {{ configInfo.mobile }}
          </div>
        </div>
        <div class="item">
          <div class="left">
            <img src="@/assets/we/icon_04.png" alt="" class="img">
          </div>
          <div class="right">
            邮箱：<br/>
            {{configInfo.email}}
          </div>
        </div>
      </div>
      <div class="right wow fadeInRight" data-wow-delay="0.5s">
        <div class="map-container" id="mapContainer"></div> 
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      
    };
  },
  mounted() {
    var map = new BMapGL.Map("mapContainer");
    var point = new BMapGL.Point(102.53322,24.329506);
    map.centerAndZoom(point, 15); 

    // 创建自定义覆盖物DOM
    function createDOM(feature) {
        const img = document.createElement('img');
        img.style.width = '35px';
        img.src = 'https://img.jozobio.com/img/common/common_title_icon.png';
        img.draggable = false;
        return img;
    }
        
    // 创建自定义覆盖物
    const customOverlay = new BMapGL.CustomOverlay(createDOM, {
        point: new BMapGL.Point(102.53322,24.329506),
        opacity: 0.5,
        map: map,
        offsetY: -10,
        properties: {
            title: 'this is a title',
            size: '100'
        }
    });
      
    // 将自定义覆盖物添加到地图上
    map.addOverlay(customOverlay);
  },
  computed: {
    ...mapGetters([
      'configInfo'
    ]),
  }
};
</script>

<style lang="less" scoped>
  .map-info {
    padding: .6rem 1.8rem .8rem;
    border-top: solid .15rem #0078f0;
    @media screen and(max-width: 980px) {
      padding: .6rem .5rem;
    }
    >.content-info {
      margin-top: .5rem;
      display: flex;
      align-items: center;
      >.left {
        >.item {
          padding: .1rem 0;
          @media screen and(max-width: 980px) {
            padding: .2rem 0;
          }
          border-bottom: dotted 1px #3f68f5;
          display: flex;
          align-items: center;
          >.left {
            >.img {
              width: .6rem;
            }
          }
          >.right {
            margin-left: .15rem;
            @media screen and(max-width: 980px) {
              font-size: .3rem;
            }
          }
        }
      }
      >.right {
        flex: 1;
        height: 4rem;
        margin-left: .5rem;
        >.map-container {
          width: 100%;
          height: 100%;
        }
      }
      @media only screen and (max-width: 980px) {
        display: initial;
         >.right {
          margin-top: .3rem;
          margin-left: 0;
         }
      }
    }
  }
</style>