//技术开发模块
import Vue from "vue";
import {
	Exploit
} from "@/views/exploit/target/exploit";

export default {
	namespaced: true,
  state: {
		nav: {},
		exploitInfo: {
			research: [], //研究领域
			achievement: {},
			workbench: [],
			partner: [] //合作伙伴
		}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setExploitInfo(state, payload) {
			state.exploitInfo[payload.key] = payload.data;
		}
	},
	actions: {
		exploit({commit}, params) {
			return new Promise(async (resolve, reject) => {
				const res = await Vue.prototype.$api.exploit.exploit(params);
				if (res.code !== 1) return reject();
				let commitKey = '';
				if (+params.type === 1) {
					commitKey = 'research';
				}
				if (+params.type === 2) {
					commitKey = 'achievement';
				}
				if (+params.type === 3) {
					commitKey = 'workbench';
				}
				if (+params.type === 4) {
					commitKey = 'partner';
				}
				if (Array.isArray(res.data)) {
					res.data = res.data.map(v => new Exploit(v));
				} else {
					res.data = new Exploit(res.data);
				}
				commit('setExploitInfo', {
					key: commitKey,
					data: res.data
				});
				resolve();
			});
		}
	}
}
