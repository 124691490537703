import http from './http'

/**
 * 公司产品接口
 */

export default {
  product(params) {//首页接口
    return http.get('/index/product', {
      params
    });
  }
}
