<template>
  <div class="quality wow fadeIn" data-wow-delay="0.3s">
    <div v-html="cContent"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    cContent() {
      let str = '';
      if(this.cStandard.content) {
        str = this.cStandard.content.replace(/<img/g, '<img style="max-width: 100%;"');
      }
      return str;
    },
    cStandard() {
      return this.$store.state.message.messageInfo.standard || {};
    }
  },
};
</script>

<style lang="less" scoped>
  .quality {
    margin-top: .2rem;
    line-height: .35rem;
  }
</style>
