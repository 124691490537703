<template>
  <div class="details">
    <t-top-info
        :banner="cNavInfo.bg_image"
        :label="cNavInfo.english_title"
        :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
        :index="navIndex"
        :titles="cTitles"
        :listInfo="navList"
        list-key="title"
        @returnVal="retNav">
    </t-nav-list>
    <section class="details-container">
      <header-info></header-info>
      <product-info></product-info>
    </section>
  </div>
</template>

<script>
import TopBanner from "@/views/product/mixins/top-banner";
import HeaderInfo from "@/views/product/details/components/header-info.vue";
import ProductInfo from "@/views/product/details/components/product-info.vue";
export default {
  components: {
    HeaderInfo,
    ProductInfo
  },
  mixins: [TopBanner]
}
</script>

<style scoped lang="less">
.details-container {
  padding: 0 1.8rem;
}
</style>
