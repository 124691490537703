<template>
  <div class="text-info wow fadeIn" data-wow-delay="0.2s">
    <div class="line-wrap">
      <div class="dot-list">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <div class="text wow fadeInUp" data-wow-delay="0.4s">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .text-info {
    padding: 0 1.8rem;
    >.line-wrap {
      position: relative;
      width: 100%;
      height: .02rem;
      background-color: var(--titleColor);
      >.dot-list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        >.dot {
          width: .1rem;
          height: .1rem;
          background-color: var(--titleColor);
          border-radius: 50%;
        }
      }
    }
    >.text {
      margin-top: .25rem;
      line-height: .3rem;
      text-indent: 2em;
    }
  }
</style>
