<template>
  <div class="product-info">
    <div class="introduce-wrap">
      <div class="label-wrap wow fadeInLeft">
        <h2 class="label nowrap">产品介绍：</h2>
        <div class="line"></div>
      </div>
      <section class="introduce-info">
        <div
          class="info-item wow fadeInUp"
          :data-wow-delay="`${0.2 * (index * 1)}s`"
          v-for="(item, index) in product.json"
          :key="`json-${index}`"
        >
          <h3 class="title">{{ item.name }}</h3>
          <div class="content">
            <p class="text-wrap" style="color: #323232;line-height: .36rem;white-space: pre-wrap">{{ item.text }}</p>
          </div>
        </div>
      </section>
    </div>
    <div class="coupe-list-wrap">
      <div
        class="coupe-item wow fadeInUp"
        v-for="(gItem, gIndex) in product.g_json"
        :key="`${gItem.name}-${gIndex}`"
      >
        <div class="label-wrap wow fadeInLeft" data-wow-delay="0.3s">
          <h2 class="label nowrap">{{ gItem.name }}</h2>
          <div class="line"></div>
        </div>
        <div class="coupe-content wow fadeInUp" data-wow-delay="0.5s">
          <p class="text-wrap" style="color: #333333;line-height: .39rem;white-space: pre-wrap">{{ gItem.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      product: {}
    }
  },
  created() {
    this.product = JSON.parse(this.$route.query.item) || {};
    if (typeof this.product.json === 'string') {
      this.product.json = JSON.parse(this.product.json);
    }
    if (typeof this.product.g_json === 'string') {
      this.product.g_json = JSON.parse(this.product.g_json);
    }
  },
  computed: {
  },
}
</script>

<style scoped lang="less">
.product-info {
  margin-top: .4rem;
}
.label-wrap {
  display: flex;
  align-items: flex-end;
}
.label {
  width: fit-content;
  height: .53rem;
  padding: 0 .28rem;
  background: linear-gradient(to right, #0079EB 20%, #00C1BC 80%);
  border-radius: .3rem .3rem 0 .3rem;
  font-size: .2rem;
  font-weight: initial;
  color: #FFFFFF;
  line-height: .53rem;
}
.line {
  width: 100%;
  border-bottom: .01rem solid #9E9D9D;
}
.introduce-info {
  display: flex;
  flex-wrap: wrap;
  padding-left: .35rem;
  margin-bottom: .9rem;
}
.info-item {
  width: 50%;
  margin-top: .65rem;
  &:nth-child(odd) {
    .content {
      padding-right: 1.4rem;
    }
  }
}
.title {
  margin-bottom: .15rem;
  font-size: .24rem;
  color: #0264D2;
}
.content {
  padding-left: .1rem;
}
.coupe-content {
  margin: .2rem 0 2.4rem;
  padding-left: .35rem;
}
@media screen and(max-width: 980px) {
  .text-wrap {
    line-height: .6rem !important;
    font-size: .35rem;
  }
}
</style>
