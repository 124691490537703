import http from './http'

/**
 * 信息公示接口
 */

export default {
  message: (params) => {
    let method = 'get';
    let obj = {};
    const type = params.type;
    if (type === 6) {
      method = 'post'
      obj = params.data;
    }
    if (params.page && params.num) {
      obj.params = {
        page: params.page,
        num: params.num
      }
    }
    return http[method]('/index/information?type=' + type, obj);
  },
  getCode(params) { //获取验证码
    return http.get('/index/getCode', {
      params
    });
  }
}
