<template>
  <div class="exploit">
    <t-top-info
      :banner="cSubNavInfo.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
      @returnLoad="retLoad"
    ></t-top-info>
    <t-nav-list
      :index="navIndex"
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      @returnVal="retNav"
    >
    </t-nav-list>
    <template v-if="navIndex == 0">
      <territory></territory>
    </template>
    <template v-else-if="navIndex == 1">
      <achievement :nav-info="cSubNavInfo"></achievement>
    </template>
    <template v-else-if="navIndex == 2">
      <workstation :nav-info="cSubNavInfo"></workstation>
    </template>
    <template v-else-if="navIndex == 3">
      <partners :nav-info="cSubNavInfo" :loadImg="loadImg"></partners>
    </template>
  </div>
</template>

<script>
import Achievement from './achievement/Achievement.vue';
import Partners from './partners/Partners.vue';
import Territory from './territory/Territory.vue';
import Workstation from "@/views/exploit/workstation/Workstation.vue";
//mixins
import TopBanner from "@/views/exploit/mixins/top-banner";
export default {
  components: { Partners, Territory, Achievement, Workstation },
  name: 'Exploit',
  mixins: [TopBanner]
};
</script>

<style lang="less" scoped>

</style>
