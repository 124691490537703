<template>
  <div class="t-top-info">
    <div class="content-info">
      <img :src="banner" alt="" class="img" @load="loadImg">
      <div class="text-wrap">
        <!-- <div class="text" :style="{backgroundImage: `linear-gradient(to bottom, ${textColor})`}">
          {{label}}
        </div> -->
        <div class="text">
          {{$navList.english_title}}
        </div>
        <div class="title">{{$navList.title}}</div>
        <div class="border"></div>
      </div>
    </div>
    <div class="t-nav-list-top"></div>
  </div>
</template>

<script>
export default {
  props: {
    banner: {
      type: String,
      default() {
        return ''
      }
    },
    label: {
      type: String,
      default() {
        return '标签'
      }
    },
    title: {
      type: String,
      default() {
        return '标题'
      }
    },
    textColor: {
      type: String,
      default: () => '#9cc5df, #3a8cc1'
    }
  },
  data() {
    return {
      titleObj: {}
    }
  },
  computed: {
    $navList() {
      let arr = [];
      if(this.$store.state[this.$route.path.slice(1)] && this.$store.state[this.$route.path.slice(1)].nav.nav) {
        arr = this.$store.state[this.$route.path.slice(1)].nav.nav[this.$route.query.p_index ? this.$route.query.p_index : 0];
      }
      return arr;
    }
  },
  methods: {
    loadImg() {
      this.$emit('returnLoad');
    }
  },
};
</script>

<style lang="less" scoped>
  .t-top-info {
    >.content-info {
      display: flex;
      align-items: center;
      >.img {
        width: 100%;
      }
      >.text-wrap {
        position: absolute;
        left: 1.5rem;
        >.text {
          font-size: .5rem;
          font-family: "Source Han Sans-CN";
          font-weight: bold;
          color: #fff;
          // background-image: linear-gradient(to bottom, #9cc5df, #3a8cc1);
          // -webkit-background-clip: text;
          // color: transparent;
        }
        >.title {
          margin-top: -.08rem;
          font-size: .4rem;
          font-weight: 500;
          color: #fff;
        }
        >.border {
          margin-top: .1rem;
          width: .3rem;
          border-bottom: solid 3px #fff;
        }
      }
    }
  }
</style>
