<template>
  <div class="writing-info">
    <t-title-info
        label="Company Culture"
        title="企业文化"
        :type1-style="{
          marginLeft: 'var(--wrapper)'
        }"
        title-border=".01rem dashed var(--titleColor)"
    ></t-title-info>
    <div class="info-wrap">
      <div class="left wow fadeInLeft">
        <div class="top">
          <img class="icon" src="https://img.jozobio.com/img/common/common_header_icon.png" />
          <div class="title">{{ cInfo.sub_title }}</div>
        </div>
        <div class="bottom" v-html="cInfo.content">
        </div>
      </div>
      <div class="right wow fadeInRight">
        <img class="thumb" :src="cInfo.image" />
      </div>
    </div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in cIntroduce"
        :key="`writing-introduce-${index}`"
      >
        <div class="title-wrap">
          <img class="bg" :src="item.icon_image" />
          <div class="title">{{ item.title }}</div>
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="desc">
          <p>
            {{ item.sub_title }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    cInfo() {
      let obj = {};
      if(this.$store.state.profile.profileInfo && this.$store.state.profile.profileInfo.introduce) {
        obj = this.$store.state.profile.profileInfo.introduce[0];
      }
      return obj;
    },
    cIntroduce() {
      let arr = [];
      if(this.$store.state.profile.profileInfo && this.$store.state.profile.profileInfo.introduce) {
        arr = this.$store.state.profile.profileInfo.introduce.slice(1);
      }
      return arr;
    }
  }
}
</script>

<style scoped lang="less">
.writing-info {
  padding: .4rem 0;
  >.info-wrap {
    display: flex;
    margin-top: .5rem;
    padding: 0 var(--wrapper);
    >.left {
      flex: 1;
      padding-right: .3rem;
      >.top {
        display: flex;
        align-items: center;
        padding-bottom: .15rem;
        border-bottom: .01rem dashed rgba(199, 199, 199, 1);
        >.icon {
          margin-right: .15rem;
          width: .6rem;
        }
        >.title {
          font-size: .2rem;
        }
      }
      >.bottom {
        margin-top: .15rem;
        padding-right: .3rem;
      }
    }
    >.right {
      flex: 1;
      >.thumb {
        width: 100%;
      }
    }
  }
  >.list {
    display: flex;
    align-items: center;
    margin-top: .7rem;
    padding: 0 var(--wrapper);
    >.item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
      height: .8rem;
      padding: 0 .4rem 0 1.2rem;
      margin-right: .4rem;
      background: linear-gradient(to right, rgba(210, 219, 231, 1), rgba(231, 236, 242, 1));
      border-radius: .5rem;
      border: .01rem solid rgba(179, 193, 216, 1);
      >.title-wrap {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        >.bg {
          width: 1.1rem;
        }
        >.title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-70%, -50%);
          text-align: center;
          font-size: .18rem;
          color: #fff;
        }
      }
      >.title {
        font-size: .18rem;
        color: var(--titleColor);
      }
      >.desc {
        >p {
          font-size: .12rem;
          color: rgba(116, 119, 123, 1);
        }
      }
    }
  }
}
</style>
