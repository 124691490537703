import {
  UIData
} from "@/common/targer";
import Vue from "vue";
import store from "@/store";
export class Message extends UIData {
  constructor(target) {
    super(target);
  }


  //跳转详情
  routerDetails(p) {
    Vue.prototype._router.push({
      path: '/message_details',
      query: {
        p_index: p,
        item: JSON.stringify(this)
      }
    });
    window.scroll(0, 0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}
