<template>
  <div class="being-product">
    <c-title :en="'/' + navInfo.english_title" :cn="navInfo.title"></c-title>
    <section class="being-product-table">
      <div class="table-header">
        <div class="header-item fixed">
          <span class="text">产品名称</span>
        </div>
        <div class="header-item" id="header-w">
          <span class="text">临床前研究</span>
        </div>
        <div class="header-item">
          <span class="text">临床试验申请</span>
        </div>
        <div class="header-item">
          <span class="text">临床试验Ⅰ期</span>
        </div>
        <div class="header-item">
          <span class="text">临床试验Ⅱ期</span>
        </div>
        <div class="header-item">
          <span class="text">临床试验Ⅲ期</span>
        </div>
        <div class="header-item">
          <span class="text">上市许可申请</span>
        </div>
      </div>
      <div class="table-main">
        <div
          class="being-product-item"
          :id="index === cProducts.length - 1 ? 'itemEnd' : ''"
          v-for="(item, index) in cProducts"
          :key="item.id"
        >
          <h3 class="product-name">{{ item.title }}</h3>
          <div class="progress-wrap">
            <div class="progress" :style="cProgressW(+item.type)"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Title from "@/views/product/components/title.vue";
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'c-title': Title
  },
  data() {
    return {
      progressW: 0
    }
  },
  computed: {
    cProducts() {
      return this.$store.state.product.productInfo.beingProduct || [];
    },
    cProgressW() {
      return (num) => {
        if (num > this.progressW.length) return ;
        return `width: ${this.progressW * num}px`;
      }
    },
    isPromise() {
      return this.$store.state.isPromise;
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getProgressW();
    });
  },
  methods: {
    getProgressW() {
      this.progressW = 0;
      const headerItem = document.getElementById('header-w');
      if (!headerItem) return ;
      this.progressW = headerItem.offsetWidth;
    },
    listenerEnd() {
      this.endDom = document.getElementById('itemEnd');
      if (!this.endDom) return ;
      this.endDom.addEventListener('animationend', () => {
        this.getProgressW();
      });
    }
  }
}
</script>

<style scoped lang="less">
.being-product-table {
  padding: 0 calc(var(--wrapper) + .5rem);
  margin-bottom: 3rem;
}
.table-header {
  display: flex;
  align-items: center;
  background: linear-gradient(90deg, #0089FF, #00C2BC);
  border-bottom: .01rem solid rgba(229, 229, 229, .7);
  border-radius: .1rem .1rem 0 0;
}
.header-item {
  display: list-item;
  flex: 1;
  border-right: .01rem solid rgba(229, 229, 229, .7);
  list-style: none;
  text-align: center;
  line-height: .8rem;
  &:last-child {
    border-right: none;
  }
  &.fixed {
    flex: none;
    width: 3.5rem;
  }
}
.text {
  font-size: .18rem;
  color: #FFFEFE;
}
.being-product-item {
  display: flex;
  height: 1rem;
  &:last-child {
    .product-name {
      border-radius: 0 0 0 .08rem;
    }
    .progress-wrap {
      border-radius: 0 0 .08rem 0;
    }
  }
}
.product-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  width: 3.5rem;
  height: inherit;
  padding: .1rem;
  background-color: #F1F1F1;
  border-left: .01rem solid #E5E5E5;
  border-right: .01rem solid #E5E5E5;
  border-bottom: .01rem solid #E5E5E5;
  font-weight: initial;
}
.progress-wrap {
  display: flex;
  align-items: center;
  flex: 1;
  height: inherit;
  border-right: .01rem solid #E5E5E5;
  border-bottom: .01rem solid #E5E5E5;
  width: 8.37rem;
}
.progress {
  width: 0px;
  height: .16rem;
  background: linear-gradient(90deg, #0089FF 20%, #00C2BC 80%);
  border-radius: 0 .08rem .08rem 0;
  transition: .5s ease-out;
}
</style>
