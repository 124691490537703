<template>
	<div class="hos-news">
		<header class="header">
			<div class="header-icon-wrap">
				<img class="header-icon" src="https://img.jozobio.com/img/common/icon_label.png" alt="热门新闻"/>
			</div>
      <h2 class="hot-label">热门新闻</h2>
		</header>
    <section class="news-swiper-container">
      <div class="swiper-wrapper">
        <div class="swiper-slide wow fadeInLeft"
             :data-wow-delay="`${0.3 * (index * 1)}s`"
             v-for="(item, index) in hotNews"
             :key="`hot-news-${index}`"
             @click="item.routerDetails($route.query.p_index || 0)"
        >
          <div class="news-item">
            <div class="img-wrap">
              <img class="img" :src="item.image" alt="新闻图片"/>
            </div>
            <div class="title-wrap">
              <h3 class="news-title more-ellipsis clamp-1">{{ item.title }}</h3>
              <div class="more-icon-wrap">
                <img class="more-icon" src="https://img.jozobio.com/img/news/news_details_host_more.png" alt="查看更多"/>
              </div>
            </div>
            <div class="desc-wrap">
              <p class="desc more-ellipsis clamp-2">{{ item.des }}</p>
            </div>
            <div class="bottom-line"></div>
          </div>
        </div>
      </div>
    </section>
	</div>
</template>

<script>
import Swiper from "swiper";
export default {
  props: {
    hotNews: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiper: null
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper(){
      let viewNum = 3;
      if (this.$store.state.isMedia) {
        viewNum = 1;
      }
      this.swiper = new Swiper('.news-swiper-container', {
        slidesPerView: viewNum,
        observer: true,
        observeParents: true
      });
    }
  }
}
</script>

<style scoped lang="less">
.hos-news {
  margin: .6rem 0 1.8rem;
  padding: 0 1.8rem;
}
.header {
  display: flex;
  align-items: center;
}
.header-icon-wrap {
  margin-right: .05rem;
}
.header-icon {
  width: .07rem;
  margin-bottom: .01rem;
}
.hot-label {
  font-size: .18rem;
  font-family: Microsoft YaHei;
  color: #000000;
}
.news-swiper-container {
  margin-top: .2rem;
  overflow-x: hidden;
}
.news-list {
  display: flex;
  justify-content: space-between;
}
.news-item {
  position: relative;
  padding-bottom: .3rem;
  width: 4.4rem;
  border-bottom: .01rem solid #E6E6E6;
  cursor: pointer;
  &:hover {
    >.bottom-line {
      width: 100%;
    }
  }
  &:nth-child(3n) {
    margin-right: 0;
  }
}
.img {
  width: 100%;
}
.title-wrap {
  display: flex;
  align-items: center;
  margin: .15rem 0 .2rem;
}
.news-title {
  flex: 1;
  font-size: .18rem;
  font-weight: initial;
}
.more-icon {
  width: .15rem;
}
.desc {
  font-size: .14rem;
  color: #969696;
}
.bottom-line {
  position: absolute;
  left: 0;
  bottom: 0;
  height: .02rem;
  width: 0;
  transition: .3s ease-in-out;
  background: linear-gradient(90deg, #0078EC 20%, #00C4BA 80%);;
}
</style>
