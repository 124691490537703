import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import base from './base'
import yzd from './yzd'
import lzy from './lzy'
const routes = [
  ...base,
  ...yzd,
  ...lzy
]

const router = new VueRouter({
  routes
})

export default router
