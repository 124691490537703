<template>
  <div class="about-info">
    <div class="content-info">
      <div class="left wow fadeIn" data-wow-delay="0.3s">
        <t-title-info
          label="About Us"
          :title="cAbout.title"
          title-border=".01rem dashed var(--titleColor)"
        >
        </t-title-info>
        <div class="text-wrap">
          <div class="text">
            <p>{{ cAbout.sub_title }}</p>
          </div>
          <div class="more-wrap" @click="cAbout.routerDetails($route.query.p_index || 0)">
            <img class="img" src="https://img.jozobio.com/img/common/common_header_icon.png" />
            查看更多+
          </div>
        </div>
        <div class="list-wrap">
          <div
              class="item wow fadeInUp"
              data-wow-delay="0.4s"
              v-for="(item, index) in (cAbout.imgs ? cAbout.imgs.split(',') : [])"
              :key="`profile-${index}`"
          >
            <img :src="item" alt="" class="img">
          </div>
        </div>
      </div>
      <div class="right wow fadeIn" data-wow-delay="0.3s">
      </div>
    </div>
    <div class="bg-wrap">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball1.png" alt="" class="img">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball2.png" alt="" class="img">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball3.png" alt="" class="img">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball4.png" alt="" class="img">
      <div class="img" :style="{backgroundImage: `url(${cAbout.image})`}"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    };
  },
  computed: {
    cAbout() {
      return this.$store.state.profile.profileInfo.profile || {};
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .about-info {
    position: relative;
    padding: 1rem var(--wrapper) .8rem;
    >.content-info {
      margin-top: .2rem;
      display: flex;
      >.left {
        flex: 2;
        padding-right: .3rem;
        >.text-wrap {
          margin-top: .3rem;
          padding-bottom: .25rem;
          border-bottom: .01rem dashed rgba(47, 137, 254, 1);
          >.text {
            margin-bottom: .2rem;
            color: #666;
            line-height: .4rem;
            >p {
              font-size: .18rem;
            }
          }
          >.more-wrap {
            color: #666;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
              position: relative;
              top: 1px;
            }
            >.img {
              margin-right: .15rem;
              width: .6rem;
            }
          }
        }
        >.list-wrap {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-top: .25rem;
          >.item {
            width: 3.1rem;
            border-radius: .25rem 0 .25rem 0;
            >.img {
              width: 100%;
              height: 1.75rem;
            }
          }
        }
      }
      >.right {
        flex: 1;
        margin: 0 .5rem 0 1.3rem;
      }
    }
    >.bg-wrap {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      overflow: hidden;
      >.img {
        position: absolute;
        width: 2rem;
        &:nth-child(1) {
          bottom: 1.05rem;
          right: 4.3rem;
          width: 3.5rem;
        }
        &:nth-child(2) {
          right: 0;
          bottom: 0;
          width: 3.5rem;
        }
        &:nth-child(3) {
          bottom: .8rem;
          left: .3rem;
          width: 1.5rem;
        }
        &:nth-child(4) {
          top: 0;
          right: 0;
          width: 6.8rem;
        }
        &:last-child {
          position: absolute;
          right: -.53rem;
          top: -3.1rem;
          width: 6.8rem;
          height: 6.8rem;
          background-size: 150% 100%;
          background-position: -2rem 1rem;
          background-repeat: no-repeat;
          border-radius: 50%;
        }
      }
    }
  }
</style>
