var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"t-title-info",style:({margin: _vm.margin})},[_c('div',{staticClass:"content-info"},[_c('div',{staticClass:"text-info wow fadeInUp",style:({alignItems: _vm.alignItems})},[(_vm.type == 2)?[_c('div',{staticClass:"title-wrap",style:({
              color: _vm.titleColor
            })},[(_vm.iconShow)?[_c('img',{staticClass:"img",attrs:{"src":"https://img.jozobio.com/img/common/icon_title_sign.png","alt":""}})]:_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],2),_c('div',{staticClass:"label-wrap"},[_c('div',{staticClass:"label",style:([_vm.cLabelColor])},[_vm._v(" "+_vm._s(_vm.label)+" ")])])]:_vm._e(),(_vm.type == 1)?[_c('div',{staticClass:"type-1",style:({
              '--titleColor': _vm.titleColor || 'rgba(63, 104, 245, 1)',
              borderBottom: _vm.titleBorder,
              ..._vm.type1Style
            })},[_c('div',{staticClass:"title-wrap",style:({
              color: _vm.titleColor
            })},[(_vm.iconShow)?void 0:_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")],2),_c('div',{staticClass:"split"},[_vm._v("/")]),_c('div',{staticClass:"label-wrap"},[_c('div',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.label)+" ")])]),(!_vm.titleColor)?[_c('img',{staticClass:"title-icon",attrs:{"src":"https://img.jozobio.com/img/common/common_title_icon.png"}})]:[_c('img',{staticClass:"title-icon",attrs:{"src":"https://img.jozobio.com/img/common/common_title_icon_w.png"}})]],2)]:_vm._e()],2),(_vm.listInfo.length > 0)?[_c('div',{staticClass:"nav-list"},_vm._l((_vm.listInfo),function(item,itemIndex){return _c('div',{key:itemIndex,staticClass:"btn wow fadeInRight",class:{active: itemIndex == _vm.index},attrs:{"data-wow-delay":`${0.4 * (_vm.index * 1)}s`},on:{"click":function($event){return _vm.handleItem(itemIndex)}}},[_vm._v(_vm._s(item[_vm.listKey]))])}),0)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }