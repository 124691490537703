<template>
  <div class="explain-info">
    <div class="list-wrap">
      <div class="item wow fadeInUp" :data-wow-delay="`${0.3 * (index * 1)}s`" @click="item.routerDetails($route.query.p_index || 0)" v-for="(item, index) in cSpecs" :key="index">
        <div class="wrap">
          <div class="img-wrap">
            <img :src="item.image" alt="" class="img">
          </div>
        </div>
        <div class="name">{{item.title}}</div>
      </div>
    </div>
    <div class="pagination-wrap wow fadeIn" data-wow-delay="0.6s">
      <el-pagination
        background
        :page-size="pageInfo.num"
        layout="prev, pager, next"
        @prev-click="pageCurrent"
        @next-click="pageCurrent"
        @current-change="pageCurrent"
        :total="cSpecs.length">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import Paging from "@/views/message/mixins/paging";
export default {
  props: {
    navIndex: {
      type: Number,
      default: () => 0
    }
  },
  mixins: [Paging],
  data() {
    return {
      pageInfo: {
        num: 6
      }
    };
  },
  computed: {
    cSpecs() {
      return this.$store.state.message.messageInfo.specs || [];
    }
  },
  mounted() {

  },

  methods: {
  },
};
</script>

<style lang="less" scoped>
  .explain-info {
    >.list-wrap {
      margin-top: -.05rem;
      margin-left: -.2rem;
      display: flex;
      flex-wrap: wrap;
      >.item {
        margin-left: .2rem;
        margin-top: .5rem;
        width: calc(100% / 3 - .2rem);
        cursor: pointer;
        >.wrap {
          padding: .01rem;
          >.img-wrap {
            padding: .2rem;
            border: 1px solid rgba(0, 0, 0, .1);
            display: flex;
            justify-content: center;
            background-color: #fff;
            >.img {
              height: 2.3rem;
              transition: .2s;
            }
          }
        }
        >.name {
          margin-top: .15rem;
          text-align: center;
          font-weight: bold;

        }
        &:hover {
          >.wrap {
            background: linear-gradient(90deg, #0078EC, #00C4BA);
            >.img-wrap {
              >.img {
                transform: scale(1.1);
              }
            }
          }
          >.name {
            background-image: linear-gradient(90deg, #0078EC 40%, #00C4BA 60%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
</style>
