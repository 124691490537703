<template>
  <div class="detail">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
      :index="navIndex"
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      @returnVal="retNav"
    ></t-nav-list>
    <div class="detail-container">
      <top-info></top-info>
      <recruit-request></recruit-request>
      <ball-list></ball-list>
    </div>
  </div>
</template>

<script>
import TopInfo from "@/views/we/recruitDetail/childComps/TopInfo.vue";
import RecruitRequest from "@/views/we/recruitDetail/childComps/recruit-request.vue";
import BallList from "@/views/we/recruitDetail/childComps/ball-list.vue";
import TopBanner from "@/views/we/mixins/top-banner";
export default {
  components: {
    TopInfo,
    RecruitRequest,
    BallList
  },
  mixins: [TopBanner]
};
</script>

<style lang="less" scoped>
.detail-container {
  position: relative;
  overflow: hidden;
}
</style>
