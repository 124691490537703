import Vue from "vue";
import {
	About,
	Base,
	Course,
	Honor,
	HonorNav, Media
} from "@/views/profile/target/profile";
//企业概况模块
export default {
	namespaced: true,
  state: {
		nav: {},
		profileInfo: {}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setProfileInfo(state, payload) {
			state.profileInfo = payload || {};
		}
	},
	actions: {
		profile({commit})  {
			return new Promise(async (resolve, reject) => {
				const res = await Vue.prototype.$api.profile.profile();
				if (res.code !== 1) return reject();
				res.data.profile = new About(res.data.profile);
				//发展历程
				res.data.develop = res.data.develop.map(v => new Course(v));
				//企业荣誉、企业资质
				res.data.qualifications = initHonorAndCerts(res.data.qualifications, res.data.qualifications2);
				//生产基地
				res.data.base = res.data.base.map(v => new Base(v));
				//媒体中心
				res.data.video = res.data.video.map(v => new Media(v));
				commit('setProfileInfo', res.data);
				resolve();
			});
		}
	}
}

function initHonorAndCerts(row, col) {
	const certification = {
		row: [],
		col: []
	}; //企业资质
	const honors = {
		row: [],
		col: []
	};
	row.forEach(v => {
		const honor = new Honor(v);
		if (+v.type === 1) {
			honors.row.push(honor);
		}
		if (+v.type === 0) {
			certification.row.push(honor);
		}
	});
	col.forEach(v => {
		const honor = new Honor(v);
		if (+v.type === 1) {
			honors.col.push(honor);
		}
		if (+v.type === 0) {
			certification.col.push(honor);
		}
	});
	return [
		new HonorNav({title: '企业资质', listTarget: certification}),
		new HonorNav({title: '企业荣誉', listTarget: honors})
	]
}
