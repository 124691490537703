/**
 * 接口统一入口
 */


import base from './base'
import product from "@/api/product";
import profile from "@/api/profile";
import message from './message'
import we from './we'
import exploit from "@/api/exploit";
import news from "@/api/news";
export default {
  base,
  product,
  profile,
  message,
  we,
  exploit,
  news
}
