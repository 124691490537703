<template>
  <div class="news-banner">
    <div class="banner-wrap">
      <template v-if="cNewsList.length > 0">
        <div class="news-swiper-container">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide wow fadeInLeft"
                :data-wow-delay="`${0.2 * (index * 1)}s`"
                v-for="(item, index) in cNewsList"
                :key="item.id"
            >
              <div class="img-wrap">
                <img class="img" :src="item.image" :alt="item.title"/>
              </div>
              <div class="info-wrap">
                <h2 class="news-title">{{ item.title }}</h2>
                <div class="desc-wrap">
                  <p class="desc">{{ item.des }}</p>
                </div>
                <div class="date-and-btn">
                  <time class="date">{{ $utils.setTime(item.create_time, 'Y-M-D') }}</time>
                  <div class="details-btn btn" @click="item.routerDetails($route.query.p_index || 0)">
                    <div class="text">查看详情</div>
                    <div class="dot"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-pagination wow fadeInUp" data-wow-delay="0.3s"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  props: {
    navIndex: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      swiper: null
    }
  },
  computed: {
    cNewsList() {
      return this.$store.state.news.newsInfo.hot_news || [];
    }
  },
  mounted() {
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      if(!document.querySelector('.news-swiper-container')) {
        requestAnimationFrame(this.initSwiper);
        return;
      }
      this.swiper = new Swiper('.news-swiper-container', {
        observer: true,
        observeParents: true,
        pagination: { //分页器
          el: '.swiper-pagination',
          clickable: true,
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.news-banner {
  padding: 0 var(--wrapper);
  margin-top: 1rem;
}
.news-swiper-container {
  position: relative;
  padding-bottom: .65rem;
  overflow-x: hidden;
  border-bottom: .01rem solid #E6E6E6;
}
.swiper-slide {
  display: flex;
  align-items: center;
  &:hover {
    >.img-wrap {
      >.img {
        transform: scale(1.2);
      }
    }
  }
}
.swiper-pagination {
  bottom: .35rem;
}
.img-wrap {
  overflow: hidden;
}
.img {
  width: 6.5rem;
  height: 3.36rem;
  transition: .3s ease-out;
}
.info-wrap {
  flex: 1;
  padding: 0 .6rem;
}
.news-title {
  font-size: .24rem;
  color: #090909;
}
.desc-wrap {
  margin: .3rem 0 1rem;
}
.desc {
  font-size: .18rem;
  color: #868686;
}
.date-and-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.date {
  color: #868686;
}
.details-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1.5rem;
  height: .48rem;
  background: linear-gradient(90deg, #0078EC 20%, #00C4BA 80%);
  border-radius: .29rem;
  cursor: pointer;
  &:hover {
    .dot {
      &:before {
        animation-name: translationB;
        animation-duration: 1s;
      }
      &:after {
        animation-name: translationA;
        animation-duration: 1s;
      }
    }
  }
}
.text {
  padding-left: .3rem;
  color: #FFFFFF;
}
.dot {
  position: relative;
  width: .39rem;
  height: .39rem;
  margin-right: .05rem;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
  &:before {
    display: block;
    position: absolute;
    top: 50%;
    left: -.3rem;
    transform: translateY(-50%) rotate(-45deg);
    content: '';
    width: .1rem;
    height: .1rem;
    border-right: .02rem solid #0E9B99;
    border-bottom: .02rem solid #0E9B99;
  }
  &:after {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    content: '';
    width: .1rem;
    height: .1rem;
    border-right: .02rem solid #0E9B99;
    border-bottom: .02rem solid #0E9B99;
  }
}
@keyframes translationB {
  100% {
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}
@keyframes translationA {
  100% {
    transform: translate(.3rem, -50%) rotate(-45deg);
  }
}
</style>
