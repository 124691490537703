<template>
	<div class="news-content">
		<div class="news-title-wrap">
			<h2 class="news-title wow fadeInUp">{{ newsDetails.data.title }}</h2>
			<div class="news-source-date">
				<div class="date-wrap wow fadeInUp" data-wow-delay="0.3s">
					<div class="date-icon-wrap">
						<img class="date-icon" src="https://img.jozobio.com/img/news/news_details_time_icon.png" alt="发布日期"/>
					</div>
					<div class="date">
						发布于：
						<time class="datetime">{{ $utils.setTime(newsDetails.data.create_time, 'Y-M-D') }}</time>
					</div>
				</div>
				<div class="split-line wow fadeInUp" data-wow-delay="0.4s"></div>
				<div class="source-wrap wow fadeInUp" data-wow-delay="0.5s">
					发布来源：
					<strong class="source">{{ newsDetails.data.source }}</strong>
				</div>
				<div class="view-wrap wow fadeInRight" data-wow-delay="0.6s">
					<div class="view-icon-wrap">
						<img class="view-icon" src="https://img.jozobio.com/img/news/news_details_view_icon.png" alt="浏览量"/>
					</div>
					<div class="view">
						浏览量：{{ newsDetails.data.num }}
					</div>
				</div>
			</div>
		</div>
		<section class="content content-text wow fadeInUp" data-wow-delay="0.3s" v-html="cContent(newsDetails.data.content)">

		</section>
		<div class="paging">
			<div class="page-btn prev wow fadeInLeft" data-wow-delay="0.4s" @click="page('previous')">
				<div class="page-icon-wrap">
					<img class="page-icon" src="https://img.jozobio.com/img/news/news_details_prev_icon.png" alt="上一条新闻"/>
				</div>
				<div class="page-title">
					上一篇：{{ newsDetails.previous ? newsDetails.previous.title : '已经到底啦~' }}
				</div>
			</div>
			<div class="page-btn next wow fadeInRight" data-wow-delay="0.4s" @click="page('next')">
				<div class="page-icon-wrap">
					<img class="page-icon" src="https://img.jozobio.com/img/news/news_details_next_icon.png" alt="下一条新闻"/>
				</div>
				<div class="page-title">
					下一篇：{{ newsDetails.next ? newsDetails.next.title : '已经到底啦~' }}
				</div>
			</div>
		</div>
		<div class="back-btn-wrap">
			<div class="back-btn btn wow fadeInUp" data-wow-delay="0.5s" @click="backNews">
				<div class="back-icon-wrap">
					<img class="back-icon" src="https://img.jozobio.com/img/news/news_details_back_icon.png" alt="返回列表"/>
				</div>
				<div class="btn-text">返回列表页</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    newsDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      id: 0
    }
  },
  created() {

  },
  computed: {
	cContent() {
		return function(value) {
			let str = '';
			if(value) {
				str = value.replace(/<img/g, '<img style="width:100%"')
			}
			return str;
		}
	}
  },
  methods: {
    page(type) {
      if (!this.newsDetails[type]) return ;
      this.$router.replace({
        path: `/news_details/${this.newsDetails[type].id}`
      });
      this.$router.go(0);
    },
    backNews() {
      this.$router.push({
        path: '/news',
        query: {
          p_index: this.$route.query.p_index
        }
      });
      window.scroll(0, 0);
      this.$nextTick(() => {
        this.$store.commit('initWoW');
      });
    }
  }
}
</script>

<style scoped lang="less">
.news-content {
	padding: 0 1.8rem;
	/deep/ .content-text * {
		font-size: .5rem !important;
	}
}
.news-title-wrap {
	margin-top: .7rem;
	border-bottom: .01rem solid #E6E6E6;
}
.news-title {
	text-align: center;
	font-size: .28rem;
	font-weight: 500;
	color: #090909;
}
.news-source-date {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: .25rem 0 .15rem;
	color: #838282;
}
.date-wrap {
	display: flex;
	align-items: center;
}
.date-icon-wrap {
	margin-right: .05rem;
}
.date-icon {
	width: .17rem;
	margin-bottom: .03rem;
}
.date,
.datetime,
.source-wrap,
.source,
.view {
	font-size: .14rem;
	font-weight: 400;
}
.split-line {
	width: .02rem;
	height: .14rem;
	margin: 0 .07rem;
	background-color: #CCCCCC;
}
.view-wrap {
	display: flex;
	align-items: center;
	margin-left: .4rem;
}
.view-icon-wrap {
	margin-right: .08rem;
}
.view-icon {
	width: .2rem;
	margin-bottom: .03rem;
}

.content {
	padding: .3rem 0 .7rem;
	border-bottom: .01rem solid #E6E6E6;
}
.paging {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: .15rem;
}
.page-btn {
	display: flex;
	align-items: center;
	cursor: pointer;
}
.page-icon-wrap {
	margin-right: .07rem;
}
.page-icon {
	width: .22rem;
}
.page-title {
	font-size: .14rem;
	color: #333333;
}
.back-btn-wrap {
	display: flex;
	justify-content: center;
	margin-top: .2rem;
}
.back-btn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.5rem;
	height: .45rem;
	background: linear-gradient(90deg, #0078EC 40%, #00C4BA 80%);
	border-radius: .25rem;
}
.back-icon-wrap {
	margin-right: .1rem;
}
.back-icon {
	width: .18rem;
	margin-bottom: .05rem;
}
.btn-text {
	font-size: .15rem;
	color: #fff;
}

</style>
