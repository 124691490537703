import {
  UIData
} from "@/common/targer";
import Vue from "vue";
import store from "@/store";
export class Product extends UIData {
  constructor(target) {
    super(target);
  }


  toggleVideo(target, index) {
    target.videoIndex = index;
  }

  hoverToggleVideo(target, index) {
    let timer = null;
    return function () {
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        target.videoIndex = index;
        clearTimeout(timer);
      }, 150);
    }
  }

  //跳转详情
  routerDetails(p) {
    Vue.prototype._router.push({
      name: 'product_details',
      query: {
        p_index: p,
        item: JSON.stringify(this)
      }
    });
    window.scrollTo(0, 0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}
