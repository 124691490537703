<template>
  <div class="t-foot-info">
    <div class="img-wrap">
      <template v-if="type == 1">
        <img src="https://img.jozobio.com/img/common/img_footer_bg_active.png" alt="" class="img wow fadeInUp">
      </template>
      <template v-else>
        <img src="https://img.jozobio.com/img/common/img_footer_bg.png" alt="" class="img wow fadeInUp">
      </template>
    </div>
    
    <template v-if="isMobile">
      <div class="mobile-container" :class="{'mobile-color': type == 1}">
        <div class="nav-list">
          <div class="nav-wrap">
            <div 
              class="item" 
              v-for="item in defaultNav" 
              :key="item.title"
              @click="handleItem(item.url)">{{item.title}}</div>
          </div>
        </div>
        <div class="code-wrap">
          <div class="text">更多玉溪九州生物技术信息及内容请点击关注</div>
          <div class="img-wrap">
            <div class="item">
              <img :src="configInfo.wx_img" alt="" class="img">
              <div class="text">九洲生物公众号</div>
            </div>
            <div class="item">
              <img :src="configInfo.dy_img" alt="" class="img">
              <div class="text">九洲生物抖音号</div>
            </div>
          </div>
        </div>
        <div class="base-info">
          <div class="left">
            <template v-if="type == 1">
              <img :src="configInfo.bottom_logo" alt="" class="img">
            </template>
            <template v-else>
              <img :src="configInfo.top_logo" alt="" class="img">
            </template>
          </div>
          <div class="right">
            <div class="text">云南玉溪九州生物技术有限责任公司</div>
            <div class="text">地址：{{ configInfo.site }}</div>
            <div class="text">电话：{{ configInfo.mobile }}</div>
            <div class="text">邮箱：{{configInfo.serve_email}}</div>
            <div class="text">安全性信息服务热线：{{ configInfo.hotline }}</div>
          </div>
        </div>
        <div class="bottom-info">
          <div class="left wow fadeIn" data-wow-delay="0.4s">
            {{ configInfo.copyright }} 
            <a :style="{fontSize: '.14rem', color: type === 1 ? '#fff' : '#121212'}" href="https://beian.miit.gov.cn/" target="_blank">备案码：{{ configInfo.beian }}</a>
            <a style="display:flex;" :style="{fontSize: '.14rem', color: type === 1 ? '#fff' : '#121212'}" href="https://beian.mps.gov.cn/" target="_blank">
              <img src="https://img.jozobio.com/uploads/20240607/Fp40nnu64ycnwO4XMdcLL2_hGlXi.png" alt="" style="width:10px;">&nbsp;&nbsp;{{ configInfo.filings }}</a>
          </div>
          <div class="right wow fadeIn" :class="{'bg-b': +type === 2}" data-wow-delay="0.4s">
            <el-select v-model="value" placeholder="友情链接" @change="linkFriend">
              <el-option
                v-for="item in configInfo.friendship"
                :key="item.id"
                :label="item.title"
                :value="item.url">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content-info" :class="[{'type-one': type == 1},{'type-two': type == 2}]">
        <div class="top">
          <div class="left">
            <template v-if="type == 1">
              <img :src="configInfo.bottom_logo" alt="" class="img wow fadeInUp"  data-wow-delay="0.3s" >
            </template>
            <template v-else>
              <img :src="configInfo.top_logo" alt="" class="img wow fadeInUp"  data-wow-delay="0.3s">
            </template>
            <div class="text-wrap">
              <div class="item wow fadeInUp" data-wow-delay="0.4s">
                <div class="left">
                  <template v-if="type == 1">
                    <img src="https://img.jozobio.com/img/common/icon_address_white.png" alt="" class="img">
                  </template>
                  <template v-else>
                    <img src="https://img.jozobio.com/img/common/icon_address_black.png" alt="" class="img">
                  </template>
                </div>
                <div class="right">
                  {{ configInfo.site }}
                </div>
              </div>
              <div class="item wow fadeInUp" data-wow-delay="0.4s">
                <div class="left">
                  <template v-if="type == 1">
                    <img src="https://img.jozobio.com/img/common/icon_mobile_white.png" alt="" class="img">
                  </template>
                  <template v-else>
                    <img src="https://img.jozobio.com/img/common/icon_mobile_black.png" alt="" class="img">
                  </template>
                </div>
                <div class="right">
                  电话：{{ configInfo.mobile }}
                </div>
              </div>
              <div class="item wow fadeInUp" data-wow-delay="0.4s">
                <div class="left">
                  <template v-if="type == 1">
                    <img src="https://img.jozobio.com/img/common/icon_mobile_white.png" alt="" class="img">
                  </template>
                  <template v-else>
                    <img src="https://img.jozobio.com/img/common/icon_mobile_black.png" alt="" class="img">
                  </template>
                </div>
                <div class="right">
                  24小时服务热线：{{ configInfo.hotline }}
                </div>
              </div>
              <div class="item wow fadeInUp" data-wow-delay="0.4s">
                <div class="right">
                  安全性信息服务邮箱：{{configInfo.serve_email}}
                </div>
              </div>
            </div>
            <div class="img-wrap">
              <div class="item wow fadeIn" data-wow-delay="0.5s">
                <img :src="configInfo.wx_img" alt="" class="img">
                <div class="text">九洲生物公众号</div>
              </div>
              <div class="item wow fadeIn" data-wow-delay="0.6s">
                <img :src="configInfo.dy_img" alt="" class="img">
                <div class="text">九洲生物抖音号</div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="item wow fadeInRight" data-wow-delay="0.3s">
              <div class="title">{{ cProfileNav.title }}</div>
              <div
                class="text"
                v-for="(item, index) in cProfileNav.nav"
                :key="`${cProfileNav.title}-${index}`"
                @click="routerUrl(`/profile?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
            <div class="item wow fadeInRight" data-wow-delay="0.4s">
              <div class="title">{{ cProductNav.title }}</div>
              <div
                class="text"
                v-for="(item, index) in cProductNav.nav"
                :key="`${cProductNav.title}-${index}`"
                @click="routerUrl(`/product?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
            <div class="item wow fadeInRight" data-wow-delay="0.5s">
              <div class="title">{{ cExploitNav.title }}</div>
              <div
                  class="text"
                  v-for="(item, index) in cExploitNav.nav"
                  :key="`${cExploitNav.title}-${index}`"
                  @click="routerUrl(`/exploit?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
            <div class="item wow fadeInRight" data-wow-delay="0.6s">
              <div class="title">{{ cNewsNav.title }}</div>
              <div
                  class="text"
                  v-for="(item, index) in cNewsNav.nav"
                  :key="`${cNewsNav.title}-${index}`"
                  @click="routerUrl(`/news?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
            <div class="item wow fadeInRight" data-wow-delay="0.6s">
              <div class="title">{{ cMessageNav.title }}</div>
              <div
                  class="text"
                  v-for="(item, index) in cMessageNav.nav"
                  :key="`${cMessageNav.title}-${index}`"
                  @click="routerUrl(`/message?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
            <div class="item wow fadeInRight" data-wow-delay="0.7s">
              <div class="title">{{ cWeNav.title }}</div>
              <div
                  class="text"
                  v-for="(item, index) in cWeNav.nav"
                  :key="`${cWeNav.title}-${index}`"
                  @click="routerUrl(`/we?p_index=${index}`)"
              >{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div class="center">
          <div class="border"></div>
        </div>
        <div class="bottom">
          <div class="left wow fadeIn" data-wow-delay="0.4s" style="display:flex;">
            {{ configInfo.copyright }} 
            &nbsp;&nbsp;&nbsp;&nbsp;<a :style="{fontSize: '.14rem', color: type === 1 ? '#fff' : '#121212'}" href="https://beian.miit.gov.cn/" target="_blank">备案码：{{ configInfo.beian }}</a>
            &nbsp;&nbsp;&nbsp;&nbsp;<a style="display:flex;" :style="{fontSize: '.14rem', color: type === 1 ? '#fff' : '#121212'}" href="https://beian.mps.gov.cn/" target="_blank">
              <img src="https://img.jozobio.com/uploads/20240607/Fp40nnu64ycnwO4XMdcLL2_hGlXi.png" alt="" style="width:20px;">&nbsp;&nbsp;{{ configInfo.filings }}</a>
          </div>
          <div class="right wow fadeIn" :class="{'bg-b': +type === 2}" data-wow-delay="0.4s">
            <el-select v-model="value" placeholder="友情链接" @change="linkFriend">
              <el-option
                v-for="item in configInfo.friendship"
                :key="item.id"
                :label="item.title"
                :value="item.url">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import base from '@/router/base';
export default {
  data() {
    return {
      value: '',
      type: 1,
      options: [{
        label: '链接1',
        value: 1
      },{
        label: '链接2',
        value: 2
      },{
        label: '链接3',
        value: 3
      }],
      isMobile: false,
      timer: null
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.type = value.meta.footer || 1;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters([
      'configInfo'
    ]),
    defaultNav() {
      let arr = [];
      for(let i = 0; i < base.length; i++) {
        if(base[i].path && base[i].path != '/index') {
          arr.push({
            title: base[i].meta.title,
            url: base[i].path
          })
        }
      }
      return arr;
    },
    cProfileNav() {
      return this.$store.state.profile.nav || {};
    },
    cProductNav() {
      return this.$store.state.product.nav || {};
    },
    cExploitNav() {
      return this.$store.state.exploit.nav || {};
    },
    cNewsNav() {
      return this.$store.state.news.nav || {};
    },
    cMessageNav() {
      return this.$store.state.message.nav || {};
    },
    cWeNav() {
      return this.$store.state.we.nav || {};
    }
  },
  created() {
    this.handleResize();
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleItem(path) {
      this.$router.push(path);
      if (this.$store.state.isMedia) {
        this.open = false;
      }
      window.scrollTo(0, 0);
      if (!this.$store.state.isMedia) {
        this.$nextTick(() => {
          this.$store.commit('initWoW');
        });
      }
    },
    handleResize() {
      if(this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isMobile = document.body.clientWidth <= 980;
        // console.log(this.isMobile )
      }, 300)
    },
    linkFriend(value) {
      const a = document.createElement('a');
      a.href = value;
      a.target = '_blank';
      a.click();
      document.removeChild(a);
    },
    routerUrl(url) {
      this.$router.push({
        path: url
      });
      window.scroll(0, 0);
    }
  }
};
</script>

<style lang="less" scoped>
  .t-foot-info {
    position: relative;
    >.img-wrap {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      >.img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    >.mobile-container {
      position: relative;
      z-index: 1;
      >.nav-list {
        padding: .8rem 0 .6rem;
        margin: 0 .5rem;
        border-bottom: solid 1px rgba(255, 255, 255, .5);
        display: flex;
        justify-content: flex-end;
        >.nav-wrap {
          margin-right: -.3rem;
          width: 5rem;
          display: flex;
          flex-wrap: wrap;
          >.item {
            width: 2.5rem;
            font-size: .35rem;
            &:nth-child(n+3) {
              margin-top: .35rem;
            }
          }
        }
      }
      >.code-wrap {
        margin: 0 .5rem;
        >.text {
          margin-top: .1rem;
          padding: .1rem;
          font-size: .3rem;
        }
        >.img-wrap {
          padding: .6rem .2rem;
          border-bottom: dotted 1px rgba(255, 255, 255, 1);
          display: flex;
          justify-content: space-around;
          >.item {
            >.img {
              width: 2.5rem;
            }
            >.text {
              margin-top: .1rem;
              font-size: .25rem;
              text-align: center;
            }
          }
        }
      }
      >.base-info {
        margin: 0 .5rem;
        padding: .6rem .1rem .6rem .4rem;
        border-bottom: solid 1px rgba(255, 255, 255, .5);
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        >.left {
          position: relative;
          bottom: .1rem;
          >.img {
            width: 4rem;
          }
        }
        >.right {
          >.text {
            font-size: .3rem;
          }
        }
      }
      >.bottom-info {
        padding: .5rem .8rem;
        display: flex;
        align-items: center;
        >.left {
          font-size: .25rem;
          a {
            font-size: .25rem !important;
          }
        }
        >.right {
          margin-left: .5rem;
        }
        /deep/ input::placeholder {
          color: #fff;
          font-size: .35rem !important;
        }
        /deep/ .el-select .el-input__inner {
          border-radius: 50px;
          height: 30px;
          border: 1px solid rgba(255, 255, 255, .7);
          background-color: transparent;
        }
        /deep/ .el-select__caret {
          margin-top: -5px;
          transition: 0s;
          color: rgba(255, 255, 255, .7) !important;
        }
        /deep/ .el-select__caret.is-reverse {
          margin-top: 5px;
        }
      }
    }
    >.mobile-color {
      >.nav-list {
        >.nav-wrap {
          >.item {
            color: #fff;
          }
        }
      }
      >.code-wrap {
        >.text {
          color: #fff;
        }
        >.img-wrap {
          >.item {
            >.text {
              color: #fff;
            }
          }
        }
      }
      >.base-info {
        >.right {
          >.text {
            color: #fff;
          }
        }
      }
      >.bottom-info {
        >.left {
          color: #fff;
        }
      }
    }
    >.content-info {
      position: relative;
      z-index: 1;
      >.top {
        padding: .78rem .86rem .64rem;
        display: flex;
        >.left {
          >.img {
            height: .5rem;
          }
          >.text-wrap {
            margin-top: .2rem;
            >.item {
              margin-top: .15rem;
              display: flex;
              align-items: center;
              >.left {
                >.img {
                  width: .15rem;
                }
              }
              >.right {
                margin-left: .1rem;
                opacity: .8;
              }
            }
          }
          >.img-wrap {
            margin-top: .3rem;
            display: flex;
            >.item {
              &:nth-child(n+2) {
                margin-left: .15rem;
              }
              >.img {
                width: 1rem;
              }
              >.text {
                margin-top: .1rem;
                text-align: center;
                font-size: .12rem;
              }
            }
          }
        }
        >.right {
          flex: 1;
          padding: 0 .5rem 0 1rem;
          display: flex;
          justify-content: space-between;
          >.item {
            >.title {
              font-size: .17rem;
            }
            >.text {
              margin-top: .15rem;
              opacity: .6;
              cursor: pointer;
              font-size: .17rem;
              transition: .2s;
              &:hover {
                opacity: .9;
              }
            }
          }
        }
      }
      >.center {
        >.border {
          border-bottom: solid 1px rgba(255, 255, 255, .2);
        }
      }
      >.bottom {
        padding: .2rem .86rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        >.left {
          font-size: .14rem;
          opacity: .7;
          >a {
            color: #fff;
            &:hover {
              color: #007aff;
            }
          }
        }
        >.right {
          &.bg-b {
            /deep/ .el-input__inner {
              background-color: transparent;
              border: .01rem solid #111111;
            }
          }
          /deep/ .el-input__inner {
            border-radius: 20px;
          }
        }
      }
    }
    >.type-one {
      color: #fff;
    }
    >.type-two {
      >.center {
        >.border {
          border-bottom: solid 1px rgba(0, 0, 0, .15);
        }
      }
    }
  }
/deep/ .el-select__caret {
  color: #8A8A8A !important;
  transform: rotateZ(0) !important;
  &.is-reverse {
    transform: rotateZ(180deg) !important;
  }
}
/deep/ .el-select {
  .el-input__inner {
    &::placeholder {
      font-size: .14rem;
      color: #545454;
    }
  }
}
</style>
