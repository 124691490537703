import Vue from "vue";
import {
	Product
} from "@/views/product/target/product";
//公司产品模块
export default {
	namespaced: true,
  state: {
		nav: {},
		productInfo: {
			productList: [],
			beingProduct: [],
			coupeVideo: []
		}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setProductList(state, payload) {
			state.productInfo.productList = payload || [];
		},
		setBeingProduct(state, payload) {
			state.productInfo.beingProduct = payload || [];
		},
		setCoupeVideo(state, payload) {
			state.productInfo.coupeVideo = payload || [];
		}
	},
	actions: {
		product({commit}, type) {
			return new Promise( async (resolve, reject) => {
				const res = await Vue.prototype.$api.product.product({
					type
				});
				if (res.code !== 1) return reject();
				res.data = res.data.map(v => new Product(v));
				if (type === 1) {
					commit('setProductList', res.data);
				}
				if (type === 2) {
					commit('setBeingProduct', res.data);
				}
				if (type === 3) {
					commit('setCoupeVideo', res.data);
				}
				resolve();
			});
		}
	}
}
