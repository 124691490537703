<template>
  <div id="app">
    <div class="wrapper">
      <template v-if="+configInfo.closeWeb !== 1">
        <template v-if="$route.path === '/index'">
          <guide-page ref="guidePage"></guide-page>
        </template>
        <t-top-nav></t-top-nav>
          <router-view></router-view>
        <t-foot-info></t-foot-info>
      </template>
      <template v-else>
        <close></close>
      </template>
    </div>
  </div>
</template>

<script>
import TTopNav from './components/content/tTopNav/TTopNav';
import TFootInfo from './components/content/tFootInfo/TFootInfo';
import GuidePage from "@/views/index/components/guide-page.vue";
import Close from "@/views/close/index.vue";
import {mapGetters} from "vuex";
export default {
  components: { TTopNav, TFootInfo,GuidePage, Close },
  name: 'App',
  metaInfo() {
    return {
      title: this.title,
        meta: this.metaList,
    }
  },
  data() {
    return {
      timer: null,
      sizeTimer: null,
      title: '',
      metaList: [
        {
          name: 'description',
          content: '',
        },
        {
          name: 'keywords',
          content: '',
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(newVal) {
      },
      deep: true,
      immediate: true
    },
    configInfo: {
      handler(newVal) {
        this.title = newVal?.top_name || '';
        this.metaList[0].content = newVal?.top_describe || '';
        this.metaList[1].content = newVal?.top_seo || '';
      },
      deep: true,
      immediate: true
    }
  },
  created() {

    // 禁用鼠标右键
    document.addEventListener('contextmenu', function(e) {
      e.preventDefault();
    });

    this.$store.commit('isMobile');
    this.$store.commit('isMedia');
    this.$store.dispatch('config', this.$route);
    this.$nextTick(() => {
      this.initPage();
      // this.initWidth();
      // window.addEventListener('resize', this.onResize);
      window.addEventListener('resize', this.initPage);
    });
  },
  mounted() {
  },
  destroyed() {
    // window.removeEventListener('resize', this.onResize);
    window.removeEventListener('resize', this.initPage);
  },
  computed: {
    ...mapGetters([
      'configInfo'
    ]),
    isPromise() {
      return this.$store.state.isPromise;
    },
  },
  methods: {
    initPage() {
      const html = document.getElementsByTagName('html')[0];
      // let width = 0;
      // if (this.$store.state.isMobile) {
      //   width = window.screen.width;
      // } else {
      //   width = this.$store.state.isMobile ? 1200 : window.screen.width;
      // }
      // const width = this.$store.state.isMobile ? 1200 : window.screen.width;
      const width = window.innerWidth;
      let fontSize = (width * 100) / 1920;
      html.style.fontSize = (fontSize < 30 ? 30 : fontSize) + 'px';
      // html.style.fontSize = fontSize + 'px';
    },
    initWidth(){
      const fontSize = document.getElementsByTagName('html')[0].style.fontSize.replace('px', '');
      const body = document.getElementsByTagName('body')[0];
      let width = window.screen.availWidth - (0.1 * fontSize) - (window.outerWidth - window.innerWidth);
      if (this.$store.state.isMobile) {
        width = width < 980 ? 1200 : width;
      } else {
        width = width < 1300 ? 1400 : width;
      }
      body.style.width = (width < 980 ? 1200 : width) + 'px';
    },
    onResize() {
      if (this.sizeTimer) clearTimeout(this.sizeTimer);
      this.sizeTimer = setTimeout(() => {
        this.initWidth();
      }, 100);
    }
  }
}
</script>
<style lang="less">
@import "./components/content/tTopNav/style/topNav";
@import "./components/content/tFootInfo/style/footer";
@import "./components/content/tTopInfo/style/topInfo";
@import "./components/content/tNavList/style/navList";
@import "./components/content/tTitleInfo/style/titleInfo";
@import "./views/index/style/index";
@import "./views/profile/style/profile";
@import "./views/product/style/product";
@import "./views/exploit/style/exploit";
@import "./views/message/style/message";
@import "./views/we/style/we";
@import "./views/news/style/news";
@import "./views/search/style/search";
@media only screen and (max-width: 980px) {
  :root {
    --wrapper: .3rem !important;
  }
  #app {
    #topNav();
    #footer();
    #topInfo();
    #navList();
    #titleInfo();
    #index();
    #profile();
    #product();
    #exploit();
    #message();
    #news();
    #we();
    #search();
  }
  .el-message__icon {
    font-size: .5rem;
  }
  .el-select-dropdown__item {
    span {
      font-size: .35rem;
    }
  }
  .el-date-table {
    th,
    td {
      font-size: .35rem;
      span {
        font-size: .35rem;
      }
    }
  }
  .el-button {
    span {
      font-size: .35rem;
    }
  }
  //详情样式
  .details {
    &.message {
      >.t-nav-list {
        >.content-info {
          padding: 0;
          flex-direction: column;
          >.left {
            display: none;
          }
          >.right {
            align-items: center;
            width: 100%;
            >.list {
              right: 0;
              left: 0;
            }
          }
        }
      }
    }
    >.news-content {
      padding: 0 .4rem !important;
      >.news-title-wrap {
        margin-top: 1rem;
        >.news-title {
          padding-bottom: .2rem;
          font-size: .6rem;
        }
        >.news-source-date {
          flex-direction: column;
          >.date-wrap {
            >.date-icon-wrap {
              >.date-icon {
                width: .4rem;
              }
            }
            >.date {
              font-size: .3rem;
              >.datetime {
                font-size: inherit;
              }
            }
          }
          >.split-line {
            display: none;
          }
          >.source-wrap {
            margin: .15rem 0;
            font-size: .3rem;
            >.source {
              font-size: inherit;
            }
          }
          >.view-wrap {
            >.view-icon-wrap {
              >.view-icon {
                width: .45rem;
              }
            }
            >.view {
              font-size: .3rem;
            }
          }
        }
      }
      >.paging {
        align-items: flex-start;
        flex-direction: column;
        >.page-btn {
          margin-bottom: .2rem;
          >.page-icon-wrap {
            >.page-icon {
              width: .5rem;
            }
          }
          >.page-title {
            font-size: .35rem;
          }
        }
      }
      >.back-btn-wrap {
        >.back-btn {
          width: 4rem;
          height: 1rem;
          border-radius: .5rem;
          >.back-icon-wrap {
            >.back-icon {
              width: .5rem;
            }
          }
          >.btn-text {
            font-size: .3rem;
          }
        }
      }
    }
    >.hos-news {
      margin: 0.4rem 0 1rem !important;
      padding: 0 .4rem !important;
      >.header {
        >.header-icon-wrap {
          >.header-icon {
            width: .25rem;
          }
        }
        >.hot-label {
          font-size: .5rem;
        }
      }
      >.news-swiper-container {
        >.swiper-wrapper {
          >.swiper-slide {
            >.news-item {
              width: 100%;
              >.title-wrap {
                >.news-title {
                  font-size: .35rem;
                }
                >.more-icon-wrap {
                  >.more-icon {
                    width: .45rem;
                  }
                }
              }
              >.desc-wrap {
                >.desc {
                  font-size: .3rem;
                }
              }
            }
          }
        }
      }
    }

    //公司产品详情样式
    >.details-container {
      padding: 0 .4rem !important;
      >.header-info {
        flex-direction: column;
        padding: .3rem 0;
        >.thumb-list-wrap {
          width: 100%;
          >.thumb-list {
            >.thumb-swiper-container {
              >.swiper-wrapper {
                >.swiper-slide {
                  >.thumb-wrap {
                    >.thumb {
                      width: 100%;
                    }
                  }
                }
              }
            }
          }
        }
        .label-title {
          .label {
            font-size: .4rem !important;
          }
        }
        >.info-wrap {
          padding: 0;
          >.product-title{
            margin-top: .3rem;
            font-size: .6rem;
          }
          >.advantage {
            >.label {
              font-size: .4rem;
            }
            >.advantage-list {
              >.advantage-item {
                font-size: .3rem;
                line-height: .55rem;
                span {
                  font-size: .3rem;
                }
              }
            }
          }
        }
      }
      >.product-info {
        >.introduce-wrap {
          >.label-wrap{
            >.label{
              height: .8rem;
              border-radius: .8rem .8rem 0 .8rem;
              font-size: .4rem;
              line-height: .8rem;
            }
          }
          >.introduce-info {
            margin-bottom: .3rem;
            >.info-item {
              width: 100%;
              >.title {
                font-size: .4rem;
              }
              >.content {

              }
            }
          }
        }
        >.coupe-list-wrap {
          >.coupe-item {
            >.label-wrap {
              >.label {
                height: .8rem;
                border-radius: .8rem .8rem 0 .8rem;
                font-size: .4rem;
                line-height: .8rem;
              }
            }
            >.coupe-content {
              margin-bottom: .8rem;
            }
          }
        }
      }
    }
    //公司产品详情样式
  }
  //详情样式
}
</style>
<style>
@font-face {
  font-family: siyuan;
  src: url("./assets/font/SourceHanSansCN-Bold.otf");
}
:root {
  --wrapper: 1.34rem;
  --titleColor: rgba(63, 104, 245, 1);
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-size: .16rem;
  font-family: siyuan !important;
}

body {
  position: relative;
  margin: auto;
  /* width: calc(19.2rem - .1rem); */
}
body::-webkit-scrollbar{
  width: .1rem;
  height: .1rem;
  background: #F1F1F1;
  border-radius: 10px;
}
body::-webkit-scrollbar-thumb{
  display:block;
  width: .06rem;
  margin:0 auto;
  border-radius: 10px;
  background: #ccc;
}
img {
  display: block;
  width: auto;
  height: auto;
  vertical-align: middle;
}
input,
textarea{
  outline: 0;
}
a {
  text-decoration: none;
}
.btn {
  transition: all .2s;
  cursor: pointer;
  user-select: none;
}
.btn:active {
  transform: translate(-.01rem, .02rem);
}
.nowrap {
  white-space: nowrap;
}
.one-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.more-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}
.clamp-1 {
  -webkit-line-clamp: 1;
  line-clamp: 1;
}
.clamp-2 {
  -webkit-line-clamp: 2;
  line-clamp: 2;
}
.clamp-3 {
  -webkit-line-clamp: 3;
  line-clamp: 3;
}
.clamp-4 {
  -webkit-line-clamp: 4;
  line-clamp: 4;
}
.pagination-wrap {
  margin-top: .6rem;
  display: flex;
  justify-content: center;
}
</style>
