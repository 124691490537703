import {
  UIData
} from "@/common/targer";
import Vue from "vue";
import store from "@/store";

export const profileTypes = {
  INDEX: 'index', //首页配置
}

export class About extends UIData {
  constructor(target, type) {
    super(target);
    if (type === profileTypes.INDEX) {
      this.initIndexType();
    }
  }
  initIndexType() {
    const json = [];
    for (let key in this.abput_json) {
      const unit = this.abput_json[key].match(/\D+/)[0];
      const value = this.abput_json[key].match(/\d+/)[0];
      json.push({
        label: key,
        value,
        unit
      });
    }
    this.abput_json = json;
  }

  routerDetails(p) {
    Vue.prototype._router.push({
      path: '/profile_details',
      query: {
        p_index: p,
        item: JSON.stringify(this)
      }
    });
    window.scroll(0, 0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}

export class Course extends UIData {
  constructor(target) {
    super(target)
  }

  toggleCourse(target, index) {
    target.currentIndex = index;
    const dom = target.$refs[`course_${target.currentIndex}`][0];
    let left = -dom.offsetLeft / 2;
    if (store.state.isMedia) {
      left *= 2
    }
    target.left = left;
  }
}

export class HonorNav extends UIData {
  constructor(target) {
    super(target)
  }
}
export class Honor extends UIData {
  constructor(target) {
    super(target)
  }
}

export class Base extends UIData {
  constructor(target) {
    super(target)
  }

  routerDetails(p) {
    Vue.prototype._router.push({
      path: '/profile_details',
      query: {
        p_index: p,
        item: JSON.stringify(this)
      }
    });
    window.scroll(0, 0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}

export class Media extends UIData {
  constructor(target) {
    super(target)
  }
}
