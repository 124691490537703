<template>
  <div class="top-info">
    <div class="left">
      <t-title-info
        :label="navInfo.english_title"
        :title="navInfo.title"
        :iconShow="true"
        labelColor="#000"
        :signRight="false">
      </t-title-info>
    </div>
    <div class="right wow fadeInUp">
      共创生物健康事业，实现卓越成长
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    };
  },

  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .top-info {
    padding: .8rem 1.8rem .5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    >.right {
      color: #666;
    }
  }
</style>
