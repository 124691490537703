//单例模式
export function singleTon(className) {
  let ins = null;
  return new Proxy(className, {
    construct(target, arg) {
      if (!ins) {
        ins = new className(...arg);
      }
      return ins;
    }
  })
}
