<template>
  <div class="talents-info">
    <t-title-info
        label="Recruitment Info"
        title="招聘信息"
        title-border=".01rem dashed var(--titleColor)"
      ></t-title-info>
    <div class="content-info">
      <div class="left">
        <img :src="cTalents['1image']" alt="" class="img">
      </div>
      <div class="right wow fadeInRight">
        <img src="https://img.jozobio.com/img/we/img_01.png" alt="" class="img">
        <img src="https://img.jozobio.com/img/we/img_01.png" alt="" class="img">
        <img src="https://img.jozobio.com/img/we/img_02.png" alt="" class="img">
        <img src="https://img.jozobio.com/img/we/img_02.png" alt="" class="img">
        <img src="https://img.jozobio.com/img/we/img_02.png" alt="" class="img">
        <div class="title-wrap">
          <img src="@/assets/common/icon_title_label.png" alt="" class="img">
          <!-- 人才理念 -->
          {{navInfo.title}}
        </div>
        <div class="text wow fadeIn" data-wow-delay="0.3s">
          {{ cTalents.con}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    };
  },
  computed: {
    cTalents() {
      return this.$store.state.we.weInfo.talents || {};
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .talents-info {
    position: relative;
    padding: .5rem 1.8rem .8rem;
    overflow: hidden;
    >.content-info {
      margin-top: .5rem;
      display: flex;
      >.left {
        flex: 1;
        >.img {
          width: 100%;
        }
      }
      >.right {
        flex: 1.3;
        margin-left: .8rem;
        >.title-wrap {
          font-size: .23rem;
          display: flex;
          align-items: center;
          >.img {
            margin-right: .1rem;
            width: .5rem;
          }
          @media screen and(max-width: 980px) {
            padding: .2rem 0 .05rem;
            font-size: .45rem;
            >.img {
              margin-right: .1rem;
              width: .8rem;
            }
          }
        }
        >.img {
          width: 2rem;
        }
        >.img:nth-child(1) {
          position: absolute;
          top: .3rem;
          left: .7rem;
          width: 1.7rem;
        }
        >.img:nth-child(2) {
          position: absolute;
          top: .4rem;
          right: 1rem;
          width: 2rem;
        }
        >.img:nth-child(3) {
          position: absolute;
          bottom: -.3rem;
          left: .5rem;
          width: 3rem;
        }
        >.img:nth-child(4) {
          position: absolute;
          bottom: -.8rem;
          right: 6rem;
          width: 2rem;
        }
        >.img:nth-child(5) {
          position: absolute;
          bottom: -.15rem;
          right: 1rem;
          width: 2rem;
        }
        >.text {
          margin-top: .2rem;
          line-height: .35rem;
          color: #666;
          @media screen and(max-width: 980px) {
            font-size: .3rem;
          }
        }
      }
      @media only screen and (max-width: 980px) {
        display: initial;
         >.right {
          flex: 1.3;
          margin-top: .3rem;
          margin-left: 0;
          .text {
            line-height: .6rem;
          }
         }
      }
    }
  }
</style>
