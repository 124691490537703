<template>
  <div class="course-info">
    <img src="https://img.jozobio.com/img/profile/img_1.jpg" alt="" class="img">
    <div class="content-info">
      <t-title-info
        label="Development History"
        title="发展历程"
        labelColor="#fff"
        titleColor="#fff"
        :type1-style="{
          marginLeft: $store.state.isMedia ? '.4rem' : '1.5rem'
        }"
        title-border=".01rem dashed var(--titleColor)"
        ></t-title-info>
      <div class="info-wrap" v-if="cCourse[currentIndex]">
        <div class="left wow fadeInLeft">
          <img :src="cCourse[currentIndex]?.image" :alt="cCourse[currentIndex]?.title" class="img">
        </div>
        <div class="right wow fadeInRight">
          <div class="name">
            <img class="icon" src="https://img.jozobio.com/img/profile/profile_course_icon.png" />
            <div class="text">{{cCourse[currentIndex]?.year}}</div>
          </div>
          <div class="text">
            <div
              class="info-item wow fadeInUp"
              v-for="(item, index) in cCourse[currentIndex].content ? JSON.parse(cCourse[currentIndex].content) : []"
              :key="`json-${index}`"
            >
              · {{ item.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="list-wrap">
        <div class="left wow fadeIn" @click="handleItem('de')">
          <img src="https://img.jozobio.com/img/profile/icon_arrows_white.png" alt="" class="img">
        </div>
        <div class="center">
          <div class="border"></div>
          <div class="item-list" :style="{transform: `translateX(${left}px)`}">
            <div
                class="item"
                :class="{active: index === currentIndex}"
                v-for="(item, index) in cCourse"
                :ref="`course_${index}`"
                :key="item.id"
                @click="item.toggleCourse($vnode.componentInstance, index)">
              <img class="icon" src="https://img.jozobio.com/img/profile/profile_course_list_icon.png" />
              <div class="sign wow bounceIn" :data-wow-delay="`${0.2 * (index * 1)}s`"></div>
              <div class="text">{{item.year}}</div>
            </div>
          </div>
        </div>
        <div class="right wow fadeIn" @click="handleItem('in')">
          <img src="https://img.jozobio.com/img/profile/icon_arrows_white.png" alt="" class="img">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

export default {
  data() {
    return {
      currentIndex: 0,
      left: 0
    };
  },
  computed: {
    cCourse() {
      return this.$store.state.profile.profileInfo.develop || []
    }
  },
  methods: {
    handleItem(name) {
      if(name == 'in') {
        if(this.currentIndex < this.cCourse.length - 1) {
          this.currentIndex++;
        }
      }else if(name == 'de') {
        if(this.currentIndex > 0) {
          this.currentIndex--;
        }
      }
      const dom = this.$refs[`course_${this.currentIndex}`][0];
      let left = -dom.offsetLeft / 2;
      if (store.state.isMedia) {
        left *= 2
      }
      this.left = left;
    }
  },
};
</script>

<style lang="less" scoped>
  .course-info {
    position: relative;
    >.img {
      width: 100%;
      height: 8.5rem;
    }
    >.content-info {
      position: absolute;
      left: 0;
      right: 0;
      top: .6rem;
      >.info-wrap {
        padding: 0 1.5rem;
        margin-top: .5rem;
        display: flex;
        flex-direction: row-reverse;
        >.left {
          flex: 1;
          >.img {
            width: 100%;
          }
        }
        >.right {
          flex: 1.3;
          padding-right: .3rem;
          >.name {
            display: flex;
            align-items: center;
            position: relative;
            padding-bottom: .15rem;
            border-bottom: .01rem dashed #fff;
            color: #fff;
            >.icon {
              width: .7rem;
              margin-right: .2rem;
            }
            >.text {
              font-size: .36rem;
            }
          }
          >.text {
            margin-top: .1rem;
            line-height: .34rem;
            font-size: .20rem;
            color: #fff;
          }
        }
      }
      >.list-wrap {
        margin-top: .2rem;
        padding: 0 1.5rem;
        display: flex;
        align-items: center;
        >.left {
          >.img {
            width: .5rem;
            transform: rotate(180deg);
            cursor: pointer;
            border-radius: 50%;
            &:hover {
              box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
            }
          }
        }
        >.center {
          flex: 1;
          position: relative;
          padding: 0 .6rem;
          display: flex;
          align-items: center;
          overflow: hidden;
          &::-webkit-scrollbar {
            height: 0;
          }
          >.border {
            position: absolute;
            left: 0;
            right: 0;
            border-bottom: dashed 1px #fff;
            opacity: .6;
            width: 100%;
          }
          >.item-list {
            display: flex;
            align-items: center;
            height: 2rem;
            transition: .3s ease-out;
            >.item {
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0 .6rem;
              >.icon {
                display: none;
                position: absolute;
                width: .5rem;
                bottom: 0;
              }
              >.sign {
                position: absolute;
                width: .09rem;
                height: .09rem;
                border-radius: 50%;
                background-color: #d5eaf8;
                box-shadow: 0 0 0 1px rgba(255, 255, 255, .3);
                transition: .2s;
              }
              >.text {
                margin-top: .45rem;
                position: absolute;
                opacity: .8;
                font-size: .17rem;
                color: #fff;
                transition: .2s;
              }
              &:hover {
                >.text {
                  opacity: 1;
                }
                >.sign {
                  background-color: #fff;
                }
              }
            }
            >.active {
              >.icon {
                display: block;
              }
              >.text {
                margin-top: .7rem;
                font-size: .26rem;
                opacity: 1;
              }
              >.sign {
                width: .16rem;
                height: .16rem;
                background-color: #fff;
                box-shadow: 0 0 0 3px rgba(255, 255, 255, .3);
              }
            }
          }
        }
        >.right {
          >.img {
            width: .5rem;
            cursor: pointer;
            border-radius: 50%;
            &:hover {
              box-shadow: 0 0 0 5px rgba(255, 255, 255, .1);
            }
          }
        }
      }
    }
  }
</style>
