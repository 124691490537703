<template>
  <div class="platform">
    <div class="text wow fadeInUp" data-wow-delay="0.3s" v-html="cAchievement.content"></div>
  </div>
</template>

<script>
export default {
  computed: {
    cAchievement() {
      return this.$store.state.exploit.exploitInfo.workbench[1] || {};
    }
  },
}
</script>

<style scoped lang="less">
.platform {
  padding: 0 1.8rem;
  margin-bottom: .3rem;
}
</style>
