<template>
  <div class="details">
    <t-top-info
        :banner="cSubNavTitle.bg_image"
        :label="cNavInfo.english_title"
        :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
        :titles="cTitles"
        :listInfo="cNavList"
        listKey="title"
        @returnVal="retNav"
    ></t-nav-list>
    <details-content></details-content>
  </div>
</template>

<script>
import DetailsContent from "@/views/profile/details/components/DetailsContent.vue";
import TopBanner from "@/views/profile/mixins/top-banner";
export default {
  components: {
    DetailsContent
  },
  mixins: [TopBanner]
}
</script>

<style scoped lang="less">

</style>
