import Vue from "vue";
import {
	We
} from "@/views/we/target/we";
//加入我们模块
export default {
	namespaced: true,
  state: {
		nav: {},
		weInfo: {
			talents: {}, //人才理念
			employment: [], //招聘信息
			hireIntern: [] //实习生招聘
		}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setWeInfo(state, payload) {
			state.weInfo[payload.key] = payload.data || payload.default;
		}
	},
	actions: {
		we({commit}, params) {
			return new Promise(async (resolve, reject) => {
				const res = await Vue.prototype.$api.we.we(params);
				if (res.code !== 1) return reject();
				let obj = {
					key: '',
					default: []
				};
				if (params.type === 1) {
					obj.key = 'talents';
					obj.default = {};
				}
				if (params.type === 2) {
					obj.key = 'employment';
				}
				if (params.type === 3) {
					obj.key = 'hireIntern';
				}
				if (Array.isArray(res.data)) {
					obj.data = res.data.map(v => new We(v));
				} else {
					obj.data = new We(res.data);
				}
				commit('setWeInfo', obj);
				resolve();
			});
		}
	}
}
