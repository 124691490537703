<template>
  <div class="news">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label="cNavInfo.english_title"
      :title="cNavInfo.title"
    ></t-top-info>
    <t-nav-list
      :index="navIndex"
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      @returnVal="retNav">
    </t-nav-list>
    <news-banner :nav-index="navIndex"></news-banner>
    <news-list :nav-index="navIndex"></news-list>
  </div>
</template>

<script>
import NewsBanner from "@/views/news/components/news-banner.vue";
import NewsList from '@/views/news/components/nwes-list.vue';
import TopBanner from './mixins/top-banner.js';
export default {
  name: 'News',
  components: {
    NewsList,
    NewsBanner
  },
	mixins: [TopBanner]
};
</script>

<style lang="less" scoped>

</style>
