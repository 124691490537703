import Index from '../views/index/Index.vue'
import Profile from '../views/profile/Profile.vue'
import Product from '../views/product/Product.vue'
import ExPloit from '../views/exploit/Exploit.vue'
import News from '../views/news/News.vue'
import Message from '../views/message/Message.vue'
import We from '../views/we/We.vue'

export default [
  {
    path: '',
    redirect: '/index'
  },{
    path: '/index',
    meta: {
      title: '首页',
    },
    component: Index
  },{
    path: '/profile',
    meta: {
      title: '企业概况',
      footer: 2
    },
    component: Profile
  },{
    path: '/product',
    meta: {
      title: '公司产品'
    },
    component: Product
  },{
    path: '/exploit',
    meta: {
      title: '技术开发',
      footer: 2
    },
    component: ExPloit
  },{
    path: '/news',
    meta: {
      title: '新闻中心',
      footer: 1
    },
    component: News
  },{
    path: '/message',
    meta: {
      title: '信息公示',
      footer: 2
    },
    component: Message
  },{
    path: '/we',
    meta: {
      title: '加入我们',
      footer: 1
    },
    component: We
  }
]
