export default {
  data() {
    return {
      navIndex: 0,
      timer: null
    };
  },
  watch: {
    $route: {
      handler(newVal) {
        this.$set(this, 'navIndex', +newVal.query.p_index || 0);
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    cTitles() {
      return ['公司产品', this.navList[this.navIndex]?.title];
    },
    navList() {
      return this.$store.state.product.nav.nav || [];
    },
    cNavInfo() {
      return this.$store.state.product.nav || {};
    },
    cSubNavTitle() {
      return this.navList[this.navIndex] || {};
    }
  },
  methods: {
    retNav(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.$set(this, 'navIndex', index);
        if (this.$route.path === '/product_details') {
          this.$router.push({
            path: '/product',
            query: {
              p_index: index
            }
          });
          if (!this.$store.state.isMedia) {
            this.$nextTick(() => {
              this.$store.commit('initWoW');
            });
          }
        }
      }, 300);
    }
  }
}
