<template>
  <div class="guide-page" id="guideWrap">
    <!-- 动画 -->
    <canvas id="guideCanvas"></canvas>
    <div class="logo-wrap">
      <img class="logo" :class="{show: isLogoShow}" :src="configInfo.top_logo" @load="logoLoad"/>
    </div>
    <div class="bottom-guide" :class="{animation: isGuideBottom}">
      <div class="guide-icon-wrap">
        <img class="guide-icon" src="@/assets/home/guide_icon.png" @load="guideIconLoad"/>
      </div>
      <div class="text">向下滑动</div>
    </div>
  </div>
</template>

<script>
import {
  Graph
} from "@/views/index/target";
import {mapGetters} from "vuex";

export default {
  data() {
    return {
      cvs: null,
      isLogoShow: false,
      isGuideBottom: false
    }
  },
  computed: {
    ...mapGetters([
      'configInfo'
    ]),
    isGuide() {
      return this.$store.state.isGuide;
    }
  },
  watch: {
    isGuide(newVal) {
    }
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.initCanvas();
    });
  },
  methods: {
    initWH() {
      const guideDom = document.getElementById('guideWrap');
      this.cvs.width = document.body.offsetWidth * devicePixelRatio; //乘以缩放倍率
      this.cvs.height = guideDom.offsetHeight * devicePixelRatio;//乘以缩放倍率
    },
    initCanvas() {
      this.cvs = document.getElementById('guideCanvas');
      this.initWH();
      //生成canvas
      const g = new Graph(this.cvs, 10);
      g.draw();
    },
    logoLoad() {
      setTimeout(() => {
        this.isLogoShow = true
      }, 500);
    },
    guideIconLoad() {
      setTimeout(() => {
        this.isGuideBottom = true
      }, 500);
    }
  }
}
</script>

<style scoped lang="less">
.guide-page {
  position: relative;
  width: 100%;
  height: 100vh;
}
#guideCanvas {
  width: 100%;
  height: 100%;
}
.logo-wrap {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.logo {
  width: 3.5rem;
  transition: 2s ease-out;
  opacity: 0;
}
.show {
  opacity: 1 !important;
}

.bottom-guide {
  position: absolute;
  bottom: .45rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: .5s ease-out;
  opacity: 0;
}
.guide-icon-wrap {
  margin-bottom: .1rem;
}
.guide-icon {
  width: .25rem;
}
.text {
  font-size: .14rem;
  color: #172A80;
}
.animation {
  animation: opacity 3s infinite;
  animation-timing-function: ease-in-out;
}
@keyframes opacity {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
