<template>
  <div class="table-info wow fadeInDown" data-wow-delay="0.5s">
    <el-table
      :data="cAchievement.data"
      stripe
      style="width: 100%">
      <el-table-column
        prop="year"
        label="年份"
        align="center"
      >
      </el-table-column>
      <el-table-column
        prop="title"
        label="专项名称"
        :width="$store.state.isMedia ? 'auto' : 580"
        align="center">
      </el-table-column>
      <el-table-column
        prop="unit"
        label="主管单位"
        :width="$store.state.isMedia ? 'auto' : 580"
        align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  computed: {
    cAchievement() {
      return this.$store.state.exploit.exploitInfo.achievement || {};
    }
  },
};
</script>

<style lang="less" scoped>
/deep/ .has-gutter {
  background: linear-gradient(90deg, #0189FF, #00C4BA);
  tr {
    background-color: transparent;
  }
}
/deep/ tr.el-table__row--striped td.el-table__cell {
  background: rgba(229, 240, 248, 1) !important;
}
/deep/ th.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table thead {
  color: #fff;
}
/deep/ .el-table {
  border-radius: .1rem .1rem 0 0;
}
/deep/ .el-table__cell {
  padding: .2rem 0;
}
/deep/ .cell {
  font-size: .18rem;
}
.table-info {
  padding: .5rem 1.8rem;
}
</style>
