import http from './http'

/**
 * 公司概况接口
 */

export default {
  profile() {//首页接口
    return http.get('/index/introduction');
  }
}
