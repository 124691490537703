<template>
  <div class="t-title-info" :style="{margin}">
    <div class="content-info">
      <div class="text-info wow fadeInUp" :style="{alignItems}">
        <template v-if="type == 2">
          <div
            class="title-wrap"
            :style="{
              color: titleColor
            }">
            <template v-if="iconShow">
              <img src="https://img.jozobio.com/img/common/icon_title_sign.png" alt="" class="img">
            </template>
            {{title}}
          </div>
          <div class="label-wrap">
            <div
                class="label"
                :style="
            [cLabelColor]">
              {{label}}
            </div>
          </div>
        </template>
        <template v-if="type == 1">
          <div
            class="type-1"
            :style="{
              '--titleColor': titleColor || 'rgba(63, 104, 245, 1)',
              borderBottom: titleBorder,
              ...type1Style
            }"
          >
            <div
            class="title-wrap"
            :style="{
              color: titleColor
            }">
              <template v-if="iconShow">
<!--                <img src="https://img.jozobio.com/img/common/icon_title_sign.png" alt="" class="img">-->
              </template>
              {{title}}
            </div>
            <div
              class="split">/</div>
            <div class="label-wrap">
              <div
                class="label"
              >
                {{label}}
              </div>
            </div>
            <template v-if="!titleColor">
              <img class="title-icon" src="https://img.jozobio.com/img/common/common_title_icon.png"/>
            </template>
            <template v-else>
              <img class="title-icon" src="https://img.jozobio.com/img/common/common_title_icon_w.png"/>
            </template>
          </div>
        </template>
      </div>
      <template v-if="listInfo.length > 0">
        <div class="nav-list">
          <div
          class="btn wow fadeInRight"
          :data-wow-delay="`${0.4 * (index * 1)}s`"
          :class="{active: itemIndex == index}"
          v-for="(item, itemIndex) in listInfo" :key="itemIndex"
          @click="handleItem(itemIndex)">{{item[listKey]}}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default() {
        return '标签'
      }
    },
    title: {
      type: String,
      default() {
        return '标题'
      }
    },
    type: {
      type: [Number, String],
      default() {
        return 1
      }
    },
    listInfo: {
      type: Array,
      default() {
        return []
      }
    },
    listKey: {
      type: String,
      default: () => 'name'
    },
    margin: {
      type: String,
      default() {
        return ''
      }
    },
    iconShow: {
      Boolean,
      default() {
        return false
      }
    },
    labelColor: {
      type: [Array, String],
      default() {
        return ['#0089FF', '#00C2BC']
      }
    },
    titleColor: {
      type: String,
      default() {
        return ''
      }
    },
    alignItems: {
      type: String,
      default() {
        return ''
      }
    },
    index: {
      type: [Number, String],
      default() {
        return 0
      }
    },
    titleBorder: {
      type: String,
      default: () => ''
    },
    type1Style: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    cSignColor() {
      return function(type) {
        let obj = {};
        if(typeof this.labelColor == 'string') {
          obj.color = this.labelColor;
        }else {
          if(type == 'left') {
            obj.color = this.labelColor[0];
          }else {
            obj.color = this.labelColor[1];
          }
        }
        return obj;
      }
    },
    cLabelColor() {
      let obj = {};
      if(typeof this.labelColor == 'string') {
        obj.color = this.labelColor;
      }else {
        obj.backgroundImage = `linear-gradient(to right, ${this.labelColor[0]}, ${this.labelColor[1]})`
      }
      return obj;
    }
  },
  methods: {
    handleItem(index) {
      this.$emit('returnVal', index);
    }
  }
};
</script>

<style lang="less" scoped>
  .t-title-info {
    >.content-info {
      display: flex;
      .text-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        .label-wrap {
          display: flex;
          align-items: center;
          >.label {
            font-size: .5rem;
            font-family: "Source Han Sans-CN";
            font-weight: bold;
            -webkit-background-clip: text;
            color: transparent;
          }
          >.sign {
            margin-bottom: -.05rem;
            font-size: .18rem;
          }
          >.left {
            margin-right: .05rem;
          }
          >.right {
            margin-left: .05rem;
          }
        }
        .title-wrap {
          margin: .05rem 0;
          font-size: .3rem;
          font-weight: 500;
          >.img {
            margin-right: .05rem;
            width: .32rem;
          }
        }
        >.type-1 {
          display: flex;
          align-items: center;
          flex: 1;
          >.title-wrap {
            font-size: .5rem;
            color: var(--titleColor);
          }
          >.split {
            margin: 0 .13rem;
            font-size: .5rem;
            color: var(--titleColor);
          }
          >.label-wrap {
            >.label {
              font-size: .5rem;
              font-family: "Source Han Sans-CN";
              font-weight: bold;
              -webkit-background-clip: text;
              color: var(--titleColor);
            }
          }
          >.title-icon {
            margin-top: .05rem;
            margin-left: .2rem;
            width: .4rem;
          }
        }
      }
      >.nav-list {
        display: flex;
        align-items: center;
        >.btn {
          padding: .11rem 0;
          min-width: 1.6rem;
          text-align: center;
          border-radius: .2rem .2rem 0 .2rem;
          cursor: pointer;
          &:nth-child(n+2) {
            margin-left: .1rem;
          }
          &:hover {
            background-color: #eee;
          }
        }
        >.active {
          color: #fff;
          background-image: linear-gradient(to right, #0078ec, #00c4ba);
        }
      }
    }
  }
</style>
