<template>
  <div class="title-container" :style="padding">
    <img class="icon wow fadeInUp" src="https://img.jozobio.com/img/common/common_header_icon.png" />
    <h2 class="title-cn wow fadeInUp">{{cn}}</h2>
    <h2 class="title-en wow fadeInUp">{{en}}</h2>
  </div>
</template>

<script>
export default {
  props: {
    en: {
      type: String,
      default: () => ''
    },
    cn: {
      type: String,
      default: () => ''
    },
    padding: {
      type: String,
      default: () => 'padding: 1rem 0 .75rem'
    }
  }
}
</script>

<style scoped lang="less">
.title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  >.icon {
    margin-right: .1rem;
    width: .8rem;
  }
  .title-en {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .48rem;
    color: var(--titleColor);
  }
  .title-cn {
    margin-right: .2rem;
    font-size: .5rem;
    font-weight: initial;
    color: #000000;
  }
}
</style>
