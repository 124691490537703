<template>
  <div class="form-info wow fadeIn" data-wow-delay="0.3s">
    <div class="text">
      药品不良反应报告
    </div>
    <div class="form-list">
      <div class="form-wrap">
        <template v-if="$store.state.isMedia">
          <table border="0" cellpadding="0" cellspacing="0" :width="tableWidth" class="table">
            <tbody>
            <tr>
              <td style="width:60px;" align="right"><span>*</span> 患者姓名：</td>
              <td colspan="2"><input type="text" v-model="subData.name" placeholder="请输入患者姓名"/><span class="Validform_checktip"></span></td>
            </tr>
            <tr>
              <td align="right">性别：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.sex" name="sex" value="男"> 男
                </label>
                <label>
                  <input type="radio" v-model="subData.sex" name="sex" value="女"> 女
                </label>
              </td>
            </tr>
            <tr>
              <td align="right">出生日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.time"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span> 药品名称：</td>
              <td colspan="2">
                <el-select v-model="subData.yp_name" placeholder="请选择药品名称">
                  <el-option
                    v-for="item in yp_name_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td align="right">产品批号：</td>
              <td colspan="2"><input type="text" v-model="subData.yp_number" placeholder="请输入产品批号"></td>
            </tr>
            <tr>
              <td align="right"> 生产企业：</td>
              <td colspan="2">
                <el-select v-model="subData.yp_unit" placeholder="请选择生产企业">
                  <el-option
                    v-for="item in yp_unit_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td align="right">用药原因：</td>
              <td colspan="2"><input type="text" v-model="subData.yp_yy" placeholder="请填写用药原因"></td>
            </tr>
            <tr>
              <td align="right">开始用药日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.yp_starttime"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始用药日期"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right">是否合并用药：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.hb" name="merge" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.hb" name="merge" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td align="right">不良反应发生日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.fy_starttime"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                    placeholder="请选择不良反应发生日期"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>发热（℃）：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="37.1-37.5"> 37.1-37.5
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="37.6-38.5"> 37.6-38.5
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="≥38.6"> ≥38.6
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="无"> 无
                </label>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span></td>
              <td colspan="2">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox label="皮肤潮红">皮肤潮红</el-checkbox>
                  <el-checkbox label="瘙痒">瘙痒</el-checkbox>
                  <el-checkbox label="水肿">水肿</el-checkbox>
                  <el-checkbox label="胸闷">胸闷</el-checkbox>
                  <el-checkbox label="恶心">恶心</el-checkbox>
                  <el-checkbox label="无">无</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>其他：</td>
              <td colspan="2"><input type="text" v-model="subData.fy_count" placeholder="如上述不适合，请填写"></td>
            </tr>
            <tr>
              <td align="right">是否就医：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.fy_jy" name="merge_1" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_jy" name="merge_1" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>报告人：</td>
              <td colspan="2"><input type="text" v-model="subData.contacts" placeholder="请填写报告人"></td>
            </tr>
            <tr>
              <td align="right">报告者职业：</td>
              <td colspan="2">
                <el-select v-model="subData.contacts_zhiye" placeholder="请选择报告者职业">
                  <el-option
                    v-for="item in contacts_zhiye_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td rowspan="3"  style="width:60px;" align="right">是否希望我们与您取得联系：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.contacts_ok" name="merge_2" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.contacts_ok" name="merge_2" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td style="width:80px;" align="right">电子邮箱：</td>
              <td><input type="text" v-model="subData.contacts_mobile" placeholder="请输入电子邮箱"></td>
            </tr>
            <tr>
              <td style="width:80px;" align="right">联系方式：</td>
              <td><input type="text" v-model="subData.contacts_email" placeholder="请输入联系方式"></td>
            </tr>
            <tr>
              <td align="right"><span>*</span>验证码：</td>
              <td colspan="2">
                <div style="display:flex;">
                  <div style="">
                    <input type="text" placeholder="请填写验证码" v-model="inputCode" />
                  </div>
                  <SecurityCode :identifyCode="identifyCode" @click.native="makeCode"></SecurityCode>
                </div>
              </td>
            </tr>

            </tbody>
          </table>
        </template>
        <template v-else>
          <table border="0" cellpadding="0" cellspacing="0" class="table">
            <tbody>
            <!--<tr>
              <td colspan="6">患者信息</td>
            </tr>
            -->
            <tr>
              <td style="width:60px;" align="right"><span>*</span> 患者姓名：</td>
              <td colspan="2"><input type="text" v-model="subData.name" colspan="4" placeholder="请输入患者姓名"/><span class="Validform_checktip"></span></td>
            </tr>
            <tr>
              <td align="right">性别：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.sex" name="sex" value="男"> 男
                </label>
                <label>
                  <input type="radio" v-model="subData.sex" name="sex" value="女"> 女
                </label>
              </td>
            </tr>
            <tr>
              <td align="right">出生日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.time"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                    placeholder="请输入患者出生日期"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span> 药品名称：</td>
              <td colspan="2">
                <el-select v-model="subData.yp_name" placeholder="请选择药品名称">
                  <el-option
                    v-for="item in yp_name_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td align="right">产品批号：</td>
              <td colspan="2"><input type="text" v-model="subData.yp_number" placeholder="请输入产品批号"></td>
            </tr>
            <tr>
              <td align="right"> 生产企业：</td>
              <td colspan="2">
                <el-select v-model="subData.yp_unit" placeholder="请选择生产企业">
                  <el-option
                    v-for="item in yp_unit_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td align="right">用药原因：</td>
              <td colspan="2"><input type="text" v-model="subData.yp_yy" placeholder="请填写用药原因"></td>
            </tr>
            <tr>
              <td align="right">开始用药日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.yp_starttime"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                    placeholder="请选择开始用药日期"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right">是否合并用药：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.hb" name="merge" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.hb" name="merge" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td align="right">不良反应发生日期：</td>
              <td colspan="2">
                <el-date-picker
                    v-model="subData.fy_starttime"
                    type="date"
                    clearable
                    value-format="yyyy-MM-dd"
                    placeholder="请选择不良反应发生日期"
                >
                </el-date-picker>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>发热（℃）：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="37.1-37.5"> 37.1-37.5
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="37.6-38.5"> 37.6-38.5
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="≥38.6"> ≥38.6
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_fare" name="fare" value="无"> 无
                </label>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span></td>
              <td colspan="2">
                <el-checkbox-group v-model="checkList">
                  <el-checkbox label="皮肤潮红">皮肤潮红</el-checkbox>
                  <el-checkbox label="瘙痒">瘙痒</el-checkbox>
                  <el-checkbox label="水肿">水肿</el-checkbox>
                  <el-checkbox label="胸闷">胸闷</el-checkbox>
                  <el-checkbox label="恶心">恶心</el-checkbox>
                  <el-checkbox label="无">无</el-checkbox>
                </el-checkbox-group>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>其他：</td>
              <td colspan="2"><input type="text" v-model="subData.fy_count" placeholder="如上述不适合，请填写"></td>
            </tr>
            <tr>
              <td align="right">是否就医：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.fy_jy" name="merge_1" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.fy_jy" name="merge_1" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td align="right"><span>*</span>报告人：</td>
              <td colspan="2"><input type="text" v-model="subData.contacts" placeholder="请填写报告人"></td>
            </tr>
            <tr>
              <td align="right">报告者职业：</td>
              <td colspan="2">
                <el-select v-model="subData.contacts_zhiye" placeholder="请选择报告者职业">
                  <el-option
                    v-for="item in contacts_zhiye_opt"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </td>
            </tr>
            <tr>
              <td rowspan="3" align="right">是否希望我们与您取得联系：</td>
              <td colspan="2">
                <label>
                  <input type="radio" v-model="subData.contacts_ok" name="merge_2" value="是"> 是
                </label>
                <label>
                  <input type="radio" v-model="subData.contacts_ok" name="merge_2" value="否"> 否
                </label>
              </td>
            </tr>
            <tr>
              <td style="width:80px;" align="right">电子邮箱：</td>
              <td><input type="text" v-model="subData.contacts_mobile" placeholder="请输入电子邮箱"></td>
            </tr>
            <tr>
              <td style="width:80px;" align="right">联系方式：</td>
              <td><input type="text" v-model="subData.contacts_email" placeholder="请输入联系方式"></td>
            </tr>
            <tr>
              <td align="right"><span>*</span>验证码：</td>
              <td colspan="2">
                <div style="display:flex;">
                  <div style="">
                    <input type="text" placeholder="请填写验证码" v-model="inputCode" />
                  </div>
                  <SecurityCode :identifyCode="identifyCode" @click.native="makeCode"></SecurityCode>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </template>
        <div class="tisp">
          注：<span style="color: red">*</span>为必填项目。
        </div>
        <div class="btn-list">
          <button class="submit btn" @click="handleSub">提交</button>
          <button class="reset btn" @click="reset">重置</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityCode from "@/components/securityCode";

export default {
  components: {
    //注册
    SecurityCode,
  },
  data() {
    return {
      identifyCode: "", //随机组合字符串
      inputCode: "", //text框输入的验证码
      subData: {
        name: '',
        sex: '',
        mobile: '',
        time: '',
        hzjb: '',
        jwyp: '',
        jwypText: '',
        gms: '',
        gmsText: '',
        contacts: '',
        contacts_mobile: '',
        contacts_email: '',
        contacts_ok: '',
        contacts_zhiye: '',
        yp_name: '',
        yp_number: '',
        yp_unit: '',
        yp_yy: '',
        yp_starttime: '',
        yp_endtime: '',
        hb: '',
        hb_name: '',
        hb_number: '',
        hb_starttime: '',
        hb_endtime: '',
        fy_name: '',
        fy_address: '',
        fy_starttime: '',
        fy_endtime: '',
        fy_count: '',
        fy_jy: '',
        fy_fare: '',
        fy_list: '',      //不适症状列表
      },
      code: '',
      regCode: '',
      time: 0,
      tableWidth: 0,
      yp_name_opt:[
        {
          value: '马破伤风免疫球蛋白F(ab′)₂',
          label: '马破伤风免疫球蛋白F(ab′)₂',
        },{
          value: '其他',
          label: '其他',
        }
      ],
      yp_unit_opt:[
        {
          value: '玉溪九洲生物技术有限责任公司',
          label: '玉溪九洲生物技术有限责任公司',
        },{
          value: '其他',
          label: '其他',
        }
      ],
      contacts_zhiye_opt:[
        {
          value: '患者本人',
          label: '患者本人',
        },{
          value: '患者家属',
          label: '患者家属',
        },{
          value: '医疗专业人士',
          label: '医疗专业人士',
        },{
          value: '其他',
          label: '其他',
        }
      ],
      checkList: [],
    };
  },
  mounted() {
    this.getW();
    this.refreshCode();
  },
  methods: {
    //初始化验证码
    refreshCode() {
      this.inputCode = ""; //输入框
      this.identifyCode = ""; //生成的验证码
      this.makeCode();
    },
    //随机切换验证码
    makeCode() {
      let code = "";
      let length = 4; //验证码长度为4
      for (let i = 0; i < length; i++) {
        const r = Math.floor(Math.random() * 36);
        if (r < 10) {
          code += r;
        } else {
          code += String.fromCharCode(r - 10 + 65);
        }
      }
      this.identifyCode = code;
      console.log(this.identifyCode);
    },
    handleSub() {
      // 不区分大小写
      const code = this.identifyCode.toLowerCase(); //全部转为小写
      const iptStr = this.inputCode.toLowerCase(); //全部转为小写

      if (!iptStr) {
        alert("请输入验证码");
      } else if (iptStr !== code) {
        alert("验证码输入错误！");
        this.makeCode();
      } else {
        alert("验证成功");
      }
    },
    getW() {
      if (this.$store.state.isMedia) {
        const fontSize = document.getElementsByTagName('html')[0].style.fontSize.replace('px', '');
        if (!fontSize) {
          requestAnimationFrame(this.getW);
        }
        const sum = fontSize * 0.4 * 2;
        this.tableWidth = (window.screen.availWidth - sum) + 'px';
      } else {
        this.tableWidth = '100%';
      }
    },
    async handleSub() {
      let title = '';
      let fy_list = '';
      if(this.checkList.length > 0){
        for (var i = 0; i < this.checkList.length; i ++){
          if(fy_list == ''){
            fy_list = this.checkList[i];
          }else{
            fy_list += ',' + this.checkList[i];
          }
        }
      }
      this.subData.fy_list = fy_list;
      const obj = this.$utils.deepClone(this.subData);
      if (!obj.name) {
        title = '请填写患者姓名';
      } else if (!obj.yp_name) {
        title = '请填写药品名称';
      } else if (!obj.fy_fare) {
        title = '请选择发热情况';
      } else if (this.checkList.length == 0 && !obj.fy_count) {
        title = '请选择或填写不适症状';
      } else if (!obj.contacts) {
        title = '请填写报告人';
      }

      var nowDate = new Date();
      if(obj.time){
        const time_date = new Date(obj.time).getTime();
        if(time_date > nowDate){
          title = '出生日期不能超过当前日期';
        }
      }
      if(obj.yp_starttime){
        const yp_date = new Date(obj.yp_starttime).getTime();
        if(yp_date > nowDate){
          title = '开始用药日期不能超过当前日期';
        }
      }
      if(obj.fy_starttime){
        const fy_date = new Date(obj.fy_starttime).getTime();
        if(fy_date > nowDate){
          title = '不良反应发生日期不能超过当前日期';
        }
        if(obj.yp_starttime){
          const yp_date_2 = new Date(obj.yp_starttime).getTime();
          if(fy_date < yp_date_2){
            title = '不良反应发生日期不能小于开始用药日期';
          }
        }
      }


      //判断验证码
      // 不区分大小写
      const code = this.identifyCode.toLowerCase(); //全部转为小写
      const iptStr = this.inputCode.toLowerCase(); //全部转为小写

      if (!iptStr) {
        title = "请填写验证码";
      } else if (iptStr !== code) {
        title = "验证码输入错误！";
        this.makeCode();
      }

      obj.jwyp = obj.jwypText;
      obj.gms += obj.gmsText;
      delete obj.jwypText;
      delete obj.gmsText;
      //第二层判断
      if (title) {
        return this.$message(title);
      }
      const res = await this.$api.message.message({
        type: 6,
        data: obj
      });
      if (res.code !== 1) return this.$message(res.msg);
      this.$message({
        type: 'success',
        message: '提交成功'
      });
    },
    reset() {
      for (let key in this.subData) {
        this.subData[key] = '';
      }
    },
    async sendCode() {
      if (this.time > 0) return;
      if (!this.subData.contacts_mobile) {
        return this.$message.error('请填写联系电话');
      }
      this.time = 60;
      const timer = setInterval(() => {
        this.time--;
        if (this.time < 1) {
          clearInterval(timer);
        }
      }, 1000);
      const res = await this.$api.message.getCode({
        mobile: this.subData.contacts_mobile
      });
      if (res.code !== 1) return this.$message(res.msg);
      this.regCode = res.data.code;
      this.$message.success('验证码已发送');
    }
  }
};
</script>

<style lang="less" scoped>
.form-info {
  > .text {
    margin-top: .2rem;
    line-height: .35rem;

    > .red {
      color: #FF0404;
    }
  }

  > .form-list {
    > .form-wrap {
      > .table {
        width: 100%;
        border: 1px solid #d7d7d7;
        margin-bottom: 30px;

        input[type=text] {
          border: 1px solid #dedede;
          padding: .04rem .02rem;
          font-size: .16rem;
          width: 90%;
        }

        td {
          padding: .08rem .1rem;
          border-top: 1px solid #d7d7d7;
          color: #444;
          font-size: .18rem;
          white-space: nowrap;
          word-wrap: break-word;

          span {
            color: red;
          }

          .code-btn {
            margin-left: .15rem;
            background-color: #0089FF;
            border-radius: .3rem;
            padding: .08rem .14rem;
            color: #fff;
            cursor: pointer;
          }

          /deep/ .el-input__inner {
            width: 100%;
            height: .38rem;
          }

          /deep/ .el-input__icon {
            line-height: .38rem;
          }
        }

        tr {
          &:first-child {
            > td {
              border: none;
              background: #f8f8f8;
            }
          }
        }
      }

      > .btn-list {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
          width: 1.4rem;
          height: .45rem;
          text-align: center;
          border-radius: .25rem;
          background-image: linear-gradient(to right, #0089FF, #00C2BC);
          font-size: .22rem;
          color: white;
          border: none;
          outline: none;
          cursor: pointer;
          line-height: .45rem;
          margin-right: .7rem;
        }
      }
    }
  }
}
</style>
