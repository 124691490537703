var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-info"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"product-swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.cProduct),function(productItem,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"product-img-wrap wow fadeIn"},[_c('img',{staticClass:"product-img",attrs:{"src":productItem.indeximg}})]),_c('div',{staticClass:"info-wrap wow fadeInUp",attrs:{"data-wow-delay":"0s"}},[_vm._m(5,true),_c('div',{staticClass:"sub-title-wrap wow fadeInUp",attrs:{"data-wow-delay":"0.3s"}},[_c('h3',{staticClass:"sub-title"},[_vm._v(_vm._s(productItem.title))])]),_c('div',{staticClass:"desc-wrap wow fadeInUp",attrs:{"data-wow-delay":"0.6s"}},[_c('p',{staticClass:"desc more-ellipsis clamp-3"},[_vm._v(_vm._s(productItem.des))])]),_c('div',{staticClass:"more-btn btn wow fadeInUp",attrs:{"data-wow-delay":"0.6s"},on:{"click":function($event){return productItem.routerDetails(_vm.$route.query.p_index || 0)}}},[_c('div',{staticClass:"btn-text"},[_vm._v("了解更多")]),_vm._m(6,true)])])])}),0),_vm._m(7),_vm._m(8)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ball"},[_c('img',{staticClass:"ball-img-1 ball-img",attrs:{"src":"https://img.jozobio.com/img/lzy_test/home_ball_1.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ball"},[_c('img',{staticClass:"ball-img-2 ball-img",attrs:{"src":"https://img.jozobio.com/img/lzy_test/home_ball_2.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ball"},[_c('img',{staticClass:"ball-img-3 ball-img",attrs:{"src":"https://img.jozobio.com/img/lzy_test/home_ball_3.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ball"},[_c('img',{staticClass:"ball-img-4 ball-img",attrs:{"src":"https://img.jozobio.com/img/lzy_test/home_ball_4.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ball"},[_c('img',{staticClass:"ball-img-5 ball-img",attrs:{"src":"https://img.jozobio.com/img/lzy_test/home_ball_5.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-warp"},[_c('h2',{staticClass:"title"},[_vm._v("推荐产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-wrap"},[_c('img',{staticClass:"icon",attrs:{"src":"https://img.jozobio.com/img/home/home_product_more.png","alt":"了解更多"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-next wow fadeInRight"},[_c('img',{staticClass:"icon-button",attrs:{"src":"https://img.jozobio.com/img/home/home_product_next.png"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-button-prev wow fadeInLeft"},[_c('img',{staticClass:"icon-button",attrs:{"src":"https://img.jozobio.com/img/home/home_product_prev.png"}})])
}]

export { render, staticRenderFns }