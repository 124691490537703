import http from './http'

/**
 * 新闻中心接口
 */

export default {
  news(params) {
    return http.get('/index/news', {
      params
    });
  },
  newsDetails(params) {
    return http.get('/index/news_detail', {
      params
    });
  }
}
