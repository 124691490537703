import Vue from "vue";
import wow from 'wowjs';

export class UIData { //整个index页面Ui父类
  constructor(target) {
    for (let key in target) {
      this[key] = target[key];
    }
  }
  //路由跳转
  routerUrl(url = '', isTop = true) {
    url = this.url || url;
    if (!url) {
      return ;
    }
    if (isTop) {
      window.scroll(0, 0);
    }
    const params = this.getParams(url);
    Vue.prototype._router.push({
      path: url,
      query: params
    });
  }

  getParams(url) {
    const params = url.split('?')[1].split('&');
    const obj = {};
    params.forEach(param => {
      const keyValue = param.split('=');
      obj[keyValue[0]] = keyValue[1];
    });
    return obj;
  }
}

//wowjs入口
export class WowEntry {
  constructor() {
    //不能使用new来创建
    return null
  }
  //动画开始
  static animationStart() {
    if (!document.querySelectorAll) {
      return this.$message({type: 'error', message: '当前浏览器不支持滚动动画'})
    }
    new wow.WOW({
      live: false
    }).init();
  }
}
