<template>
  <div class="index">
    <swiper-banner ref="banner"></swiper-banner>
    <anchor-list></anchor-list>
    <about-info></about-info>
    <research-info></research-info>
    <product-info></product-info>
    <overview-info></overview-info>
    <news-info></news-info>
    <join-info></join-info>
  </div>
</template>

<script>
import SwiperBanner from "./components/swiper-banner.vue";
import AnchorList from "@/views/index/components/anchor-list.vue";
import AboutInfo from "@/views/index/components/about-info.vue";
import ResearchInfo from "@/views/index/components/research-info.vue";
import ProductInfo from "@/views/index/components/product-info.vue";
import OverviewInfo from "@/views/index/components/overview-info.vue";
import NewsInfo from "@/views/index/components/news-info.vue";
import JoinInfo from "@/views/index/components/join-info.vue";
import GuidePage from "@/views/index/components/guide-page.vue";
export default {
  name: 'Index',
  components: {
    SwiperBanner,
    AnchorList,
    AboutInfo,
    ResearchInfo,
    ProductInfo,
    OverviewInfo,
    NewsInfo,
    JoinInfo,
    GuidePage
  },
  data() {
    return {
      wheelTimer: null,
      scrollTimer: null,
      topWheelCount: 0 //滚动次数
    };
  },
  computed: {
    isGuide() {
      return this.$store.state.isGuide;
    }
  }
};
</script>

<style lang="less" scoped>

</style>
