<template>
  <div class="media-info">
    <div class="top-info">
        <t-title-info label="Media Center" title="媒体中心" :listInfo="navList"></t-title-info>
    </div>
    <div class="content-info">
      <div class="swiper-container media-swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in cMedias"
            :key="item.id"
          >
            <div class="item wow fadeInDown" :data-wow-delay="`${0.2 * (index * 1)}s`">
              <div class="img-wrap" @click.stop="handlePlay(item)">
                <img :src="item.image" alt="" class="img">
                <img src="https://img.jozobio.com/img/common/icon_play_white.png" alt="" class="img">
              </div>
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="video-wrap" v-show="video.show">
      <div class="info-wrap">
        <video ref="video" :src="video.url" class="video" controls></video>
        <div class="close" @click.stop="handleClose">
          <div class="item">
            <div class="v"></div>
            <div class="h"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper';
export default {
  data() {
    return {
      navList: [{
        id: 1,
        name: '公司相关视频'
      }],
      video: {
        show: false,
        url: 'https://video.699pic.com/videos/26/53/96/a_6548bb746d81116992653964504.mp4'
      }
    };
  },
  mounted() {
    let viewNum = 4;
    let gap = 30;
    if (this.$store.state.isMedia) {
      viewNum = 2;
      gap = 20;
    }
    new Swiper('.media-swiper-container', {
      slidesPerView: viewNum,
      spaceBetween: gap,
      speed: 5000,
      autoplay: { //自动开始
        delay: 0, //时间间隔
        disableOnInteraction: false, //*手动操作轮播图后不会暂停*
      },
      observer: true,
      observeParents: true,
      loop: true, // 循环模式选项
    })
  },
  computed: {
    cMedias() {
      return this.$store.state.profile.profileInfo.video || []
    }
  },
  methods: {
    handlePlay(item) {
      this.video.show = true;
      this.video.url = item.file;
      this.$nextTick(() => {
        this.$refs.video.play();
      })
    },
    handleClose() {
      this.video.show = false;
      this.$nextTick(() => {
        this.$refs.video.pause();
      })
    }
  }
};
</script>

<style lang="less" scoped>
  .media-info {
    padding: 1rem var(--wrapper) .8rem;
    >.top-info {
      position: relative;
    }
    >.content-info {
      margin-top: .5rem;
      .swiper-container {
        .swiper-wrapper {
          transition-timing-function: linear !important;
          .item {
            background-color: #fff;
            >.img-wrap {
              position: relative;
              border-radius: .05rem;
              overflow: hidden;
              cursor: pointer;
              >.img:first-child {
                width: 100%;
                transition: .2s;
              }
              >.img:last-child {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: .4rem;
              }
            }
            >.title {
              padding: .15rem;
              text-align: center;
            }
            &:hover {
              >.img-wrap {
                >.img:first-child {
                  transform: scale(1.1);
                }
              }
            }
          }
        }
      }
    }
    >.video-wrap {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 999;
      >.info-wrap {
        position: relative;
        >.video {
          height: 50vh;
          box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
        }
        >.close {
            position: absolute;
            top: -.17rem;
            right: -.15rem;
            cursor: pointer;
          >.item {
            position: relative;
            width: .35rem;
            height: .35rem;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .4);
            display: flex;
            align-items: center;
            justify-content: center;
            >.v {
              position: absolute;
              width: .17rem;
              height: .03rem;
              background-color: #fff;
              transform: rotate(45deg);
            }
            >.h {
              position: absolute;
              width: .17rem;
              height: .03rem;
              background-color: #fff;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }
</style>
