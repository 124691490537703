<template>
  <div class="html-text">
    <div class="text" v-html="cWorkbench.content"></div>
  </div>
</template>

<script>
export default {
  computed: {
    cWorkbench() {
      return this.$store.state.exploit.exploitInfo.workbench[0] || {};
    }
  }
}
</script>

<style scoped lang="less">
.html-text {
  padding: 0 1.8rem;
  margin-bottom: .3rem;
}
</style>
