<template>
  <div class="people-info">
    <div class="top">
      <div class="left">
        <border-title :titles="cTitles"></border-title>
        <div class="text">
          {{ cETitle[0] }}<br/>
          {{ cETitle[1] }}
        </div>
      </div>
      <div class="right wow fadeInRight" data-wow-delay="0.5s">
        <div class="title">参加马破免III期临床试验的研究机构</div>
        <div class="table-list">
          <el-table
              :data="cTableOne"
              stripe
              style="width: 50%;">
            <el-table-column
                prop="num"
                label="中心号"
                :width="$store.state.isMedia ? 'auto' : 180"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="unit"
                label="单位名称"
                width="auto"
                align="center"
            >
            </el-table-column>
          </el-table>
          <el-table
              :data="cTableTwo"
              stripe
              style="width: 50%;">
            <el-table-column
                prop="num"
                label="中心号"
                :width="$store.state.isMedia ? 'auto' : 180"
                align="center"
            >
            </el-table-column>
            <el-table-column
                prop="unit"
                label="单位名称"
                width="auto"
                align="center"
            >
            </el-table-column>
          </el-table>
        </div>

      </div>
    </div>
    <div class="bottom">
      <label-info></label-info>
    </div>
  </div>
</template>

<script>
import BorderTitle from './BorderTitle.vue';
import LabelInfo from './labelInfo.vue';
export default {
  components: { BorderTitle, LabelInfo },
  data() {
    return {
    };
  },
  computed: {
    cPartner() {
      return this.$store.state.exploit.exploitInfo.partner || [];
    },
    cTitles() {
      return this.cPartner[1]?.title.split(',') || [];
    },
    cETitle(){
      return this.cPartner[1]?.e_title.split(',') || [];
    },
    cTableOne() {
      const table = this.cPartner[1]?.json.slice(0, this.cPartner[1]?.json.length / 2);
      return table;
    },
    cTableTwo() {
      const table = this.cPartner[1]?.json.slice(this.cPartner[1]?.json.length / 2);
      return table;
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
/deep/ .has-gutter {
  background: rgba(251, 247, 246, 1);
  tr {
    background-color: transparent;
  }
}
/deep/ tr.el-table__row--striped td.el-table__cell {
  background: rgba(219, 235, 248, 1) !important;
}
/deep/ th.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table thead {
  color: #000;
}
/deep/ .el-table {

}
/deep/ .el-table__cell {
  padding: .2rem 0;
}
  .people-info {
    padding: 0 1.8rem;
    >.top {
      display: flex;
      >.left {
        width: 4rem;
        >.text {
          margin-left: .18rem;
          margin-top: .4rem;
          color: #696969;
        }
      }
      >.right {
        width: calc(100% - 4rem);
        >.title {
          background: linear-gradient(90deg, #0189FF, #00C4BA);
          font-size: .28rem;
          color: #fff;
          line-height: .6rem;
          text-align: center;
        }
        >.table-list {
          display: flex;
        }
      }
    }
  }
</style>
