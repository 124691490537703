import Vue from "vue";
import {Message} from "@/views/message/target/message";
//信息公示模块
export default {
	namespaced: true,
  state: {
		nav: {},
		messageInfo: {
			companyProduct: [], //公司产品信息
			specs: [], //包装
			recall: [], //召回信息
			standard: {}, //公司质量管理规范执行情况
			record: [], //配送商备案信信息
		}
	},
	mutations: {
		setNav(state, payload) {
			state.nav = payload || {};
		},
		setMessageInfo(state, payload) {
			state.messageInfo[payload.key] = payload.data || payload.default;
		}
	},
	actions: {
		message({commit}, params) {
			return new Promise( async (resolve, reject) => {
				const res = await Vue.prototype.$api.message.message(params);
				if (res.code !== 1) return reject();
				const param = {
					key: '',
					default: []
				};
				switch (params.type) {
					case 1:
						param.key = 'companyProduct';
						break;
					case 2:
						param.key = 'specs';
						break;
					case 3:
						param.key = 'recall';
						break;
					case 4:
						param.key = 'standard';
						param.default = {};
						break;
					case 5:
						param.key = 'record';
						break;
				}
				if (Array.isArray(res.data)) {
					param.data = res.data.map(v => new Message(v));
				} else {
					param.data = new Message(res.data);
				}
				commit('setMessageInfo',param);
				resolve();
			});
		}
	}
}
