import { render, staticRenderFns } from "./MapInfo.vue?vue&type=template&id=b5074b56&scoped=true"
import script from "./MapInfo.vue?vue&type=script&lang=js"
export * from "./MapInfo.vue?vue&type=script&lang=js"
import style0 from "./MapInfo.vue?vue&type=style&index=0&id=b5074b56&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5074b56",
  null
  
)

export default component.exports