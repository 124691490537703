<template>
  <div class="achievement">
    <t-title-info
      margin=".5rem 1.8rem"
      :label="navInfo.english_title"
      :title="navInfo.title"
      labelColor="#000"
      :signRight="false"
      :iconShow="true"
      :listInfo="navList"
      :index="navIndex"
      @returnVal="retTitle"
    ></t-title-info>
    <text-info :text="cAchievement.title"></text-info>
    <template v-if="navIndex == 0">
      <table-info></table-info>
    </template>
    <template v-else-if="navIndex == 1">
      <swiper-info></swiper-info>
    </template>
<!--    <template v-else-if="navIndex == 2">-->
<!--      <platform></platform>-->
<!--    </template>-->
  </div>
</template>

<script>
import SwiperInfo from './childComps/SwiperInfo.vue';
import TableInfo from './childComps/TableInfo.vue';
import TextInfo from './childComps/TextInfo.vue';
import Platform from "@/views/exploit/achievement/childComps/platform.vue";
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: { TextInfo, TableInfo, SwiperInfo, Platform },
  data() {
    return {
      navIndex: 0,
      navList: [
        {
          id: 1,
          name: '科技专项'
        },
        {
          id: 2,
          name: '专利'
        },
        // {
        //   id: 3,
        //   name: '研发平台'
        // }
      ],
      timer: null
    };
  },
  computed: {
    cAchievement() {
      return this.$store.state.exploit.exploitInfo.achievement || {};
    }
  },
  mounted() {
    if (this.$route.query.a_index) {
      this.navIndex = +this.$route.query.a_index;
      this.retTitle(+this.$route.query.a_index);
    }
  },
  methods: {
    init() {
      for(let i = 0; i < this.navList.length; i++) {
        if(i == this.navIndex) {
          this.navList[i].name = `${this.navList[i].name.split(' ')[0]} •`;
        }else {
          this.navList[i].name = `${this.navList[i].name.split(' ')[0]}`;
        }
      }
    },
    retTitle(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        this.$store.dispatch('exploit/exploit', {type: 2, l: index + 1});
        this.init();
        this.$nextTick(() => {
          this.$store.commit('initWoW');
        });
      }, 300);
    }
  },
};
</script>

<style lang="less" scoped>

</style>
