<template>
  <div class="base-info">
    <div class="sign"></div>
    <div class="sign"></div>
    <img src="https://img.jozobio.com/img/profile/img_02.png" alt="" class="img">
    <div class="content-info">
      <t-title-info
        label="Production Base"
        title="生产基地"
        labelColor="#fff"
        titleColor="#fff"
        alignItems="center">
      </t-title-info>
      <div class="info-wrap">
        <div class="info">
          <div class="left">
            <div class="swiper-container base-swiper-container">
              <div class="swiper-wrapper">
                <div
                    class="swiper-slide"
                    v-for="(item, index) in cBase.image?.split(',')"
                    :key="item"
                >
                  <img :src="item" alt="" class="img wow fadeIn" data-wow-delay="0.2s">
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
<!--            <img :src="cBase.image" alt="" class="img wow fadeIn" data-wow-delay="0.2s">-->
          </div>
          <div class="right">
            <div class="sign"></div>
            <div class="sign"></div>
            <div class="info-wrap">
              <div class="num wow fadeIn">{{ $utils.formatNumber(baseIndex + 1) }}</div>
              <div class="name wow fadeInUp" data-wow-delay="0.2s">{{ cBase.title }}</div>
              <div class="text more-ellipsis clamp-4 wow fadeInUp" data-wow-delay="0.2s">{{ cBase.e_title }}</div>
              <div class="border wow fadeIn" data-wow-delay="0.2s"></div>
              <div class="text wow fadeInUp" data-wow-delay="0.3s">{{ cBase.des }}</div>
              <div class="btn wow fadeInUp" data-wow-delay="0.4s" @click="cBase.routerDetails($route.query.p_index || 0)">
                查看详情
                <div class="arrows"></div>
              </div>
              <div class="num-wrap wow fadeIn" data-wow-delay="0.5s">
                <div class="item" @click="page('prev')">
                  <template v-if="baseIndex < 1">
                    <img src="https://img.jozobio.com/img/profile/icon_09.png" alt="" class="img">
                  </template>
                  <template v-else>
                    <img src="https://img.jozobio.com/img/profile/icon_09_active.jpg" alt="" class="img">
                  </template>
                </div>
                <div class="text">
                  {{ $utils.formatNumber(baseIndex + 1) }}<div class="text">/{{ $utils.formatNumber(cBases.length) }}</div>
                </div>
                <div class="item" @click="page('next')">
                  <template v-if="baseIndex >= cBases.length - 1">
                    <img src="https://img.jozobio.com/img/profile/icon_10.jpg" alt="" class="img">
                  </template>
                  <template v-else>
                    <img src="https://img.jozobio.com/img/profile/icon_10_active.png" alt="" class="img">
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";

export default {
  data() {
    return {
      baseIndex: 0,
      swiper: null
    };
  },
  computed: {
    cBases() {
      return this.$store.state.profile.profileInfo.base || [];
    },
    cBase() {
      return this.cBases[this.baseIndex] || {};
    }
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 1000);
  },
  methods: {
    page(type) {
      if (type === 'prev' && this.baseIndex < 1) return ;
      if (type === 'next' && this.baseIndex >= this.cBases.length - 1) return ;
      //上一页
      if (type === 'prev') {
        this.baseIndex--;
      }
      if (type === 'next') {
        this.baseIndex++;
      }
    },
    initSwiper() {
      if (!document.querySelector('.honor-swiper-container-row')) {
        requestAnimationFrame(this.initSwiper);
        return ;
      }
      this.swiper = new Swiper('.base-swiper-container', {
        slidesPerView: 1,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: 5000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 分页器可以点击
        },
      });
    }
  },
};
</script>

<style lang="less" scoped>
  .base-info {
    position: relative;
    overflow: hidden;
    >.img {
      width: 100%;
    }
    >.sign {
      position: absolute;
      border-radius: 50%;
      &:nth-child(1) {
        left: 1rem;
        top: 1.4rem;
        width: 1.2rem;
        height: 1.2rem;
        background-color: rgba(255, 255, 255, .1);
      }
      &:nth-child(2) {
        right: -.5rem;
        bottom: -.5rem;
        width: 2rem;
        height: 2rem;
        background-image: linear-gradient(rgba(255, 255, 255, .1), transparent);
      }
    }
    >.content-info {
      position: absolute;
      left: 1.5rem;
      top: .5rem;
      right: 1.5rem;
      bottom: .5rem;
      >.info-wrap {
        margin-top: .5rem;
        padding: 0 .8rem;
        >.info {
          display: flex;
          background-color: #fff;
          border-radius: .15rem .05rem .05rem .15rem;
          >.left {
            width: 8.78rem;
            >.base-swiper-container {
              width: 100%;
            }
            .img {
              width: 8.78rem;
              height: 6rem;
              border-radius: .05rem;
              object-fit: cover;
            }
          }
          >.right {
            position: relative;
            flex: 1;
            padding: .2rem .4rem .3rem;
            display: flex;
            align-items: center;
            >.sign {
              position: absolute;
              border-radius: 50%;
              &:nth-child(1) {
                top: .1rem;
                right: 0;
                width: 2rem;
                height: 2rem;
                background-color: #f4f6f9;
              }
              &:nth-child(2) {
                left: .2rem;
                margin-bottom: -.5rem;
                width: 1rem;
                height: 1rem;
                background-image: linear-gradient(120deg, #f4f6f9, transparent);
              }
            }
            >.info-wrap {
              position: relative;
              >.num {
                font-size: .9rem;
                font-family: Lucida Bright;
                color: #e6eaef;
              }
              >.name {
                margin-top: .1rem;
                font-size: .3rem;
                font-weight: bold;
                color: #0064C0;
              }
              >.text {
                line-height: .3rem;
              }
              >.border {
                margin: .15rem 0 .2rem;
                width: .5rem;
                border-bottom: solid 3px #0064C0;
              }
              >.btn {
                margin-top: .25rem;
                padding: .08rem .25rem;
                color: #fff;
                border-radius: .2rem .2rem 0 .2rem;
                background-image: linear-gradient(to right, #0079eb, #00c2bb);
                cursor: pointer;
                display: inline-flex;
                align-items: center;
                >.arrows {
                  margin-left: .04rem;
                  width: .08rem;
                  height: .08rem;
                  border: solid 1px #fff;
                  border-width: 0 1px 1px 0;
                  transform: rotate(-45deg);
                }
                &:hover {
                  box-shadow: 0 3px 5px rgba(0, 0, 0, .05);
                }
              }
              >.num-wrap {
                margin-top: .4rem;
                display: flex;
                align-items: center;
                >.item {
                  cursor: pointer;
                  >.img {
                    width: .5rem;
                  }
                  &:hover {
                    position: relative;
                    top: 1px;
                  }
                }
                >.text {
                  margin: 0 .1rem;
                  font-size: .22rem;
                  font-weight: bold;
                  display: flex;
                  align-items: flex-end;
                  >.text {
                    margin-bottom: .03rem;
                    color: #969696;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
