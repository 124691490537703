import renderList from '../recruitDetail/json/render.json';
import {
  UIData
} from "@/common/targer";
import Vue from "vue";
import store from "@/store";
export class We extends UIData {
  constructor(target) {
    super(target);
  }

  routerDetails(p) {
    Vue.prototype._router.push({
      path: '/we_info',
      query: {
        p_index: p,
        item: JSON.stringify(this)
      }
    });
    window.scroll(0, 0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}
export class ResumeForm {
  #renderForms //表单渲染数组
  constructor(options) {
    this.#renderForms = renderList.renderForms;
    if (isObject(options)) {
      for (const key in options) {
        this[key] = options[key];
      }
    }
    //初始化表单数据
    this.resumeForm = initForm.call(this);
  }

  get value() {
    return this.#renderForms;
  }

  //添加工作经历
  addWork() {
    this.#addForm('工作经历', 'addDefault', 'json');
  }
  //删除工作经历
  delWord() {
    this.#delForm('工作经历', 'addDefault', 'json');
  }

  //添加教育经历
  addEducation() {
    this.#addForm('教育经历', 'educationDefault', 'education_json');
  }
  //删除教育经历
  delEducation() {
    this.#delForm('教育经历', 'educationDefault', 'education_json')
  }

  #addForm(label, field, subField) {
    const formIndex = this.#renderForms.findIndex(v => v.label === label);
    if (formIndex < 0) return ;
    if (label === '教育经历' && this.#renderForms[formIndex].inputList.length >= 4) return Vue.prototype.$message({type: 'error', message: `最多填四个${label}`})
    const newInputItem = Vue.prototype.$utils.deepClone(this.#renderForms[formIndex].default);
    const newAddItem = Vue.prototype.$utils.deepClone(this[field]);
    this.#renderForms[formIndex].inputList.push(newInputItem);
    this.resumeForm[subField].push(newAddItem);
  }

  #delForm(label, field, subField) {
    const formIndex = this.#renderForms.findIndex(v => v.label === label);
    if (formIndex < 0) return ;
    //如果只剩一个 就不能删除
    if (this.#renderForms[formIndex].inputList.length <= 1) return Vue.prototype.$message({type: 'error', message: `必须填一个${label}`});
    this.#renderForms[formIndex].inputList.splice(this.#renderForms[formIndex].inputList.length - 1, 1);
    this.resumeForm[subField].splice(this.resumeForm[subField].length - 1, 1);
  }

  //提交简历
  submit(target) {
    const result =
      this.#submitValidate('教育经历', 'education_json') &&
      this.#submitValidate('工作经历', 'json');
    //如果result是字符串
    if (typeof result === 'string') return Vue.prototype.$message({type: 'error', message: result});
    //在校验element ui的表单
    target.$refs[this.ref].validate(async (valid) => {
      if (!valid) return false;
      //处理数据
      this.resumeForm.birth_time = Vue.prototype.$utils.setTime(this.resumeForm.birth_time, 'Y-M-D h:m:s');
      this.resumeForm.json = JSON.stringify(this.resumeForm.json);
      this.resumeForm.education_json = JSON.stringify(this.resumeForm.education_json);
      //调用接口
      const res = await Vue.prototype.$api.we.submitResume(this.resumeForm);
      if (res.code !== 1) return Vue.prototype.$message({type: 'error', message: res.msg});
      Vue.prototype.$message({type: 'success', message: '投递成功！'});
      target.close();
    });
  }

  //提交校验
  #submitValidate(label, subField) {
    const formItem = this.#renderForms.find(v => v.label === label);
    //获取不到参数
    if (!formItem) return '数据错误';
    //错误信息
    let errorMsg = '';
    formItem.default.forEach(input => {
      //逐个判断是否是符合规则
      input.rules.forEach(rule => {
        //是否必填
        if (rule.required) {
          this.resumeForm[subField].forEach(field => {
            if (!field[input.prop]) {
              errorMsg = `${input.label}不能为空`;
              return ;
            }
          });
        }
        if (errorMsg) return;
      });
      if (errorMsg) return;
    });
    return errorMsg ? errorMsg : true;
  }

  //校验手机号
  validateTel(rule, value, callback) {
    const reg = /^[1][3,4,5,6.7,8,9][0-9]{9}$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正确的手机号'));
    }
    callback();
  }

  //校验邮箱
  validateEmail(rule, value, callback) {
    const reg = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    if (!reg.test(value)) {
      return callback(new Error('请输入正确的邮箱'));
    }
    callback();
  }
}

function isObject(target) {
  return typeof target === 'object' && target !== null && target.__proto__ === Object.prototype;
}

function initForm() {
  if (!Array.isArray(this.value)) {
    return {};
  }
  const obj = {};
  this.value.forEach(label => {
    if (!Array.isArray(label.inputList)) {
      return ;
    }
    label.inputList.forEach(input => {
      if (isObject(input)) {
        //不是数组
        obj[input.prop] = '';
        //初始化规则校验
        input.rules.forEach(rule => {
          if (rule.validator) {
            rule.validator = this[rule.validator];
          }
        });
      }
      if (Array.isArray(input)) {
        obj[label.formField] = [];
        const item = input.reduce((target, next) => {
          target[next.prop] = ''
          return target;
        }, {});
        //深度克隆
        this[label.defaultField] = Vue.prototype.$utils.deepClone(item);
        obj[label.formField].push(item);
      }
    });
  });
  return obj;
}
