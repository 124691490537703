<template>
  <div class="header-info">
    <div class="thumb-list-wrap">
      <div class="active-thumb-wrap">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide wow fadeInLeft"
            :data-wow-delay="`${0.2 * (index * 1)}`"
            v-for="(url, index) in cImages"
            :key="`active-img-${index}`"
          >
            <div class="active-thumb-item">
              <img class="active-thumb-img" :src="url">
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div class="thumb-list">
        <div class="thumb-swiper-container">
          <div class="swiper-wrapper">
            <div
                class="swiper-slide wow fadeInLeft"
                v-for="(url, index) in cImages"
                :data-wow-delay="`${0.3 * (index * 1)}`"
                :key="`img-${index}`"
            >
              <div class="thumb-wrap">
                <img class="thumb" :src="url" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrap">
      <h2 class="product-title wow fadeInUp">{{ product.title }}</h2>
      <div class="advantage">
        <!-- <strong class="label wow fadeInUp">产品优势及核心技术：</strong> -->
        <strong class="label wow fadeInUp">{{cCons[0]}}</strong>
        <div class="advantage-list">
          <p
            class="advantage-item wow fadeInUp"
            data-wow-delay="0.3s"
          >{{ cCons[1] }}</p>
        </div>
        <div class="label-title">
          <strong class="label wow fadeInUp" data-wow-delay="0.8s">{{cCons[2]}}</strong>
        </div>
           <div class="advantage-list">
          <p
            class="advantage-item wow fadeInUp"
            data-wow-delay="1s"
          >
            <template v-for="(item, index) in cCons">
              <span v-if="index > 2" :key="index">{{ item }}</span>
            </template>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
export default {
  data() {
    return {
      swiper: null,
      swiperActive: null,
      product: {}
    }
  },
  created() {
    this.product = JSON.parse(this.$route.query.item) || {};
  },
  mounted() {
    this.initSwiper();
  },
  computed: {
    cImages() {
      return this.product.images?.split(',') || [];
    },
    cCons() {
      return this.product.con?.replace(/\r\n/g, ' ').split(' ').filter(v => v) || [];
    }
  },
  methods: {
    initSwiper() {
      let viewNum = 3;
      let gap = 30;
      if (this.$store.state.isMedia) {
        viewNum = 2;
        gap = 10
      }
      this.swiper = new Swiper('.thumb-swiper-container', {
        slidesPerView: viewNum,
        spaceBetween: gap,
        observer: true,
        observeParents: true,
        watchSlidesProgress: true,
        freeMode: true
      });
      this.swiperActive = new Swiper('.active-thumb-wrap', {
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: this.swiper,
        },
      });
    }
  }
}
</script>

<style scoped>
.header-info {
  display: flex;
  padding: .94rem 0 .9rem;
}
.thumb-list-wrap {
  width: 6.9rem;
}
.active-thumb-wrap {
  position: relative;
  margin-bottom: .2rem;
  overflow-x: hidden;
}
.active-thumb-img {
  width: 100%;
  border-radius: .1rem;
}
.thumb-swiper-container {
  overflow: hidden;
}
.swiper-slide {
  cursor: pointer;
}
.thumb {
  width: 2.09rem;
  border-radius: .1rem;
}

.info-wrap {
  flex: 1;
  padding-left: .85rem;
}
.product-title {
  margin-top: .75rem;
  padding-bottom: .25rem;
  border-bottom: .01rem dashed #9E9D9D;
  font-size: .3rem;
  font-weight: initial;
  color: #010101;
}
.advantage {
  margin-top: .4rem;
}
.label {
  font-size: .24rem;
  color: #0264D2;
}
.label-title {
  margin-top: .2rem;
}
.label-title {
  font-size: .17rem;
  color: #0264D2;
}
.advantage-list {
  margin: .1rem 0;
}
.advantage-item {
  font-size: .17rem;
  line-height: .28rem;
  color: rgba(200, 200, 200, .9);
}
</style>
