import {UIData} from "@/common/targer";
import Vue from "vue";
import store from "@/store";

export class NewsNav extends UIData {
  constructor(target, array) {
    super(target);
    const obj = {
      firstItem: array[0]
    }
    array.splice(0, 1);
    obj['list'] = array;
    this['data'] = obj;
  }

  //切换新闻
  toggleNews(target, index) {
    target.navIndex = index;
  }
}
export class News extends UIData {
  constructor(target) {
    super(target);
  }

  routerDetails(p) {
    Vue.prototype._router.push({
      path: `/news_details/${this.id}`,
      query: {
        p_index: p
      }
    });
    window.scroll(0,0);
    Vue.prototype.$nextTick(() => {
      store.commit('initWoW');
    });
  }
}
