<template>
  <div class="exploit-info">
    <div class="top">
      <div class="left">
        <border-title :titles="cTitles"></border-title>
      </div>
    </div>
    <div class="center">
      <div class="left wow fadeIn" data-wow-delay="0.3s">
        {{ cETitle[0] }}<br/>
        {{ cETitle[1] }}
      </div>
      <div class="right">
        <div
          class="item wow fadeInRight"
          :data-wow-delay="`${0.3 * (index * 1)}s`"
          v-for="(image, index) in cPartner[0]?.images"
          :key="`image-${index}`"
        >
          <img :src="image" alt="" class="img">
        </div>
      </div>
    </div>
    <div class="bottom">
      <label-info></label-info>
    </div>
  </div>
</template>

<script>
import BorderTitle from './BorderTitle.vue';
import LabelInfo from './labelInfo.vue';
export default {
  components: { BorderTitle, LabelInfo },
  data() {
    return {

    };
  },
  computed: {
    cPartner() {
      return this.$store.state.exploit.exploitInfo.partner || [];
    },
    cTitles() {
      return this.cPartner[0]?.title.split(',') || [];
    },
    cETitle(){
      return this.cPartner[0]?.e_title.split(',') || [];
    }
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .exploit-info {
    padding: .5rem 1.8rem;
    >.top,
    >.center {
      display: flex;
      align-items: flex-start;
      >.left {
        width: 4rem;
      }
      >.right {
        flex: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        >.item {
          margin-right: .2rem;
          >.img {
            height: .9rem;
          }
        }
      }
    }
    >.center {
      margin-left: .18rem;
      margin-top: .4rem;
      >.left {
        color: #696969;
      }
      >.right {
        >.item {
          >.img {
            height: .8rem;
          }
        }
      }
    }
  }
</style>
