import http from './http'

/**
 * 信息公示接口
 */

export default {
  we: (params) => {
    return http.get('/index/join', {
      params
    });
  },
  submitResume(params) {
    return http.post('/index/online', params);
  },
  subPDF(params) {
    return http.post('/index/uploadresume', params);
  }
}
