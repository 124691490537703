<template>
  <div class="product-info">
    <div class="table-wrap">
      <div class="title wow fadeInUp" data-wow-delay="0.3s">公司上市产品信息</div>
      <div class="table-info wow fadeInUp" data-wow-delay="0.5s">
        <el-table
          :data="cCompanyProduct"
          stripe
          :border="true"
          :style="{width: tableWidth}">
          <el-table-column
            prop="name"
            label="产品名称"
            align="center"
            :width="$store.state.isMedia ? 'auto' : ''">
          </el-table-column>
          <el-table-column
            prop="type"
            label="剂型"
            align="center"
            :width="$store.state.isMedia ? 'auto' : ''">
          </el-table-column>
          <el-table-column
            prop="specs"
            label="规格"
            align="center"
            :width="$store.state.isMedia ? 'auto' : ''">
          </el-table-column>
          <el-table-column
            prop="number"
            label="批准文号"
            align="center"
            :width="$store.state.isMedia ? 'auto' : ''">
          </el-table-column>
          <el-table-column
            prop="content"
            align="center"
            label="产品介绍">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableWidth: '100%'
    }
  },
  mounted() {
    this.getW();
  },
  computed: {
    cCompanyProduct() {
      return this.$store.state.message.messageInfo.companyProduct || [];
    },

  },
  methods: {
    getW() {
      if (this.$store.state.isMedia) {
        const fontSize = document.getElementsByTagName('html')[0].style.fontSize.replace('px', '');
        if (!fontSize) {
          requestAnimationFrame(this.getW);
        }
        const sum = fontSize * 0.4 * 2;
        this.tableWidth = (window.innerWidth - sum) + 'px';
      } else {
        this.tableWidth = '100%';
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .has-gutter {
  background: rgba(251, 247, 246, 1);
  tr {
    height: .92rem;
    background-color: transparent;
  }
}
/deep/ th.el-table__cell {
  background-color: transparent;
}
/deep/ .el-table thead {
  color: #000;
}
/deep/ .el-table td {
  background-color: rgba(220, 235, 246, 1);
}
.product-info {
  >.table-wrap {
    >.title {
      padding: .05rem 0;
      background: linear-gradient(90deg, #0189FF, #00C4BA);
      text-align: center;
      font-size: .24rem;
      color: #fff;
    }
  }
 }
</style>
