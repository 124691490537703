<template>
  <div class="top-info">
    <div class="left">
      <t-title-info
        :label="cNavInfo.english_title"
        :title="cNavInfo.title"
        :iconShow="true"
        labelColor="#000"
        :signRight="false">
      </t-title-info>
    </div>
    <div class="right wow fadeInUp">
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemoTopInfo',

  data() {
    return {

    };
  },

  computed: {
    cNavInfo() {
      return this.$store.state.message.nav || {};
    },
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
  .top-info {
    padding: .8rem var(--wrapper) .5rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    >.right {
      color: #666;
    }
  }
</style>
