<template>
  <div class="banner-info">
    <img :src="cTalents['3image']" alt="" class="img">
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
  computed: {
    cTalents() {
      return this.$store.state.we.weInfo.talents || {};
    }
  },
};
</script>

<style lang="less" scoped>
  .banner-info {
    >.img {
      width: 100%;
    }
  }
</style>