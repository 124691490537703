<template>
  <div class="top-info">
    <div class="info">
      <h2 class="top wow fadeInUp">
        招聘岗位：{{ recruitInfo.name }}
      </h2>
      <div class="bottom wow fadeInUp" data-wow-delay="0.3s">
        <div class="text">
          工作地点：{{ recruitInfo.site }} | 职位类别：{{ recruitInfo.category }} | 招{{ recruitInfo.num }}人
        </div>
        <div class="right wow fadeInUp" data-wow-delay="0.3s">
          发布时间：{{ $utils.setTime(recruitInfo.create_time, 'Y-M-D') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recruitInfo: {}
    };
  },
  created() {
    this.recruitInfo = JSON.parse(this.$route.query.item || '{}') || {};
  },
  mounted() {

  },

  methods: {

  },
};
</script>

<style lang="less" scoped>
.top-info {
  padding: 0 2rem;
  margin-top: .7rem;
}
.info {
  padding: 0 .1rem;
  border-bottom: .01rem solid #E5E5E5;
}
.top {
  font-size: .28rem;
  color: #252525;
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: .2rem 0 .3rem;
}
.text {
  font-size: .18rem;
  color: #707070;
}
.right {
  color: #A9A9A9;
}
</style>
