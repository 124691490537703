<template>
  <div class="list-info">
    <div
      class="item wow fadeInDown"
      :class="{active: navIndex === index}"
      :data-wow-delay="`${0.2 * (index * 1)}s`"
      @click="handleItem(index)"
      v-for="(item, index) in cList"
      :key="`list-nav-${index}`"
    >
      <img :src="item.icon_image" alt="" class="img">
      <img :src="item.icon_act_image" alt="" class="img">
      <div class="text">{{ item.title }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navIndex: -1
    };
  },

  mounted() {

  },
  computed: {
    cList() {
      return this.$store.state.exploit.exploitInfo.research || []
    }
  },
  methods: {
    handleItem(num) {
      this.navIndex = num;
      this.$emit('returnVal', num + 1);
    }
  },
};
</script>

<style lang="less" scoped>
  .list-info {
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    >.item {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: .08rem 0;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:nth-child(n+2) {
        margin-left: .2rem;
      }
      >.img {
        height: .4rem;
        &:nth-child(2) {
          display: none;
        }
      }
      >.text {
        margin-top: .1rem;
      }
      &.active {
        color: #fff;
        background-image: linear-gradient(120deg, #0089fe, #00c1bd);
        >.img:nth-child(1) {
          display: none;
        }
        >.img:nth-child(2) {
          display: block;
        }
      }
      &:hover {
        color: #fff;
        background-image: linear-gradient(120deg, #0089fe, #00c1bd);
        >.img:nth-child(1) {
          display: none;
        }
        >.img:nth-child(2) {
          display: block;
        }
      }
    }
  }
</style>
