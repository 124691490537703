export default {
  data() {
    return {
      loadImg: false,
      navIndex: 0,
      timer: null
    };
  },
  watch: {
    $route: {
      handler(value) {
        this.navIndex = +value.query.p_index || 0;
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    cTitles() {
      return ['技术开发', this.navList[this.$route.p_index || 0]?.title];
    },
    cNavInfo() {
      return this.$store.state.exploit.nav || {};
    },
    navList() {
      return this.$store.state.exploit.nav.nav || [];
    },
    cSubNavInfo() {
      return this.navList[this.navIndex] || {};
    }
  },
  methods: {
    retLoad() {
      this.loadImg = true;
    },
    retNav(index) {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.navIndex = index;
        this.$store.dispatch('exploit/exploit', {type: index + 1, l: 1});
        if (this.$route.path === '/exploit_details') {
          window.scroll(0, 0);
          this.$router.push({
            path: '/exploit',
            query: {
              p_index: this.$route.query.p_index || 0
            }
          });
          this.$nextTick(() => {
            this.$store.commit('initWoW');
          });
        }
      }, 300);
    }
  }
}
