<template>
  <div class="content-info">
    <div class="left">
      <div class="list-wrap">
        <div
          class="item wow fadeInUp"
          :data-wow-delay="`${0.3 * (index * 1)}s`"
          :class="{active: itemIndex == index}"
          v-for="(item, itemIndex) in listInfo"
          :key="item.id"
          @click="handleItem(itemIndex)">
          <img :src="item.image1" alt="" class="img">
          <div class="name">{{item.title}}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="title-wrap wow fadeIn" data-wow-delay="0.3s">
        <img class="title-icon" src="https://img.jozobio.com/img/common/icon_label.png" alt="热门新闻"/>
        {{listInfo[index]?.title}}
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listInfo: {
      type: Array,
      default() {
        return []
      }
    },
    index: 0,
  },
  methods: {
    handleItem(index) {
      this.$router.replace({
        path: this.$route.path,
        query: {
          p_index: index
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
  .content-info {
    padding: 0 var(--wrapper) 1rem;
    display: flex;
    >.left {
      width: 4rem;
      >.list-wrap {
        padding-bottom: .5rem;
        border-radius: 0 .5rem;
        background-color: #228dfb;
        overflow: hidden;
        >.item {
          padding: .28rem .25rem;
          color: #fff;
          font-size: .14rem;
          // white-space: nowrap;
          cursor: pointer;
          user-select: none;
          display: flex;
          align-items: center;
          >.img {
            width: .25rem;
          }
          >.name {
            margin-left: .1rem;
            font-size: .18rem;
          }
          &:hover {
            background-color: rgba(0, 0, 0, .1);
          }
        }
        >.active {
          color: #fff;
          background-color: #95cd2f !important;
        }
      }
    }
    >.right {
      flex: 1;
      margin-left: .5rem;
      // width: calc(100% - 3rem -.5rem);
      width: 1rem;
      >.title-wrap {
        padding-bottom: .08rem;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
        display: flex;
        align-items: center;
        font-size: .2rem;
        >.title-icon {
          margin-right: .05rem;
          width: .08rem;
        }
      }
    }
  }
</style>
