<template>
  <div class="close">
    网站已关闭
  </div>
</template>

<script>
export default {

}
</script>


<style scoped lang="less">
.close {
  font-size: .5rem;
  text-align: center;
}
</style>
