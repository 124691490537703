<template>
  <div class="border-title">
    <div class="border"></div>
    <div class="title wow fadeIn">
      {{titles[0]}}<br/>
      <template v-if="type == 1">
        {{titles[1]}}
      </template>
      <template v-else>
        <div class="text wow fadeIn">{{titles[1]}}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titles: {
      type: Array,
      default() {
        return ['', '']
      }
    },
    type: {
      type: [Number, String],
      default() {
        return 1
      }
    }
  }
};
</script>

<style lang="less" scoped>
  .border-title {
    display: flex;
    align-items: center;
    >.border {
      margin-top: .05rem;
      height: .8rem;
      border-left: solid .08rem #80b51f;
    }
    >.title {
      margin-left: .08rem;
      font-weight: bold;
      font-size: .35rem;
      >.text {
        margin-top: .25rem;
        color: #696969;
        font-weight: initial;
        line-height: 1;
      }
    }
  }
</style>
