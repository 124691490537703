<template>
  <div class="search">
    <div class="location-wrap">
      <div class="label">当前位置：</div>
      <div class="result">--搜索“<span style="color: #00A7CD;font-size: var(--fonSize);">{{ search }}</span>”的结果</div>
    </div>
    <nav class="nav-list wow fadeInUp" data-wow-delay="0.3s">
      <div
          :class="['nav-item', {active: navIndex === index}]"
          v-for="(navItem, index) in navList"
          :key="`search-nav-${navItem.id}`"
          @click="toggleNav(index)"
      >
        <strong>{{ navItem.title }}</strong>
      </div>
    </nav>
    <template v-if="navIndex === 0">
      <news-list :news-list="newsList"></news-list>
    </template>
    <template v-if="navIndex === 1">
      <product-list :product-list="productList"></product-list>
    </template>
    <template v-if="navIndex === 2">
      <recruit-list :recruit-list="recruitList"></recruit-list>
    </template>
  </div>
</template>

<script>
import NewsList from "@/views/search/components/news-list.vue";
import ProductList from "@/views/search/components/product-list.vue";
import RecruitList from "@/views/search/components/recruit-list.vue";
//target
import {
  News
} from "@/views/news/target/news";
import {
  Product
} from "@/views/product/target/product";
import {
  We
} from "@/views/we/target/we";
export default {
  components: {
    NewsList,
    ProductList,
    RecruitList
  },
  data() {
    return {
      search: '',
      navList: [
        {
          id: 1,
          title: '新闻'
        },
        {
          id: 2,
          title: '产品'
        },
        {
          id: 3,
          title: '招聘'
        }
      ],
      navIndex: 0,
      newsList: [], //新闻
      productList: [], //产品
      recruitList: []
    }
  },
  created() {
    const search = this.$route.query.s || '';
    if (!search) {
      this.$router.push({
        path: '/'
      });
      return;
    }
    this.search = search;
    this.getSearchData();
  },
  methods: {
    async getSearchData() {
      const res = await this.$api.base.search({
        name: this.search
      });
      if (res.code === 1) {
        this.newsList = res.data.news.map(v => new News(v));
        this.productList = res.data.product.map(v => {
          v.g_json = JSON.parse(v.g_json);
          v.json = JSON.parse(v.json);
          v.lable_json = JSON.parse(v.lable_json);
          v.files = v.files.split(',');
          return new Product(v);
        });
        this.recruitList = res.data.recruit.map(v => new We(v));
      }
    },
    toggleNav(index) {
      this.navIndex = index;
    }
  }
}
</script>

<style scoped lang="less">
.search {
  padding: .5rem var(--wrapper);
  --fonSize: .2rem;
}
.location-wrap {
  display: flex;
}
.label {
  font-size: var(--fonSize);
}
.result {
  font-size: var(--fonSize);
}
.nav-list {
  display: flex;
  align-items: center;
}
.nav-item {
  width: 1.6rem;
  height: .47rem;
  margin-top: .3rem;
  transition: all .2s;
  font-size: .2rem;
  text-align: center;
  line-height: .47rem;
  border-radius: .24rem;
  color: #1B1B1B;
  cursor: pointer;
  &.active {
    background: linear-gradient(90deg, #0078EC, #00C4BA);
    color: #fff;
  }
}
</style>
