<template>
  <div class="news-info">
    <div class="top-nav-wrap">
      <!-- <div class="title-wrap wow fadeInUp">
        <h2 class="title-cn">新闻资讯</h2>
        <h2 class="title-en">/news</h2>
      </div> -->
      <t-title-info
        label="news"
        title="新闻资讯"
        title-border=".01rem dashed var(--titleColor)"
      >
      </t-title-info>
      <nav class="nav-list wow fadeInUp" data-wow-delay="0.3s">
        <div
          :class="['nav-item', {active: navIndex === index}]"
          v-for="(navItem, index) in navList"
          :key="`news-nav-${navItem.id}`"
          @click="navItem.toggleNews($vnode.componentInstance, index)"
        >
          <strong>{{ navItem.title }}</strong>
        </div>
      </nav>
    </div>
    <section class="news-main-wrap">
      <template v-if="Object.keys(cFirstItem).length > 0">
        <div class="the-first border wow fadeIn" data-wow-delay="0.4s" @click="cFirstItem.routerDetails($route.query.p_index || 0)">
          <div class="news-img-wrap">
            <img class="news-img" :src="cFirstItem.image" alt="新闻封面"/>
          </div>
          <div class="info-wrap">
            <h2 class="label">{{ +cFirstItem.type === 0 ? '企业动态' : '行业新闻' }}</h2>
            <h3 class="title more-ellipsis clamp-2">{{ cFirstItem.title }}</h3>
            <div class="desc-wrap">
              <p class="desc more-ellipsis clamp-2">{{ cFirstItem.des }}</p>
            </div>
            <div class="date-wrap">
              <time class="date nowrap">{{ $utils.setTime(cFirstItem.create_time, 'Y-M-D') }}</time>
              <div class="icon-wrap">
                <img class="icon" src="https://img.jozobio.com/img/home/home_news_icon.png" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="cNewsList.length > 0">
        <div class="news-list">
          <div
              class="news-item border wow slideInLeft"
              :data-wow-delay="`${0.2 * (index * 1)}s`"
              v-for="(item, index) in cNewsList"
              @click="item.routerDetails($route.query.p_index || 0)"
          >
            <h2 class="label">{{ +item.type === 0 ? '企业动态' : '行业新闻' }}</h2>
            <h3 class="title more-ellipsis clamp-2">{{ item.title }}</h3>
            <div class="date-wrap">
              <time class="date nowrap">{{ $utils.setTime(item.create_time, 'Y-M-D') }}</time>
              <div class="icon-wrap">
                <img class="icon" src="https://img.jozobio.com/img/home/home_news_icon.png" />
              </div>
            </div>
          </div>
          <template v-if="cNewsList.length < 3">
            <div class="news-item empty"></div>
          </template>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navIndex: 0,
    }
  },
  computed: {
    navList() {
      return this.$store.state.indexInfo.data?.news || [];
    },
    cFirstItem() {
      return this.navList[this.navIndex]?.data.firstItem || {};
    },
    cNewsList() {
      return this.navList[this.navIndex]?.data.list || [];
    }
  }
}
</script>

<style scoped lang="less">
.news-info {
  padding: 1.13rem var(--wrapper) .96rem;
  .top-nav-wrap {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .title-wrap {
      display: flex;
      align-items: flex-end;
      font-weight: bold;
      .title-cn {
        font-size: .44rem;
        color: #1B1B1B;
      }
      .title-en {
        margin-left: .1rem;
        margin-bottom: .02rem;
        font-size: .24rem;
        font-weight: initial;
        color: #616161;
      }
    }
    .nav-list {
      display: flex;
      align-items: center;
      .nav-item {
        width: 1.6rem;
        height: .47rem;
        transition: all .2s;
        font-size: .2rem;
        text-align: center;
        line-height: .47rem;
        border-radius: .24rem;
        color: #1B1B1B;
        cursor: pointer;
        &.active {
          background: linear-gradient(90deg, #0078EC, #00C4BA);
          color: #fff;
        }
      }
    }
  }
  .news-main-wrap {
    .label {
      color: #0089FF;
      font-weight: initial;
    }
    .title {
      margin: .2rem 0 .2rem;
      font-size: .18rem;
      font-weight: initial;
      color: #111111;
    }
    .date-wrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: .4rem;
      .date {
        color: #737373;
      }
      .icon-wrap {
        .icon {
          width: .18rem;
        }
      }
    }
    .the-first {
      display: flex;
      margin-top: .65rem;
      background: #EDF6FD;
      border-radius: .2rem;
      cursor: pointer;
      .news-img-wrap {
        flex: 1;
        .news-img {
          width: 100%;
        }
      }
      .info-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        padding: 0 .5rem 0 .44rem;

        .desc-wrap {
          .desc {
            color: #737373;
            line-height: .26rem;
          }
        }
      }
    }
    .news-list {
      display: flex;
      justify-content: space-between;
      margin-top: .48rem;
      .news-item {
        padding: .3rem;
        width: 5.25rem;
        background-color: #F7F7F7;
        border-radius: .1rem;
        cursor: pointer;
        .date-wrap {
          margin-top: .7rem;
        }
        &.border:hover:after {
          width: calc(100% - .04rem);
        }
        &.empty {
          background-color: transparent;
          cursor: initial;
        }
      }
    }
  }
}
.border {
  position: relative;
  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    content: '';
    width: 0;
    height: .05rem;
    background: linear-gradient(90deg, #0089FF 50%, #7DB424);
    border-radius: 0 0 .2rem 0;
    transition: all .2s;
  }
}
.border:hover {
  &:after {
    width: calc(100% - .08rem);
  }
}
</style>
