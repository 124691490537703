<template>
  <div class="title-info">
    <div class="left">
      <div class="bottom">
        <!--        <img src="https://img.jozobio.com/img/common/icon_title_sign.png" alt="" class="img">-->
        {{ navInfo.title }}
      </div>
      <div class="split">/</div>
      <div class="top">{{ navInfo.english_title }}</div>
      <img class="icon" src="https://img.jozobio.com/img/common/common_title_icon.png" />
    </div>
    <div class="right">
<!--      <div class="border"></div>-->
<!--      <div class="num">04</div>-->
<!--      <div class="text">-->
<!--        BRAND<br/>-->
<!--        COOPERATTON-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style lang="less" scoped>
  .title-info {
    padding: .6rem 1.8rem;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    >.left {
      display: flex;
      align-items: center;
      >.top {
        font-size: .5rem;
        color: var(--titleColor);
      }
      >.split {
        margin: 0 .13rem;
        font-size: .5rem;
        color: var(--titleColor);
      }
      >.bottom {
        font-size: .5rem;
        color: var(--titleColor);
        //display: flex;
        //align-items: center;
        //>.img {
        //  margin-right: .05rem;
        //  width: .32rem;
        //}
      }
      >.icon {
        margin-top: 0.05rem;
        margin-left: 0.2rem;
        width: .4rem;
      }
    }
    >.right {
      display: flex;
      align-items: center;
      >.border {
        margin-bottom: -.2rem;
        width: .45rem;
        border-bottom: solid 2px #999;
      }
      >.num {
        margin-left: .1rem;
        font-size: .35rem;
        font-weight: bold;
        font-family: Bahnschrift;
      }
      >.text {
        margin-left: .35rem;
        font-size: .14rem;
        color: #666;
      }
    }
  }
</style>
