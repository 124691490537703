<template>
  <div class="message">
    <t-top-info
      :banner="cSubNavTitle.bg_image"
      :label=cNavInfo.english_title
      :title=cNavInfo.title
    ></t-top-info>
    <t-nav-list
      :titles="cTitles"
      :listInfo="navList"
      list-key="title"
      iPadding="0 .3rem">
    </t-nav-list>
    <top-info></top-info>
    <content-info
      :listInfo="navList"
      :index="navIndex">
      <template v-if="navIndex == 0">
        <product-info></product-info>
      </template>
      <template v-if="navIndex == 1">
        <explain-info :nav-index="navIndex"></explain-info>
      </template>
      <template v-if="navIndex == 2">
        <recall-info :nav-index="navIndex"></recall-info>
      </template>
      <template v-if="navIndex == 3">
        <quality-info></quality-info>
      </template>
      <template v-if="navIndex == 4">
        <archival-info :nav-index="navIndex"></archival-info>
      </template>
      <template v-if="navIndex == 5">
        <form-info></form-info>
      </template>
    </content-info>
  </div>
</template>

<script>
import TopInfo from './childComps/TopInfo.vue';
import ContentInfo from './childComps/ContentInfo.vue';
import ProductInfo from './childComps/ProductInfo.vue';
import ExplainInfo from './childComps/ExplainInfo.vue';
import RecallInfo from './childComps/RecallInfo.vue';
import QualityInfo from './childComps/QualityInfo.vue';
import ArchivalInfo from './childComps/ArchivalInfo.vue';
import FormInfo from './childComps/FormInfo.vue';
//mixins
import TopBanner from "@/views/message/mixins/top-banner";
export default {
  components: { TopInfo, ContentInfo, ProductInfo, ExplainInfo, RecallInfo, QualityInfo, ArchivalInfo, FormInfo },
  name: 'Message',
  mixins: [TopBanner]
};
</script>

<style lang="less">
.t-nav-list {
  >.content-info {
    >.left {
      >.list-wrap {
        >.item {
          font-size: .15rem;
        }
      }
    }
    >.right {
      >.list {
        >.item {
          font-size: .15rem;
        }
      }
    }
  }
}
</style>
