<template>
  <div class="product-list">
    <c-title :en="'/' + navInfo.english_title" :cn="navInfo.title"></c-title>
    <template v-if="cProducts.length < 1">
      <div class="product-item empty">
        <div class="img-wrap">
          <img class="img" src="https://img.jozobio.com/img/common/common_empty_img.png" alt="暂无数据"/>
        </div>
        <div class="text">---暂无数据---</div>
      </div>
    </template>
    <template v-else>
      <section class="list">
        <div
            class="product-item wow fadeInUp"
            :data-wow-delay="`${0.2 * (index * 1)}s`"
            v-for="(item, index) in cProducts"
            :key="item.id"
        >
          <div class="info-wrap">
            <h2 class="title one-ellipsis wow fadeInUp" :data-wow-delay="`${0.3 * (index * 1)}s`">{{ item.title }}</h2>
            <div class="type nowrap wow fadeInUp" :data-wow-delay="`${0.3 * (index * 1)}s`">{{ item.sub_title }}</div>
            <div class="desc-wrap wow fadeInUp" :data-wow-delay="`${0.4 * (index * 1)}s`">
              <p class="desc more-ellipsis clamp-4">{{ item.des }}</p>
            </div>
            <div class="details-btn btn wow fadeInUp" :data-wow-delay="`${0.4 * (index * 1)}s`" @click.stop="item.routerDetails($route.query.p_index || 0)">
              <div class="btn-text nowrap">产品详情</div>
              <div class="more-icon-wrap">
                <img src="https://img.jozobio.com/img/exploit/icon_08.png" class="more-icon">
                <img class="more-icon active" src="https://img.jozobio.com/img/product/product_more_icon.png" alt="产品详情"/>
              </div>
            </div>
            <div class="feature-list">
              <div
                  class="feature-item wow bounceIn"
                  :data-wow-delay="`${0.5 * (index * 1)}s`"
                  v-for="(labelItem, labelIndex) in item.lable_json"
                  :key="`label-${labelIndex}`"
              >
                <div class="feature-icon-wrap">
                  <img class="feature-icon" :src="item.files[labelIndex]">
                </div>
                <div class="feature-text">{{ labelItem }}</div>
              </div>
            </div>
          </div>
          <div class="product-img-wrap">
            <img class="product-img" :src="item.image"/>
          </div>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import Title from "@/views/product/components/title.vue";
export default {
  props: {
    navInfo: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    'c-title': Title
  },
  computed: {
    cProducts() {
      return this.$store.state.product.productInfo.productList || [];
    }
  },
  methods: {
  }
}
</script>


<style scoped lang="less">
.product-list {
  margin-bottom: 2.16rem;
}
.product-item {
  display: flex;
  align-items: center;
  margin-bottom: .65rem;
  background-color: #F4F4F4;
  border-radius: 0 0 1.03rem 0;
  // &:hover {
  //   >.product-img-wrap {
  //     >.product-img {
  //       transform: scale(1.2);
  //     }
  //   }
  // }
  &:last-child {
    margin-bottom: 0;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    border-radius: 1.03rem 0 0 0;
  }
}
.info-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  padding: 0 var(--wrapper);
}
.title {
  width: fit-content;
  max-width: 100%;
  background: linear-gradient(90deg, #6235C3 0%, #31A6C8 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "PingFang SC";
  font-size: .32rem;
  font-weight: bold;
}
.type {
  margin: .3rem 0 .2rem;
  font-size: .22rem;
  color: #000000;
}
.desc-wrap {
  margin-bottom: .45rem;
}
.desc {
  color: #807F7F;
  line-height: .3rem;
}
.details-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: .47rem;
  margin-bottom: .35rem;
  border: .01rem solid #B1B1B1;
  border-radius: .25rem .2rem 0 .25rem;
  cursor: pointer;
  &:hover {
    >.more-icon-wrap {
      >.more-icon {
        margin-top: .02rem;
        opacity: 0;
        &.active {
          opacity: 1;
        }
      }
    }
    >.btn-text {
      color: #fff;
    }
    background: linear-gradient(to right, #007CE9 20%, #00C3BB 80%);
    border: none;
  }
}
.more-icon-wrap {
  position: relative;
  margin-left: .03rem;
  margin-bottom: .03rem;
}
.more-icon {
  margin-top: .1rem;
  width: .34rem;
  transition: .3s ease-out;
  &.active {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    opacity: 0;
  }
}
.btn-text {
  font-size: .15rem;
  color: #B1B1B1;
  transition: .3s ease-out;
}
.feature-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.feature-item {
  display: flex;
  align-items: center;
  margin-right: .98rem;
}
.feature-icon-wrap {
  margin-right: .15rem;
}
.feature-icon {
  width: .41rem;
}
.feature-text {
  max-width: 2.5rem;
  font-size: .15rem;
}
.product-img-wrap {
  flex: 1;
  width: 50%;
  border-radius: 1.03rem 0 1.03rem 0;
  overflow: hidden;
}
.product-img {
  width: 100%;
  transition: .3s ease-out;
}
</style>
