<template>
	<div class="details">
		<t-top-info
		  :banner="cSubNavTitle.bg_image"
		  :label="cNavInfo.english_title"
		  :title="cNavInfo.title"
		></t-top-info>
		<t-nav-list
		  :index="navIndex"
		  :titles="cTitles"
		  :listInfo="navList"
      list-key="title"
		  @returnVal="retNav">
		</t-nav-list>
		<news-content :news-details="newsDetails"></news-content>
    <hot-news :hot-news="newsDetails.hot_news"></hot-news>
	</div>
</template>

<script>
import NewsContent from './components/news-content.vue';
import HotNews from "@/views/news/details/components/hot-news.vue";
import TopBanner from '../mixins/top-banner.js';
import {News} from "@/views/news/target/news";
export default {
	components: {
		NewsContent,
    HotNews
	},
	mixins: [TopBanner],
  data() {
    return {
      id: 0,
      newsDetails: {
        data: {},
        previous: null,
        next: null,
        hot_news: []
      }
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        this.id = newVal.params.id || 0;
        if (this.id === 0 || this.id === void 0) {
          this.$router.push({
            path: '/news',
            query: {
              p_index: 0
            }
          });
        }
        this.getNewsDetails();
      },
      deep: true
    }
  },
  created() {
    this.id = this.$route.params.id || 0;
    if (this.id === 0 || this.id === void 0) {
      this.$router.push({
        path: '/news',
        query: {
          p_index: 0
        }
      });
    }
    this.getNewsDetails();
  },
  methods: {
    async getNewsDetails() {
      const res = await this.$api.news.newsDetails({id: this.id});
      if (res.code !== 1) return ;
      res.data.hot_news = res.data.hot_news?.map(v => new News(v));
      this.newsDetails = new News(res.data);
    }
  }
}
</script>

<style>
</style>
