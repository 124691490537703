<template>
  <div class="honor-info">
    <img class="bg-img" src="https://img.jozobio.com/img/profile/img_3.jpg" />
    <div class="top-info">
      <t-title-info
        :label="navList[navIndex].label"
        :title="navList[navIndex].name"
        :index="navIndex"
        :listInfo="cHonors"
        list-key="title"
        @returnVal="retTitle">
      </t-title-info>
    </div>
    <div class="content-info">
      <template v-if="cHonors[navIndex]?.listTarget?.row.length > 0">
        <div
          v-if="index === navIndex"
          class="swiper-container honor-swiper-container-row"
          v-for="(honor, index) in cHonors"
          :key="`profile-row-honor-${index}`"
        >
          <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                v-for="(item, index) in honor?.listTarget?.row">
              <div class="item wow fadeInDown" :data-wow-delay="`${0.2 * (index * 1)}s`">
                <div class="img-wrap">
                  <img :id="`img${index + 1}`" :src="item.image" :alt="item.title" class="img">
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="cHonors[navIndex]?.listTarget?.col.length > 0">
        <div
          v-if="index === navIndex"
          class="swiper-container honor-swiper-container-col"
          v-for="(honor, index) in cHonors"
          :key="`profile-col-honor-${index}`"
        >
          <div class="swiper-wrapper">
            <div
                class="swiper-slide"
                v-for="(item, index) in honor?.listTarget?.col">
              <div class="item wow fadeInDown" :data-wow-delay="`${0.2 * (index * 1)}s`">
                <div class="img-wrap">
                  <img :src="item.image" :alt="item.title" class="img">
                </div>
                <div class="title">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <!-- 如果需要分页器 -->
          <div class="swiper-pagination"></div>
        </div>
      </template>
    </div>
    <div class="bg-wrap">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball2.png" alt="" class="img">
      <img src="https://img.jozobio.com/img/profile/profile_about_ball3.png" alt="" class="img">
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper';
export default {
  data() {
    return {
      navIndex: 0,
      navList: [{
        id: 1,
        name: '企业资质',
        label: 'Qualifications'
      },{
        id: 2,
        name: '企业荣誉',
        label: 'Corporate Honors'
      }],
      rowSwiper: null,
      colSwiper: null
    };
  },
  computed: {
    cHonors() {
      return this.$store.state.profile.profileInfo.qualifications || [];
    }
  },
  watch: {
    $route: {
      handler(newVal) {
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 1500);
  },
  methods: {
    retTitle(index) {
      this.navIndex = index;
      setTimeout(() => {
        this.initSwiper();
      }, 10);
    },
    initSwiper() {
      if (!document.getElementById('img1').complete) {
        requestAnimationFrame(this.initSwiper);
        return ;
      }
      let viewNum = 4;
      let gap = 30;
      if (this.$store.state.isMedia) {
        viewNum = 2
        gap = 20;
      }
      this.rowSwiper = new Swiper('.honor-swiper-container-row', {
        slidesPerView: viewNum,
        spaceBetween: gap,
        autoplay: { //自动开始
          delay: 5000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        observer: true,
        observeParents: true,
        loop: this.cHonors[this.navIndex]?.listTarget?.row.length > 3, // 循环模式选项
      });
      this.colSwiper = new Swiper('.honor-swiper-container-col', {
        slidesPerView: viewNum,
        spaceBetween: gap,
        autoplay: { //自动开始
          delay: 5000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        observer: true,
        observeParents: true,
        loop: this.cHonors[this.navIndex]?.listTarget?.col.length > 3, // 循环模式选项
        // 如果需要分页器
        pagination: {
          el: '.swiper-pagination',
          clickable: true, // 分页器可以点击
        },
      });
    }
  }
};
</script>

<style lang="less" scoped>
  .honor-info {
    position: relative;
    padding: .6rem var(--wrapper);
    background-color: #f3f5f2;
    overflow: hidden;
    >.bg-img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
    >.top-info {
      position: relative;
    }
    >.content-info {
      margin-top: .7rem;
      .swiper-container {
        height: 4.7rem;
        &.honor-swiper-container-col {
          .img-wrap {
            margin: 1.5px 1.5px 0;
            padding: .3rem .2rem;
            min-height: 3.5rem;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            >.img {
              width: auto !important;
              height: 3rem;
            }
          }
        }
        .item {
          border-radius: .05rem;
          background-color: #fff;
          overflow: hidden;
          background-image: linear-gradient(to right, #0079eb, #00c0bd);
          >.img-wrap {
            margin: 1.5px 1.5px 0;
            padding: .3rem .2rem;
            min-height: 3.5rem;
            background-color: #fff;
            display: flex;
            align-items: center;
            >.img {
              width: 100%;
            }
          }
          >.title {
            padding: .1rem;
            color: #fff;
            text-align: center;
            background-color: #ababab;
            background-image: linear-gradient(to right, #0079eb, #00c0bd);
          }
        }
      }
    }
    >.bg-wrap {
      >.img {
        position: absolute;
        &:first-child {
          bottom: 0;
          right: 0;
          width: 3.5rem;
        }
        &:last-child {
          bottom: 0;
          left: 0;
          width: 2rem;
        }
      }
    }
  }
</style>
