<template>
  <div class="swiper-info">
    <div class="list-wrap">
      <div class="item wow fadeInDown" data-wow-delay="0.3s">
        <div class="top">
          <div class="big">{{ cAchievement.number.invent || '--' }}</div>
          项
        </div>
        <div class="bottom">
          发明专利
        </div>
      </div>
      <div class="border"></div>
      <div class="item wow fadeInDown" data-wow-delay="0.4s">
        <div class="top">
          <div class="big">{{ cAchievement.number.aspect || '--' }}</div>
          项
        </div>
        <div class="bottom">
          外观设计专利
        </div>
      </div>
      <div class="border"></div>
      <div class="item wow fadeInDown" data-wow-delay="0.5s">
        <div class="top">
          <div class="big">{{ cAchievement.number.utility || '--' }}</div>
          项
        </div>
        <div class="bottom">
          实用新型专利
        </div>
      </div>
    </div>
    <div class="content-info">
      <img src="https://img.jozobio.com/img/exploit/img_12.png" alt="" class="img">
      <div class="swiper-wrap">
        <div class="swiper-container ach-swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in cAchievement.data" :key="index">
              <div class="item wow fadeInLeft" :data-wow-delay="`${0.3 * (index * 1)}s`">
                <img :id="`img${index + 1}`" :src="item.image" alt="" class="img">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import Swiper from 'swiper';
export default {
  data() {
    return {

    };
  },
  computed: {
    cAchievement() {
      return this.$store.state.exploit.exploitInfo.achievement || {};
    },
    cSum() {
      return (type) => {
        const sum = this.cAchievement.data?.reduce((total, current) => {
          if (+current.type === type) {
            return total + 1;
          }
          return total;
        }, 0);
        return sum;
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.initSwiper();
    }, 1000);
  },

  methods: {
    initSwiper() {
      if (!document.getElementById('img1').complete) {
        requestAnimationFrame(this.initSwiper);
        return;
      }
      new Swiper('.ach-swiper-container', {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: true,
        autoplay: { //自动开始
          delay: 5000, //时间间隔
          disableOnInteraction: false, //*手动操作轮播图后不会暂停*
        },
        observer: true,
        observeParents: true,
        loop: true, // 循环模式选项
      })
    }
  },
};
</script>

<style lang="less" scoped>
  .swiper-info {
    >.list-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      >.item {
        padding: .5rem 0;
        width: 4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        >.top {
          color: #0265C5;
          display: flex;
          align-items: flex-end;
          >.big {
            margin-bottom: -.08rem;
            font-size: .4rem;
          }
        }
        >.bottom {
          margin-top: .15rem;
          color: #666;
        }
      }
      >.border {
        height: .3rem;
        border-right: solid 1px #616161;
      }
    }
    >.content-info {
      position: relative;
      margin-top: .5rem;
      >.img {
        width: 100%;
      }
      >.swiper-wrap {
        position: absolute;
        top: 0;
        left: 4rem;
        right: 4rem;
        .swiper-slide {
          text-align: center;
          font-size: 18px;
          background: #fff;

          /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
            transition: 300ms;
            transform: scale(0.8);
          }
        .swiper-slide-active,.swiper-slide-duplicate-active{
            transform: scale(1);
        }
        .item {
          >.img {
            width: 100%;
          }
        }
      }
    }
  }
</style>
